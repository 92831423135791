enum ELocalStorageKeys {
  AccessToken = "accessToken",
  DefaultFilters = "defaultFilters",
  GoogleRecaptcha = "_grecaptcha",
  RefreshToken = "refreshToken",
  UserEmail = "userEmail",
  UserRoles = "userRoles",
}

export default ELocalStorageKeys;
