import { SettingsIcon } from "@chakra-ui/icons";
import { Box, Button, Checkbox, Divider, Popover, PopoverArrow, PopoverBody, PopoverCloseButton, PopoverContent, PopoverHeader, PopoverTrigger, Stack } from "@chakra-ui/react";
import { Table } from "@tanstack/react-table";
import SearchField from "components/v2/SearchField";
import { useState } from "react";
import { IconButton } from '@chakra-ui/react'

export type ColumnSidebarProps<TData> = {
    table: Table<TData>;
    columnsSidebarChildren?: React.ReactNode;
    triggerButtonType?: 'icon' | 'text';
};

const ColumnSidebar = <T, >(props: ColumnSidebarProps<T>) => {

    const { table } = props;

    const [search, setSearch] = useState<string>('');

    return (
      <Popover
        // placement="right-start"
      >
        <PopoverTrigger>  
          {props.triggerButtonType === 'icon' ? (
            <IconButton 
              aria-label="Customize columns"
              icon={<SettingsIcon color="#8bb9a8" />}
            />
          ) : (
            <Button 
              rightIcon={<SettingsIcon color="#8bb9a8" fontSize="large" />}
              size="md"
              border="1px solid #b3b8db"
              backgroundColor="#ffffff"
              fontSize="14px"
              padding="10px 16px"
              height="43px"
            >
              Customize
            </Button>
          )}
        </PopoverTrigger>
        <PopoverContent>
          <PopoverArrow />
          <PopoverCloseButton />
          <PopoverHeader>
            <Checkbox 
              isChecked={table.getIsAllColumnsVisible()}
              onChange={table.getToggleAllColumnsVisibilityHandler()}
              fontWeight={600}
            >
              Columns
            </Checkbox>
          </PopoverHeader>
          <PopoverBody
            display="flex"
            flexFlow="column"
            gap="10px"
          >
            <Box
              marginBottom="10px"
            >
              <SearchField 
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                onClear={() => setSearch('')}
              />
            </Box>
            <Box
              overflow="auto"
              maxHeight="250px"
            >
              <Stack 
                spacing={3} 
                direction='column'
              >
                {table.getAllLeafColumns().map(column => {
                  
                  let headerName = (column as any).columnDef.header;

                  if (column.parent) {
                    headerName = `${(column.parent as any).columnDef.header} - ${headerName}`;
                  }
                  return (
                      <Checkbox 
                        key={column.id}
                        isChecked={column.getIsVisible()}
                        onChange={column.getToggleVisibilityHandler()}
                      >
                        {headerName}
                      </Checkbox>
                  );
                }).filter(column => typeof column.props.children === 'string' ? column.props.children.toLowerCase().includes(search.toLowerCase()) : true)}
              </Stack>
            </Box>
            {props.columnsSidebarChildren && (
              <>
                <Divider />
                <Box
                  display="flex"
                  flexFlow="column"
                  gap="10px"
                >
                  {props.columnsSidebarChildren}
                </Box>
              </>
            )}
          </PopoverBody>
        </PopoverContent>
      </Popover>
    );
};

export default ColumnSidebar;