import { FC, MouseEvent } from "react";
import cs from "classnames";
import styles from "./styles.module.scss";

interface IItem {
  label: string;
  value: any;
}

interface IButtonGroupProps {
  /**
   * @type number - index type
   */
  activeButton: number;
  className?: string;
  items: IItem[];
  onClick: (
    index: number,
    value: any,
    e?: MouseEvent<HTMLButtonElement>
  ) => void;
}

const ButtonGroup: FC<IButtonGroupProps> = ({
  activeButton,
  className,
  items,
  onClick,
}) => {
  return items?.length ? (
    <div className={cs(styles["buttonGroup"], className)} role="group">
      {items.map(({ label, value }, index) => (
        <button
          className={cs(styles["buttonGroup__button"], {
            [styles["buttonGroup__button--active"]]: activeButton === index,
          })}
          key={index}
          onClick={(e) => onClick(index, value, e)}
        >
          {label}
        </button>
      ))}
    </div>
  ) : null;
};

export default ButtonGroup;
