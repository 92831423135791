import { FC, FunctionComponent, useCallback, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import cs from "classnames";
import { ReactComponent as ChevronDownIcon } from "assets/icons/chevron-down.svg";
import styles from "../styles.module.scss";

interface IMenuItemBase {
  icon?: FunctionComponent;
  label: string;
  link?: string;
}

export interface IProps extends IMenuItemBase {
  index: number;
  isSidebarOpen: boolean;
  onCloseSubMenu: () => void;
  onCloseSidebar?: () => void;
  onOpenSubMenu?: (index: number) => void;
  openSubMenu: number | null;
  subMenu?: IMenuItemBase[];
}

const MenuItem: FC<IProps> = ({
  icon: Icon,
  index,
  isSidebarOpen,
  label,
  link,
  onCloseSidebar,
  onCloseSubMenu,
  onOpenSubMenu,
  openSubMenu,
  subMenu,
}) => {
  const navigate = useNavigate();
  const location = useLocation();

  const onClickRoute = useCallback(
    (routeLink: string) => {
      if (routeLink) {
        navigate(routeLink);
        if (onCloseSidebar) {
          onCloseSidebar();
        }
        onCloseSubMenu();
      }
    },
    [navigate, onCloseSidebar, onCloseSubMenu]
  );

  const checkIsActiveItem = useCallback(() => {
    if (subMenu) {
      const subMenuIndex = subMenu.findIndex(
        ({ link }) => link === location.pathname
      );

      return subMenuIndex !== -1;
    }

    return link ? link === location.pathname : false;
  }, [link, location, subMenu]);

  const isOpenSubMenu = useMemo(
    () => subMenu?.length && index === openSubMenu,
    [index, openSubMenu, subMenu?.length]
  );

  const isSubMenuAndSidebarOpen = useMemo(
    () => (isOpenSubMenu && isSidebarOpen) || false,
    [isOpenSubMenu, isSidebarOpen]
  );

  return (
    <li className={styles.menuItem}>
      <button
        className={cs({
          [styles.isActive]: checkIsActiveItem() || isSubMenuAndSidebarOpen,
        })}
        onClick={() =>
          link && !subMenu ? onClickRoute(link) : onOpenSubMenu ? onOpenSubMenu(index) : undefined
        }
      >
        {Icon && <Icon />}
        <p>{label}</p>

        {isSidebarOpen && subMenu && (
          <ChevronDownIcon
            className={cs(styles.hasSubMenuIcon, {
              [styles.isExpanded]: isSubMenuAndSidebarOpen,
            })}
          />
        )}
      </button>
      {subMenu?.length ? (
        <ul
          className={cs(styles.subMenu, {
            [styles.isExpanded]: isSubMenuAndSidebarOpen,
          })}
        >
          {subMenu.map(({ label, link }) =>
            link ? (
              <li
                key={link}
                className={styles.subMenuItem}
                onClick={() => onClickRoute(link)}
              >
                {label}
              </li>
            ) : null
          )}
        </ul>
      ) : null}
    </li>
  );
};

export default MenuItem;
