import { useState, useEffect } from "react";
import { EBreakpoints, TBreakpointKeys } from "types/EBreakpoints";

const useScreenSize = (): TBreakpointKeys | null => {
  const [breakpoint, setBreakpoint] = useState<TBreakpointKeys | null>(null);

  useEffect(() => {
    const handleWindowResize = () => {
      const windowWidth = window.innerWidth;

      if (windowWidth < EBreakpoints.sm) {
        setBreakpoint("sm");
      } else if (windowWidth < EBreakpoints.md) {
        setBreakpoint("md");
      } else if (windowWidth < EBreakpoints.lg) {
        setBreakpoint("lg");
      } else if (windowWidth < EBreakpoints.xl) {
        setBreakpoint("xl");
      } else {
        setBreakpoint("2xl");
      }
    };

    handleWindowResize();

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  return breakpoint;
};

export default useScreenSize;
