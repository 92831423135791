import { Text, Box, Button, NumberDecrementStepper, NumberIncrementStepper, NumberInput, NumberInputField, NumberInputStepper, Popover, PopoverArrow, PopoverBody, PopoverCloseButton, PopoverContent, PopoverHeader, PopoverTrigger, Portal, RangeSlider, RangeSliderFilledTrack, RangeSliderThumb, RangeSliderTrack } from "@chakra-ui/react";
import React, { useMemo } from "react";


type RangePropovderProps = {
    header?: string | React.ReactNode;
    children: React.ReactNode;
    defaultValue?: number[];
    onChange?: (value: number[]) => void;
    max: number;
    onMaxChange?: (value: number) => void;
    onReset?: () => void;
};

const RangePopover = (props: RangePropovderProps) => {
    
    const [value, setValue] = React.useState<number[]>(props.defaultValue || [0, 500]);

    const [maxRange, setMaxRange] = React.useState<number>(props.max);

    const handleChange = (index: number, newValue: number) => {
        setValue((prevValue) => {
          const updatedValue = [...prevValue];
          updatedValue[index] = newValue;
          return updatedValue;
        });
    };

    useMemo(() => {
        setValue(props.defaultValue || [0, 500]);
    }, [props.defaultValue]);

    // useEffect(() => {
    //     const handler = setTimeout(() => {
    //         if (props.onChange) {
    //             props.onChange(value);
    //         }
    //     }, 500); // 300ms debounce delay
    
    //     return () => {
    //         clearTimeout(handler);
    //     };
    // }, [props, value]);
    
    return (
        <Popover>
            {({ onClose }) => (
                <>
                <PopoverTrigger>  
                    {props.children}
                </PopoverTrigger>
                <Portal>
                    <PopoverContent>
                        <PopoverArrow />
                        <PopoverCloseButton />
                        {props.header && <PopoverHeader>{props.header}</PopoverHeader>}
                        <PopoverBody>
                            <Box
                                display="flex"
                                flexDirection="column"
                                gap="10px"
                            >
                                <Box
                                    padding="5px 10px"
                                >
                                    <RangeSlider 
                                        // min={value[0]}
                                        max={maxRange}
                                        value={value}
                                        onChange={(newValue) => {
                                            setValue(newValue);
                                        }}
                                    >
                                        <RangeSliderTrack
                                            height="10px"
                                        >
                                            <RangeSliderFilledTrack />
                                        </RangeSliderTrack>
                                        <RangeSliderThumb 
                                            index={0} 
                                            h="20px"
                                            w="20px"
                                        />
                                        <RangeSliderThumb 
                                            index={1} 
                                            h="20px"
                                            w="20px"
                                        />
                                    </RangeSlider>
                                </Box>
                                <Box
                                    display="flex"
                                    justifyContent="space-between"
                                    gap="20px"
                                >
                                    <Box>
                                        <Text 
                                            fontSize="sm"
                                            paddingBottom="3px"
                                        >
                                            Min
                                        </Text>    
                                        <NumberInput 
                                            size="sm"
                                            step={5} 
                                            min={0} 
                                            max={value[1]}
                                            value={value[0]}
                                            onChange={(e) => handleChange(0, Number(e))}
                                        >
                                            <NumberInputField />
                                            <NumberInputStepper>
                                                <NumberIncrementStepper 
                                                    fontSize="10px"
                                                />
                                                <NumberDecrementStepper 
                                                    fontSize="10px"
                                                />
                                            </NumberInputStepper>
                                        </NumberInput>
                                    </Box>
                                    <Box>
                                        <Text 
                                            fontSize="sm"
                                            paddingBottom="3px"
                                        >
                                            Max
                                        </Text>
                                        <NumberInput 
                                            size="sm"
                                            step={5} 
                                            min={value[0]} 
                                            // max={value[1]}
                                            value={value[1]}
                                            onChange={(tempValue) => {
                                                handleChange(1, Number(tempValue));
                                                if (Number(tempValue) > maxRange) {
                                                    setMaxRange(Number(tempValue));
                                                }
                                            }}
                                        >
                                            <NumberInputField />
                                            <NumberInputStepper
                                            >
                                                <NumberIncrementStepper 
                                                    fontSize="10px"
                                                />
                                                <NumberDecrementStepper 
                                                    fontSize="10px"
                                                />
                                            </NumberInputStepper>
                                        </NumberInput>
                                    </Box>
                                </Box>
                            </Box>
                            <Box
                                display="flex"
                                gap="10px"
                                alignItems="center"
                                justifyContent="space-between"
                                paddingTop="20px"
                            >
                                 <Button
                                    size="sm"
                                    onClick={() => {
                                        if (props.onReset) {
                                            props.onReset();
                                        }
                                        onClose();
                                    }}
                                    color="red"
                                >
                                    Reset
                                </Button>
                                <Box
                                    display="flex"
                                    gap="10px"
                                    alignItems="center"
                                    justifyContent="flex-end"
                                >
                                    <Button
                                        size="sm"
                                        onClick={() => {
                                            if (props.onChange) {
                                                props.onChange(value);
                                            }
                                            if (props.onMaxChange) {
                                                props.onMaxChange(maxRange);
                                            }
                                            onClose();
                                        }}
                                    >
                                        Save
                                    </Button>
                                    <Button
                                        size="sm"
                                        onClick={onClose}
                                    >
                                        Close
                                    </Button>
                                </Box>
                            </Box>
                        </PopoverBody>
                    </PopoverContent>
                </Portal>
                </>
            )}
        </Popover>
    );
};

export default RangePopover;