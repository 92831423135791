import { useQuery } from "@tanstack/react-query";
import EDaysOnLot from "types/APIDaysOnLot";
import { IDateRanges, IDealership, ISearch, TInventory, TPagination } from "types/APIParameters";
import TResultScoreRecentActivity from "types/APIResultScoreKeys";
import { IAPIDefaultResults } from "types/APIResults";
import fetchData from "utils/fetchData";

export enum EReportsModules {
  NeedsAttentionV2 = "v2/reports/needs-attention",
  RecentActivityReportV2 = "v2/reports/recent-activity-report-dol",
}

interface IParams extends IDealership {}

type TParams = {} & TPagination & IDealership;

interface INeedsAttentionParams extends IParams, IDateRanges, TInventory {}

type IRecentActivityReportParamsV2 = {
  result_score?: TResultScoreRecentActivity;
  is_missing_photos?: string;
  is_less_than_50_vdps?: string;
  dol_min?: number;
  dol_max?: number;
} & TParams & IDateRanges & ISearch & TInventory;

type TGreen = number;
type TRed = number;
type TYellow = number;

type TResultsScoreBars = [TGreen, TYellow, TRed];

export interface IAPINeedsAttention {
  [EDaysOnLot.ZeroToFifteen]: TResultsScoreBars;
  [EDaysOnLot.SixteenToThirty]: TResultsScoreBars;
  [EDaysOnLot.ThirtyOneToFortyFive]: TResultsScoreBars;
  [EDaysOnLot.FortySixToSixty]: TResultsScoreBars;
  [EDaysOnLot.SixtyAbove]: TResultsScoreBars;
  green_score: number;
  red_score: number;
  needs_pricing: number;
  yellow_score: number;
}

export type IAPIRecentActivityReportV2 = {
  stock_number:                 string;
  price:                        number;
  photos_count:                 number;
  default_percentage_to_market: number;
  price_to_market:              number;
  last_price_change:            string;
  year:                         number;
  make:                         string;
  model:                        string;
  trim:                         string;
  dol:                          number;
  leads:                        number;
  vdps:                         number;
  total_carfax_pull:            number;
  leads_vdps:                   number;
  result_score:                 number;
  cgr_deal_rating:              string;
  atc_deal_rating:              string;
  ccm_deal_rating:              string;
  cfx_deal_rating:              string;
}

interface IOptions<T extends string> {
  module: EReportsModules;
  params?: T extends EReportsModules.NeedsAttentionV2
    ? INeedsAttentionParams
    : T extends EReportsModules.RecentActivityReportV2
    ? IRecentActivityReportParamsV2
    : IParams;
}

type FetchDataReturnType<T extends IOptions<T>["module"]> =
  T extends EReportsModules.NeedsAttentionV2
    ? IAPINeedsAttention
    : T extends EReportsModules.RecentActivityReportV2
    ? IAPIDefaultResults<IAPIRecentActivityReportV2[]>
    : T;

const useGetReports = <T extends IOptions<T>["module"]>({
  module,
  params,
}: IOptions<T>) => {
  return useQuery({
    refetchOnWindowFocus: false,
    queryFn: getReportsAsync<T>({ module, params }),
    queryKey: ["getReports", module, ...Object.values(params || {})],
    staleTime: 1000 * 60 * 5, // 5 minutes
    cacheTime: 1000 * 60 * 5, // 5 minutes
  });
};

export const getReportsAsync = <T extends IOptions<T>["module"]>({
  module,
  params,
}: IOptions<T>) =>
  fetchData<FetchDataReturnType<T>>({
    isAuth: true,
    method: "GET",
    params,
    url: `${process.env.REACT_APP_API_URL || ""}api/${
      module ? `${module}` : ""
    }`,
  });

export default useGetReports;
