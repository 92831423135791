import { useCallback, useEffect, useMemo, useState } from "react";
import cs from "classnames";
import { format } from "date-fns";
import { toast } from "react-toastify";
import useGetSales, { EModules as EModulesGetSales } from "api/useGetSales";
import usePostSales, { EModules as EModulesPostSales } from "api/usePostSales";
import { IDateRangeProps } from "components/DateRange";
import LineChart from "components/LineChart";
import MetricCard, {
  IOnSaveParameter,
  TMetricProps,
} from "components/MetricCard";
import Switch from "components/Switch";
import Typography from "components/Typography";
import apiDateFormat from "constants/apiDateFormat";
import { currentMonth, graphDateRange } from "constants/commonDateRanges";
import useScreenSize from "hooks/useScreenSize";
import styles from "pages/Merchandising/styles.module.scss";
import {
  ELocalSavedFilters,
  ISavedDefaultFilters,
  getDefaultFilters,
  setDefaultFilters,
} from "utils/defaultFilters";
import formatDateRangeLabel from "utils/formatDateRangeLabel";
import { useAppSelector } from "hooks/store";
import { selectUserData } from "store/auth/authSlice";

type TSavedFilters =
  ISavedDefaultFilters[ELocalSavedFilters.SalesToFrontLineReadyGraph];

const FrontLineReadyVehicles = () => {
  const screenSize = useScreenSize();
  const isMobileView = useMemo(
    () => screenSize !== "2xl" && screenSize !== "xl",
    [screenSize]
  );
  const defaultFilters = getDefaultFilters<TSavedFilters>(
    ELocalSavedFilters.SalesToFrontLineReadyGraph,
    {
      dateRange: currentMonth,
    }
  );

  const [refreshWidget, setRefreshWidget] = useState<number>(0);
  const [showWidgets, setShowWidgets] = useState<boolean>(true);
  const [dateRange, setDateRange] = useState<IDateRangeProps["value"]>(
    defaultFilters.dateRange
  );

  const userAuth = useAppSelector(selectUserData);
  
  const lineGraphFetchParameters = useMemo(
    () => ({
      date_from: format(dateRange.startDate, apiDateFormat),
      date_to: format(dateRange.endDate, apiDateFormat),
      dealership_uid: userAuth.dealerV2.uid,
      inventory: userAuth.inventory.value,
    }),
    [dateRange.endDate, dateRange.startDate, userAuth.dealerV2.uid, userAuth.inventory.value]
  );
  const widgetFetchParameters = useMemo(
    () => ({
      date_from: format(currentMonth.startDate, apiDateFormat),
      date_to: format(currentMonth.endDate, apiDateFormat),
      dealership_uid: userAuth.dealerV2.uid,
      inventory: userAuth.inventory.value,
    }),
    [userAuth.dealerV2.uid, userAuth.inventory.value]
  );
  const { data: frontLineGraphData } =
    useGetSales<EModulesGetSales.FrontLineGraphV2>({
      module: EModulesGetSales.FrontLineGraphV2,
      params: lineGraphFetchParameters,
    });
  const { data: resultsSummaryData } =
    useGetSales<EModulesGetSales.ResultsSummaryV2>({
      module: EModulesGetSales.ResultsSummaryV2,
      params: widgetFetchParameters,
      queryKeys: [refreshWidget],
    });

  const { mutate: mutateSalesGoal } =
    usePostSales<EModulesPostSales.AddSalesGoalV2>({
      module: EModulesPostSales.AddSalesGoalV2,
      // params: { dealership_uid: userAuth.dealerV2.uid },
    });
  // const { mutate: mutateSalesPace } =
  //   usePostSales<EModulesPostSales.AddSalesPace>({
  //     module: EModulesPostSales.AddSalesPace,
  //     params: { dealership_uid: userAuth.dealerV2.uid },
  //   });

  useEffect(() => {
    setDefaultFilters<TSavedFilters>(
      ELocalSavedFilters.SalesToFrontLineReadyGraph,
      {
        dateRange,
      }
    );
  }, [dateRange]);

  const formattedFrontLineData = useMemo(
    () =>
      frontLineGraphData?.results?.map(
        ({ count, date_range: [startDate, endDate] }) => ({
          x: formatDateRangeLabel(startDate, endDate),
          y: count,
        })
      ) || [],
    [frontLineGraphData]
  );

  const onChangeDateRange: IDateRangeProps["onChange"] = useCallback(
    ([startDate, endDate]) => {
      setDateRange({ startDate, endDate });
    },
    []
  );

  const onToggleWidget = useCallback(
    (newValue: boolean) => setShowWidgets(newValue),
    []
  );

  // const onSaveSalesPace = useCallback(
  //   ({ newValue, onCloseEdit, setIsSubmitting }: IOnSaveParameter) => {
  //     setIsSubmitting(true);

  //     mutateSalesPace(
  //       { sales_pace: newValue },
  //       {
  //         onError: () => {
  //           setIsSubmitting(false);
  //           toast.error("Couldn't save Sales Pace");
  //         },
  //         onSuccess: () => {
  //           setRefreshWidget((prevState) => ++prevState);
  //           setIsSubmitting(false);
  //           onCloseEdit();
  //           toast.success("Sales Pace has been added");
  //         },
  //       }
  //     );
  //   },
  //   [mutateSalesPace]
  // );

  const onSaveSalesGoal = useCallback(
    ({ newValue, onCloseEdit, setIsSubmitting }: IOnSaveParameter) => {
      setIsSubmitting(true);

      mutateSalesGoal(
        { 
          goal: newValue, 
          inventory: userAuth.inventory.value,
          dealership_uid: userAuth.dealerV2.uid,
       },
        {
          onError: () => {
            setIsSubmitting(false);
            toast.error("Couldn't save Sales Goal");
          },
          onSuccess: () => {
            setRefreshWidget((prevState) => ++prevState);
            setIsSubmitting(false);
            onCloseEdit();
            toast.success("Sales Goal has been added");
          },
        }
      );
    },
    [mutateSalesGoal, userAuth.dealerV2.uid, userAuth.inventory.value]
  );

  const metrics: TMetricProps[] = useMemo(
    () => [
      {
        className: styles["frontLineReadyVehicle__graph__widgets--salesGoal"],
        label: "Sales Goal",
        labelEdit: (
          <span>
            Please enter current <br /> Sales Goal
          </span>
        ),
        onSaveEdit: onSaveSalesGoal,
        value: resultsSummaryData?.sales_goal || 0,
      },
      {
        className: styles["frontLineReadyVehicle__graph__widgets--salesPace"],
        label: "Sales Pace",
        labelEdit: (
          <span>
            Please enter your <br /> Month to Date Sales
          </span>
        ),
        // onSaveEdit: onSaveSalesPace,
        value: resultsSummaryData?.sales_pace || 0,
      },
      {
        className:
          styles["frontLineReadyVehicle__graph__widgets--frontLineReady"],
        label: "Front Line Ready",
        value: resultsSummaryData?.front_line_ready || 0,
      },
      {
        className:
          styles["frontLineReadyVehicle__graph__widgets--salesFlrRatio"],
        label: "Sales / FLR Ratio",
        value: resultsSummaryData?.sales_front_line_ready_ratio || 0, // sales_flr_radio
      },
      {
        className:
          styles["frontLineReadyVehicle__graph__widgets--flrToHitGoal"],
        label: "FLR to Hit Goal",
        value: resultsSummaryData?.front_line_ready_to_sales_goal || 0, //flr_to_goal
      },
    ],
    [
      onSaveSalesGoal,
      // onSaveSalesPace,
      resultsSummaryData?.front_line_ready_to_sales_goal,
      resultsSummaryData?.front_line_ready,
      resultsSummaryData?.sales_front_line_ready_ratio,
      resultsSummaryData?.sales_goal,
      resultsSummaryData?.sales_pace,
    ]
  );

  return (
    <div className={styles["frontLineReadyVehicle"]}>
      <div className={styles["frontLineReadyVehicle__top"]}>
        <Typography
          className={styles["frontLineReadyVehicle__title"]}
          fontSize="text-xl"
          fontWeight="bold"
        >
          Front Line Ready Trend
        </Typography>

        {!isMobileView && (
          <div className={styles["frontLineReadyVehicle__toggleWidget"]}>
            <Typography fontWeight="medium">
              {showWidgets ? "Hide" : "Show"} Widgets
            </Typography>
            <Switch checked={showWidgets} onChange={onToggleWidget} />
          </div>
        )}
      </div>
      {showWidgets ? (
        <div
          className={cs(styles["frontLineReadyVehicle__graph"], {
            [styles["frontLineReadyVehicle__graph--showWidgets"]]:
              showWidgets && !isMobileView,
          })}
        >
          <div className={styles["frontLineReadyVehicle__graph__line"]}>
            <LineChart
              key={`${showWidgets}`}
              data={[
                {
                  id: "frontLineReadyVehicles",
                  data: formattedFrontLineData,
                  name: "",
                  color: "#609FF5",
                },
              ]}
              dateRange={dateRange}
              onChangeDateRange={onChangeDateRange}
              options={graphDateRange}
            />
          </div>
          <div
            className={cs(styles["frontLineReadyVehicle__graph__widgets"], {
              [styles["frontLineReadyVehicle__graph__widgets--showWidgets"]]:
                showWidgets && isMobileView,
            })}
          >
            <div className={styles["frontLineReadyVehicle__widgetLabel"]}>
              {((isMobileView && showWidgets) || !isMobileView) && (
                <Typography fontWeight="bold">Month to Date</Typography>
              )}
              {isMobileView && (
                <div className={styles["frontLineReadyVehicle__toggleWidget"]}>
                  <Typography fontWeight="medium">
                    {showWidgets ? "Hide" : "Show"} Widgets
                  </Typography>
                  <Switch checked={showWidgets} onChange={onToggleWidget} />
                </div>
              )}
            </div>
            {metrics.map(
              ({ className, label, labelEdit, onSaveEdit, value }) => (
                <MetricCard
                  key={label}
                  className={className}
                  label={label}
                  labelEdit={labelEdit}
                  onSaveEdit={onSaveEdit}
                  value={value}
                />
              )
            )}
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default FrontLineReadyVehicles;
