import { useMemo, useState } from "react";
import { format } from "date-fns";
import useGetSales, { EModules } from "api/useGetSales";
import Table from "components/Table";
import Typography from "components/Typography";
import apiDateFormat from "constants/apiDateFormat";
import { allTime, lastWeek } from "constants/commonDateRanges";
import styles from "pages/Home/styles.module.scss";
import { getColumnsSalesTrend, getColumnsSalesTrendNewInventory, getSalesTrendFormatV2 } from "./utils";
import useGetProviders, { EProvidersModules } from "api/useGetProviders";
import { IProviderCheckList } from "types/IProviderCheckList";
import { filterColumnsV2 } from "utils/filterColumns";
import { useAppSelector } from "hooks/store";
import { selectUserData } from "store/auth/authSlice";

const FrontLineReadyTable = () => {

  const userAuth = useAppSelector(selectUserData);
  
  const date_from = format(allTime.startDate, apiDateFormat);
  const date_to = format(allTime.endDate, apiDateFormat);

  const { data, isFetching } = useGetSales<EModules.SalesToFrontLineReadyV2>({
    module: EModules.SalesToFrontLineReadyV2,
    params: {
      date_from: date_from,
      date_to: date_to,
      dealership_uid: userAuth.dealerV2.uid,
      inventory: userAuth.inventory.value
    },
  });

  const [providers, setProviders] = useState<IProviderCheckList[]>([]);

  // get 3rd party providers
  const fetchProvders = useMemo(() => ({
    module: EProvidersModules.ThirdPartyProviderV2,
    params: {
      dealership_uid: userAuth.dealerV2.uid,
      inventory: userAuth.inventory.value
    }
  }), [userAuth.dealerV2.uid, userAuth.inventory.value]);
  
  const providerList = useGetProviders<EProvidersModules.ThirdPartyProviderV2>(fetchProvders);
  
  // set providers
  useMemo(() => {
    if (!providerList.isFetching && providerList.data) {
      const tempProviders: IProviderCheckList[] = ((providerList.data as any).results as any[]).map((provider) => {
        return {
          id: provider.value,
          label: provider.label,
          value: true
        }
      });        
      setProviders(tempProviders);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [providerList.data, providerList.isFetching]);

  const salesTrendData = useMemo(() => getSalesTrendFormatV2(data), [data]);
  
  const columns = useMemo(
    () =>
      getColumnsSalesTrend({
        endDate: format(lastWeek.endDate, apiDateFormat),
        startDate: format(lastWeek.startDate, apiDateFormat),
      }),
    []
  );

  const newColumns = useMemo(
    () =>
      getColumnsSalesTrendNewInventory({
        endDate: date_to,
        startDate: date_from,
      }),
    [date_to, date_from]
  );

  const filteredColumns = useMemo(
    () => filterColumnsV2(userAuth.inventory.value === 'new' ? newColumns : columns, providers),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [providers, userAuth.inventory.value]
  );

  return (
    <div className={styles.salesFrontContainer}>
      <Typography
        className={styles["salesFrontContainer__label"]}
        fontSize="text-xl"
        fontWeight="bold"
      >
        Quick Results
      </Typography>
      <Table
        className={styles.frontLineReadyTable}
        columns={filteredColumns}
        data={salesTrendData}
        isLoading={isFetching}
        showPagination={false}
        sort={{
          mode: "client",
        }}
      />
    </div>
  );
};

export default FrontLineReadyTable;
