import { Button, FormControl, FormErrorMessage, FormLabel, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay } from "@chakra-ui/react"
import { yupResolver } from "@hookform/resolvers/yup";
import usePostSales, { EModules } from "api/usePostSales";
import { useAppSelector } from "hooks/store";
import { Dispatch, SetStateAction, useEffect } from "react";
import { useForm } from "react-hook-form";
import { selectUserData } from "store/auth/authSlice";
import { EProviderInitials } from "types/APIProviderRanks";
import getProviderName from "utils/getProviderName";
import * as yup from "yup";

type EditBudgetModalProps = {
    provider: EProviderInitials;
    isOpen: boolean;
    onClose: () => void;
    budget: number;
    setRefreshTable: Dispatch<SetStateAction<number>>;
}

type BudgetForm = yup.InferType<typeof budgetSchema>;

const budgetSchema = yup.object().shape({
    budget: yup
      .number()
      .required("Budget is required")
      .integer()
      .typeError('Must be an integer'),
});

export const EditBudgetModal = ({ isOpen, onClose, provider, budget, setRefreshTable }: EditBudgetModalProps) => {

    const userAuth = useAppSelector(selectUserData);
    
    const { isLoading, mutate } = usePostSales<EModules.SetBudgetROI>({
        module: EModules.SetBudgetROI,
        // params: { 
        //   dealership_uid: userAuth.dealerV2.uid, 
        // },
      });
      
    const {
        register,
        handleSubmit,
        formState: { errors, isSubmitting },
        reset
    } = useForm<BudgetForm>({ 
        resolver: yupResolver(budgetSchema),
        defaultValues: {
            budget: budget
        }
    });

    useEffect(() => {
        reset({ budget });
    }, [budget])
      
    const onSubmit = async (formData: BudgetForm) => {
        console.log(formData);
        mutate(
            { 
              budget: formData.budget, 
              source: provider,
              dealership_uid: userAuth.dealerV2.uid,
              inventory: userAuth.inventory.value,
            },
            { onSuccess: () => {
                if (onClose) {
                    onClose();
                    setRefreshTable((prevState) => ++prevState);
                }
            } }
        );
    };

    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
        >
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>{`${provider ? getProviderName(provider) : ''} Budget`}</ModalHeader>
                <ModalCloseButton />
                <form
                    onSubmit={handleSubmit(onSubmit)}
                >
                    <ModalBody>
                        <FormControl isInvalid={errors.budget?.message !== undefined}>
                            <FormLabel>Please add the MONTHLY BUDGET for this vendor</FormLabel>
                            <Input 
                                {...register('budget')}
                                disabled={isSubmitting || isLoading}
                                autoFocus={true}
                            />
                            <FormErrorMessage>
                                {errors.budget && errors.budget.message}
                            </FormErrorMessage>
                        </FormControl>
                    </ModalBody>

                    <ModalFooter>
                        <Button 
                            type="submit" 
                            isLoading={isSubmitting}
                            background="linear-gradient(45deg,#344054,#475467)"
                            _hover={{ background: "linear-gradient(45deg,#344054,#475467)" }}
                            color="#fff"
                            disabled={isSubmitting || isLoading}
                        >
                            Submit
                        </Button>
                    </ModalFooter>
                </form>
            </ModalContent>
        </Modal>
    );
}