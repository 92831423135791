import { FC, useState } from "react";
import { ESteps } from "../../types/ForgotPassword";
import ChangePassword from "./components/ChangePassword";
import SendOTP from "./components/SendOTP";
import styles from "./styles.module.scss";

const ForgotPassword: FC = () => {
  const [step, setStep] = useState<ESteps>(ESteps.SendOTP);
  const [userEmail, setUserEmail] = useState<string>("");

  return (
    <>
      <div className={styles.background} />
      {step === ESteps.SendOTP && (
        <SendOTP setStep={setStep} setUserEmail={setUserEmail} />
      )}
      {step === ESteps.ChangePassword && userEmail && (
        <ChangePassword userEmail={userEmail} />
      )}
    </>
  );
};

export default ForgotPassword;
