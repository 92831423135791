import { Box } from '@chakra-ui/react';
import { IKaTableProps, ITableProps, Table } from 'ka-table';
import 'ka-table/style.css';
import SearchField from '../SearchField';
import debounce from 'utils/debounce';


type TableV2Props<T> = {
    // search?: string;
    onSearch?: (value: string) => void;
    conttroller?: React.ReactNode
} & ITableProps<T> & IKaTableProps<T>;

const TableV2 = <T, >(props: TableV2Props<T>) => {

    const debouncedSearch = debounce((value: string) => {
        if (props.onSearch) {
            props.onSearch(value);
        }
    }, 500);
    
    return (
        <Box
            display={'flex'}
            flexDirection={'column'}
            gap={'10px'}
            paddingTop="15px"
        >
            <Box 
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                flexFlow="row wrap"
                gap="15px"
            >
                <SearchField 
                    onChange={(e) => debouncedSearch(e.target.value)}
                />
                {props.conttroller}
            </Box>
            <Table
                {...props}
            />
        </Box>
    );
};

export default TableV2;