export enum ESteps {
  ChangePassword = "changePassword",
  SendOTP = "sendOTP",
}

export interface ISendOTPForm {
  email: string;
}

export interface IChangePasswordForm {
  newPassword: string;
  oneTimePassword: string;
}
