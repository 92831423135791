import {
  FC,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useLocation } from "react-router-dom";
import cs from "classnames";
import useScreenSize from "hooks/useScreenSize";
import Header from "./components/Header";
import Sidebar from "./components/Sidebar";
import styles from "./styles.module.scss";
import { menuItems } from "./constants";
import HeaderV2 from "layouts/v2/Header";

interface IProps {
  children?: ReactNode;
}

const SidebarHeader: FC<IProps> = ({ children }) => {
  const { pathname, hash } = useLocation();

  const screenSize = useScreenSize();
  const isMobileView = useMemo(
    () => screenSize !== "2xl" && screenSize !== "xl",
    [screenSize]
  );

  const anchorRef = useRef(null);

  useEffect(() => {
    if (anchorRef.current) {
      if (hash) {
        const element = document.querySelector(hash);
        if (element) {
          element.scrollIntoView({ behavior: "smooth", block: "start" });
        }
      }
    }
  }, [hash]);

  const onCheckPageTitle = useCallback(() => {
    const { label } = menuItems.find(({ subMenu, link }) => {
      if (subMenu && !link) {
        const subMenuIndex = subMenu.findIndex(
          ({ link: submenuLink }) =>
            submenuLink.replace(/\?.*/, "") === pathname
        );

        return subMenuIndex !== -1;
      }

      return link === pathname;
    }) || { label: "", subMenu: [], iconSrc: "" };

    return label;
  }, [pathname]);

  const [isSidebarClose, setIsSidebarClose] = useState<boolean>(false);
  const [hoverToggleSidebar, setHoverToggleSidebar] = useState<boolean>(false);
  const [pageTitle, setPageTitle] = useState(() => onCheckPageTitle());

  const [isMobileSidebarClose, setIsMobileSidebarClose] = useState<boolean>(true);
  const [hoverToggleMobileSidebar, setHoverToggleMobileSidebar] = useState<boolean>(false);


  useEffect(() => {
    setPageTitle(() => onCheckPageTitle());
  }, [onCheckPageTitle, pathname]);

  return (
    <div
      className={cs(styles.containerLayout, {
        [styles.isSidebarClose]: isMobileView ? isMobileSidebarClose : isSidebarClose,
        // [styles.isHoverSidebar]: hoverToggleSidebar,
      })}
    >

      {isMobileView ? (
        <Sidebar
          hoverToggleSidebar={hoverToggleMobileSidebar}
          // isMobileView={isMobileView}
          isSidebarClose={isMobileSidebarClose}
          setHoverToggleSidebar={setHoverToggleMobileSidebar}
          setIsSidebarClose={setIsMobileSidebarClose}
        />
      ) : (

        <Sidebar
          hoverToggleSidebar={hoverToggleSidebar}
          // isMobileView={isMobileView}
          isSidebarClose={isSidebarClose}
          setHoverToggleSidebar={setHoverToggleSidebar}
          setIsSidebarClose={setIsSidebarClose}
        />
      )}

      {/* <Header pageTitle={pageTitle} setIsSidebarClose={isMobileView ? setIsMobileSidebarClose : setIsSidebarClose} /> */}
      <HeaderV2
        isOpen={isMobileView ? isMobileSidebarClose : isSidebarClose}
        pageTitle={pageTitle}
        setIsSidebarClose={isMobileView ? setIsMobileSidebarClose : setIsSidebarClose}
      />
      <div ref={anchorRef} className={styles.content}>
        {children}
      </div>
    </div>
  );
};

export default SidebarHeader;
