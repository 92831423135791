import { useQuery } from "@tanstack/react-query";
import { IDateRanges, IDealership, TInventory, TPagination } from "types/APIParameters";
import { IAPIResults } from "types/APIResults";
import fetchData from "utils/fetchData";

interface IParams extends IDealership {}

interface IThirdPartyProvdersParams extends IParams, TInventory {}

export enum EProvidersModules {
  ThirdPartyProviderV2 = 'api/providers/v2/third-party-provider',
  LeadROI = "api/v2/providers/leads-roi",
}

export interface IAPIGetProvidersRoot {
    status:  boolean;
    message: string;

}
export interface IAPIGetProvider extends IAPIGetProvidersRoot {
    results: Result[];
}

export interface Result {
    label: string;
    value: string;
}

type TLeadROIParams = {
  ordering?: keyof IAPIGetLeadROI;
} & IParams & IDateRanges & TInventory & TPagination;

export type IAPIGetLeadROI = {
  name:                     string | null;
  total_lead_count:         number | null;
  good_lead_count:          number | null;
  sold_lead_count:          number | null;
  website_total_lead_count: number | null;
  website_good_lead_count:  number | null;
  website_sold_lead_count:  number | null;
}

interface IOptions<T extends string> {
  module: EProvidersModules;
  params?: T extends EProvidersModules.ThirdPartyProviderV2
    ? IThirdPartyProvdersParams 
    : T extends EProvidersModules.LeadROI
    ? TLeadROIParams
    : IParams;
}

type FetchDataReturnType<T extends IOptions<T>["module"]> =
  T extends EProvidersModules.ThirdPartyProviderV2
    ? IAPIGetProvider
    : T extends EProvidersModules.LeadROI
    ? IAPIResults<IAPIGetLeadROI[]>
    : T;

const useGetProviders = <T extends IOptions<T>["module"]>({
  module,
  params,
}: IOptions<T>) => {
  return useQuery({
    refetchOnWindowFocus: false,
    queryFn: getProvidersAsync<T>({ module, params }),
    queryKey: ["getProviders", module, ...Object.values(params || {})],
  });
};

export const getProvidersAsync = <T extends IOptions<T>["module"]>({
  module,
  params,
}: IOptions<T>) =>
  fetchData<FetchDataReturnType<T>>({
    isAuth: true,
    method: "GET",
    params,
    url: `${process.env.REACT_APP_API_URL || ""}${
      module ? `${module}` : ""
    }`,
  });

export default useGetProviders;
