import { createColumnHelper } from "@tanstack/react-table";
import TAggregatorColumnPDF from "types/TAggregatorColumnPDF";
// import styles from "./styles.module.scss";
import { IAPIGetLeadROI } from "api/useGetProviders";
import { TInventoryOptionValue } from "constants/common";
import TAggregatorColumnXLS from "types/TAggregatorColumnXLS";
import { capitalizeFirstLetter } from "utils/helper";

const columnHelper = createColumnHelper<IAPIGetLeadROI>();

export const getColumns = (inventory: TInventoryOptionValue) => [
  columnHelper.accessor((row) => row.name, {
    id: "name",
    cell: (info) => info.getValue(),
    enableSorting: true,
    header: `Provider: ${capitalizeFirstLetter(inventory)}`,
  }),
  columnHelper.accessor((row) => row.total_lead_count, {
    id: "total_lead_count",
    cell: (info) => info.getValue(),
    enableSorting: true,
    header: `Total Lead: ${capitalizeFirstLetter(inventory)}`,
  }),
  columnHelper.accessor((row) => row.good_lead_count, {
    id: "good_lead_count",
    cell: (info) => info.getValue(),
    enableSorting: true,
    header: `Good Lead: ${capitalizeFirstLetter(inventory)}`,
  }),
  columnHelper.accessor((row) => row.sold_lead_count, {
    id: "sold_lead_count",
    cell: (info) => info.getValue(),
    enableSorting: true,
    header: `Sold: ${capitalizeFirstLetter(inventory)}`,
  }),
  // columnHelper.accessor('website_total_lead_count', {
  //   cell: (info) => info.getValue(),
  //   header: () => <span>Website Total</span>,
  //   id: `website_total_lead_count`,
  // }),
  // columnHelper.accessor('website_good_lead_count', {
  //   cell: (info) => info.getValue(),
  //   header: () => <span>Website Good</span>,
  //   id: `website_good_lead_count`,
  // }),
  // columnHelper.accessor('website_sold_lead_count', {
  //   cell: (info) => info.getValue(),
  //   header: () => <span>Website Sold</span>,
  //   id: `website_good_lead_count`,
  // }),
];

export const getPdfColumns = (inventory: TInventoryOptionValue): TAggregatorColumnPDF<IAPIGetLeadROI>[] => [
  // {
  //   label: "Rank",
  //   key: "rank",
  // },
  {
    // label: "Provider",
    label: `Provider: ${capitalizeFirstLetter(inventory)}`,
    key: "name",
  },
  {
    // label: "Total Lead",
    label: `Total Lead: ${capitalizeFirstLetter(inventory)}`,
    key: "total_lead_count",
  },
  {
    // label: "Good Lead",
    label: `Good Lead: ${capitalizeFirstLetter(inventory)}`,
    key: "good_lead_count",
  },
  {
    // label: "Sold",
    label: `Sold: ${capitalizeFirstLetter(inventory)}`,
    key: "sold_lead_count",
  },
  // {
  //   label: "Website Total",
  //   key: "website_total_lead_count",
  // },
  // {
  //   label: "Website Good",
  //   key: "website_good_lead_count",
  // },
  // {
  //   label: "Website Sold",
  //   key: "website_sold_lead_count",
  // },
];

export const getXLSColumns = (inventory: TInventoryOptionValue): TAggregatorColumnXLS<IAPIGetLeadROI>[] => [
  // {
  //   label: "Rank",
  //   key: "rank",
  // },
  {
    // label: "Provider",
    label: `Provider: ${capitalizeFirstLetter(inventory)}`,
    key: "name",
  },
  {
    // label: "Total Lead",
    label: `Total Lead: ${capitalizeFirstLetter(inventory)}`,
    key: "total_lead_count",
  },
  {
    // label: "Good Lead",
    label: `Good Lead: ${capitalizeFirstLetter(inventory)}`,
    key: "good_lead_count",
  },
  {
    // label: "Sold",
    label: `Sold: ${capitalizeFirstLetter(inventory)}`,
    key: "sold_lead_count",
  },
  // {
  //   label: "Website Total",
  //   key: "website_total_lead_count",
  // },
  // {
  //   label: "Website Good",
  //   key: "website_good_lead_count",
  // },
  // {
  //   label: "Website Sold",
  //   key: "website_sold_lead_count",
  // },
];
