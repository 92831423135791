// Popover.tsx
import React, { useState, useRef, useEffect, useCallback } from 'react';
import styles from './styles.module.scss';

interface PopoverProps {
  trigger: React.ReactNode;
  children?: React.ReactNode;
}

const Popover: React.FC<PopoverProps> = ({ trigger, children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [positionClass, setPositionClass] = useState<string>('');
  const triggerRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);

  const handleTogglePopover = () => {
    setIsOpen(!isOpen);
  };

  const handleClickOutside = useCallback(
    (event: MouseEvent) => {
      if (
        contentRef.current &&
        !contentRef.current.contains(event.target as Node) &&
        triggerRef.current &&
        !triggerRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    },
    [setIsOpen]
  );

  useEffect(() => {
    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen, handleClickOutside]);

  useEffect(() => {
    if (triggerRef.current) {
      const triggerRect = triggerRef.current.getBoundingClientRect();
      const { innerWidth, innerHeight } = window;

      const shouldShowOnLeft = triggerRect.left > innerWidth - triggerRect.right;
      const shouldShowOnTop = triggerRect.top > innerHeight - triggerRect.bottom;

      let positionClass = '';
      if (shouldShowOnLeft) {
        positionClass = 'left';
      } else if (shouldShowOnTop) {
        positionClass = 'top';
      } else {
        positionClass = 'bottom';
      }
      setPositionClass(positionClass);
    }
  }, [isOpen]);

  return (
    <div className={styles['popover-container']}>
      <div className={styles['popover-trigger']} ref={triggerRef} onClick={handleTogglePopover}>
        {trigger}
      </div>
      {isOpen && (
        <div className={`${styles['popover-content']} ${styles[positionClass]}`} ref={contentRef}>
          {children}
        </div>
      )}
    </div>
  );
};

export default Popover;
