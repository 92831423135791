import cs from "classnames";
import { MouseEvent, ReactNode, forwardRef } from "react";
import styles from "./styles.module.scss";

interface ButtonProps {
  children: ReactNode | string;
  className?: string;
  disabled?: boolean;
  onClick?: (e: MouseEvent<HTMLButtonElement>) => void;
  variant?: "primary" | "secondary" | "error" | "outline" | "text";
  size?: "sm" | "md" | "lg" | "xl" | "xxl";
}

const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      children,
      className,
      disabled,
      onClick,
      variant = "primary",
      size = "md",
    },
    ref
  ) => {
    return (
      <button
        {...(ref && { ref })}
        className={cs(
          styles.buttonContainer,
          styles[variant],
          styles[size],
          className
        )}
        disabled={disabled}
        {...(onClick && { onClick })}
      >
        {children}
      </button>
    );
  }
);

export default Button;
