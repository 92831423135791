import { createColumnHelper } from "@tanstack/react-table";
import { TopTenWebsiteSourceV2Data } from "api/useGetSales";
import { TInventoryOptionValue } from "constants/common";
import TAggregatorColumnPDF from "types/TAggregatorColumnPDF";
import TAggregatorColumnXLS from "types/TAggregatorColumnXLS";
import { capitalizeFirstLetter, formatNumber } from "utils/helper";

const columnHelper = createColumnHelper<TopTenWebsiteSourceV2Data>();

export const getColumns = (inventory: TInventoryOptionValue) => ([
  columnHelper.accessor((row) => row.rank, {
    id: "rank",
    cell: (info) => info.getValue(),
    header: `Rank: ${capitalizeFirstLetter(inventory)}`,
    enableSorting: true,
  }),
  columnHelper.accessor((row) => row.source_medium, {
    id: "source",
    cell: (info) => info.getValue(),
    enableSorting: false,
    header: `Source: ${capitalizeFirstLetter(inventory)}`,
  }),
  columnHelper.accessor((row) => row.total_vdps, {
    id: "vdps",
    cell: (info) => formatNumber(info.getValue()),
    header: `VDPs: ${capitalizeFirstLetter(inventory)}`,
    enableSorting: true,
  }),
  columnHelper.accessor((row) => row.total_srps, {
    id: "srps",
    cell: (info) => formatNumber(info.getValue()),
    header: `SRPs: ${capitalizeFirstLetter(inventory)}`,
    enableSorting: true,
  }),
]);

export const getPdfColumns = (inventory: TInventoryOptionValue): TAggregatorColumnPDF<TopTenWebsiteSourceV2Data>[] => ([
  {
    // label: "Rank",
    label: `Rank: ${capitalizeFirstLetter(inventory)}`,
    key: "rank",
  },
  {
    // label: "Source",
    label: `Source: ${capitalizeFirstLetter(inventory)}`,
    key: "source_medium",
  },
  {
    // label: "VDPs",
    label: `VDPs: ${capitalizeFirstLetter(inventory)}`,
    key: "total_vdps",
  },
  {
    // label: "SRPs",
    label: `SRPs: ${capitalizeFirstLetter(inventory)}`,
    key: "total_srps",
  },
]);

export const getXLSColumns = (inventory: TInventoryOptionValue): TAggregatorColumnXLS<TopTenWebsiteSourceV2Data>[] => ([
  {
    // label: "Rank",
    label: `Rank: ${capitalizeFirstLetter(inventory)}`,
    key: "rank",
  },
  {
    // label: "Source",
    label: `Source: ${capitalizeFirstLetter(inventory)}`,
    key: "source_medium",
  },
  {
    // label: "VDPs",
    label: `VDPs: ${capitalizeFirstLetter(inventory)}`,
    key: "total_vdps",
  },
  {
    // label: "SRPs",
    label: `SRPs: ${capitalizeFirstLetter(inventory)}`,
    key: "total_srps",
  },
]);
