import { Box, Th, Thead, Tooltip, Tr } from "@chakra-ui/react";
import { flexRender, Header, SortDirection, Table } from "@tanstack/react-table";
import { RiArrowDownSLine, RiArrowUpSLine, RiExpandUpDownLine } from "react-icons/ri";

type TableHeaderProps<TData> = {
    table: Table<TData>
};

const TableHeader = <TData, >(props: TableHeaderProps<TData>) => {

    const { table } = props;

    const onHandleResize = (header: Header<TData, unknown>) => (e: React.MouseEvent | React.TouchEvent) => {
        e.preventDefault();
        e.stopPropagation();
        header.getResizeHandler()(e);
    };

    const getArrow = (sortBy: false | SortDirection) => {
        if (sortBy === 'asc') {
            return <RiArrowUpSLine size={16} />;
        } if (sortBy === 'desc') { 
            return <RiArrowDownSLine size={16} />;
        } else {
            return <RiExpandUpDownLine size={16} />;
        }
    };
  
    return (
        <Thead>
            {table.getHeaderGroups().map((headerGroup) => (
                <Tr key={headerGroup.id}>
                    {headerGroup.headers.map((header) => {

                        const tempHeader = header.isPlaceholder ? null : flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                        );

                        return (
                        <Th 
                            key={header.id}
                            // onClick={() => header.column.getCanSort() ? header.column.toggleSorting() : undefined}
                            textTransform="none"
                            width={header.getSize()}
                            id={header.id}
                            style={header.column.columnDef.meta?.headerStyle}
                            position="relative"
                            colSpan={header.colSpan}
                        >
                            <Box
                                display="flex"
                                justifyContent="space-between"
                                alignItems="center"
                                // cursor={header.column.getCanSort() ? 'pointer' : 'default'}
                                gap="10px"
                                fontSize="14px"
                            >
                            <Tooltip 
                                hasArrow={true}
                                label={tempHeader}
                                placement="top"
                            >
                                <Box
                                    textOverflow="ellipsis"
                                    overflow="hidden"
                                    whiteSpace="nowrap"
                                    flex="1"
                                    __css={{
                                        'span': {
                                        display: 'block',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        whiteSpace: 'nowrap',
                                        width: '100%',
                                        }
                                    }}
                                >
                                {tempHeader}
                                </Box>
                            </Tooltip>
                            {header.column.getCanSort() && (
                                <Box
                                onClick={() => header.column.getCanSort() ? header.column.toggleSorting() : undefined}
                                cursor={header.column.getCanSort() ? 'pointer' : 'default'}
                                >
                                {getArrow(header.column.getIsSorted())}
                                </Box>
                            )}
                            </Box>
                            {header.column.getCanResize() ? (
                                <Box
                                    cursor="ew-resize"
                                    position="absolute"
                                    height="10px"
                                    borderRight="2px solid"
                                    borderColor="#d7d3d3"
                                    top="50%"
                                    transform="translateY(-50%)"
                                    right="10px"
                                    onClick={(e) => e.stopPropagation()}
                                    onMouseDown={onHandleResize(header)}
                                    onTouchStart={onHandleResize(header)}
                                />
                            ) : null}
                        </Th>
                        )
                    })}
                </Tr>
            ))}
        </Thead>
    );
};

export default TableHeader;