import { EReportsModules } from 'api/useGetReports'
import { toast } from 'react-toastify';
import { logoutUser } from 'store/auth/authSlice';
import { defaultResultScores, setLoading, setNeedsAttention, setResultScoreGraph } from 'store/resultScore/resultScoreSlice';
import EDaysOnLot from 'types/APIDaysOnLot';
import { TDateRanges, TInventory, TParams } from 'types/APIParams';
import { api } from './api';


type TNeedsAttentionParams = TParams & TDateRanges & TInventory;


export type NeedAttentionData = {
    red_score:     number;
    yellow_score:  number;
    green_score:   number;
    needs_pricing: number;
    "0-15":        number[];
    "16-30":       number[];
    "31-45":       number[];
    "46-60":       number[];
    "60+":         number[];
}

export const needAttentionApi = api.injectEndpoints({
    endpoints: (build) => ({
        getNeedAttention: build.query<NeedAttentionData, TNeedsAttentionParams>({
            keepUnusedDataFor: 0,
            query: (params) => {

                const queryParams = new URLSearchParams(
                    Object.entries(params).filter(([_, value]) => value != null)
                    .map(([key, value]) => [key, value.toString()])
                ).toString();

                return `${EReportsModules.NeedsAttentionV2}?${queryParams}`
            },
            onQueryStarted: async (arg, api) => {
                
                const { dispatch, queryFulfilled } = api;

                dispatch(setLoading(true));

                try {

                    const { data } = await queryFulfilled;

                    dispatch(setNeedsAttention({
                        greenScore: data.green_score || 0,
                        needsPricing: data.needs_pricing || 0,
                        redScore: data.red_score || 0,
                        yellowScore: data.yellow_score || 0,
                    }));

                    const defaultCategories = [
                        EDaysOnLot.ZeroToFifteen,
                        EDaysOnLot.SixteenToThirty,
                        EDaysOnLot.ThirtyOneToFortyFive,
                        EDaysOnLot.FortySixToSixty,
                        EDaysOnLot.SixtyAbove,
                    ];

                    let formattedScores = defaultResultScores;

                    defaultCategories.forEach((category) => {
                        if (data) {
                            const [greenScore, yellowScore, redScore] = data[category];
                    
                            formattedScores = {
                                greenScores: [...formattedScores.greenScores, greenScore],
                                redScores: [...formattedScores.redScores, redScore],
                                yellowScores: [...formattedScores.yellowScores, yellowScore],
                            };
                        }
                    });

                    dispatch(setResultScoreGraph({
                        greenScores: formattedScores.greenScores,
                        redScores: formattedScores.redScores,
                        yellowScores: formattedScores.yellowScores,
                    }));

                    dispatch(setLoading(false));

                } catch (error) {

                    if ((error as any).error.status === 401) {
                        dispatch(logoutUser());
                    }
                    console.log(error);
                    toast.error('Failed to fetch data');
                    dispatch(setLoading(false));
                }
            },
        }),
    }),
});

export const { useGetNeedAttentionQuery } = needAttentionApi;