import { ChevronDownIcon } from "@chakra-ui/icons";
import { Button, Menu, MenuButton, MenuButtonProps, MenuItem, MenuList } from "@chakra-ui/react";

type DropdownMenuProps<T> = {
    title: string;
    menus: T[];
    value?: T;
    onChange?: (value: T) => void;
    menuButtonProps?: MenuButtonProps
};

const DropdownMenu = <T extends { label: string; value: any }>(props: DropdownMenuProps<T>) => {

    const handleOnchangge = (value: T) => () => {
        props.onChange && props.onChange(value);
    };

    return (
        <Menu
            
        >
            <MenuButton 
                as={Button} 
                rightIcon={<ChevronDownIcon />}
                variant="outline"
                padding="21px 16px"
                fontSize="14px"
                {...props.menuButtonProps}
            >
                {props.value?.label || props.title}
            </MenuButton>
            <MenuList
                maxHeight={250}
                overflowY="auto"
            >
                {props.menus.map((menu, index) => (
                    <MenuItem 
                        key={index}
                        onClick={handleOnchangge(menu)}
                        fontSize="14px"
                        padding="15px 15px"
                    >
                        {menu.label}
                    </MenuItem>
                ))}
            </MenuList>
        </Menu>
    );
}

export default DropdownMenu;