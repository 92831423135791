import { EColors } from "components/Badge";
import { ReactNode } from "react";
import { ReactComponent as ThumbsDownIcon } from "assets/icons/thumbs-down.svg";
import { ReactComponent as ThumbsUpIcon } from "assets/icons/thumbs-up.svg";
import { EAPIProviderRanks } from "types/APIProviderRanks";

interface IProviderRank {
  color: EColors;
  icon: ReactNode;
  label: string;
}

const getProviderRank = (rank: EAPIProviderRanks): IProviderRank => {
  switch (rank?.toLowerCase()) {
    case EAPIProviderRanks.Overpriced.toLowerCase():
      return {
        color: "error",
        icon: <ThumbsDownIcon />,
        label: "Overpriced",
      };
    case EAPIProviderRanks.Fair.toLowerCase():
      return {
        color: "primary",
        icon: <ThumbsUpIcon />,
        label: "Fair",
      };

    case EAPIProviderRanks.Good.toLowerCase():
      return {
        color: "indigo",
        icon: <ThumbsUpIcon />,
        label: "Good",
      };

    case EAPIProviderRanks.Great.toLowerCase():
      return {
        color: "success",
        icon: <ThumbsUpIcon />,
        label: "Great",
      };

    case EAPIProviderRanks.High.toLowerCase():
      return {
        color: "error",
        icon: <ThumbsDownIcon />,
        label: "High Price",
      };

    case EAPIProviderRanks.NotApplicable.toLowerCase():
      return {
        color: "blueLight",
        icon: null,
        label: "None",
      };

    case EAPIProviderRanks.Unknown.toLowerCase():
    // case EAPIProviderRanks.NaN.toLowerCase():
      return {
        color: "gray",
        icon: null,
        label: "Unknown",
      };

    default:
      return { color: "transparent", icon: null, label: "-" };
  }
};

export default getProviderRank;
