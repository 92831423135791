import { EProviderInitials } from "types/APIProviderRanks";
import { IProviderCheckList } from "types/IProviderCheckList";

type TColumns<T> = {
  filterColumnId?: EProviderInitials | EProviderInitials[];
} & T;

const filterColumns = <T>(
  columns: TColumns<T>[],
  selectedProviders: IProviderCheckList[]
): T[] =>
  columns.filter(({ filterColumnId }) => {
    if (Array.isArray(filterColumnId)) {
      return selectedProviders.some(({ id }) => {
        return filterColumnId.includes(id);
      });
    } else {
      const providerOptionIndex = selectedProviders.findIndex(
        (option) => filterColumnId === option.id
      );

      if (providerOptionIndex !== -1) {
        return selectedProviders[providerOptionIndex].value;
      }
    }

    return true;
  });

export default filterColumns;

export const filterColumnsV2 = <T>(
  columns: TColumns<T>[],
  selectedProviders: IProviderCheckList[]
): T[] =>
  columns.filter(({ filterColumnId }) => {
    // If filterColumnId is not defined, include the column
    if (!filterColumnId) {
      return true;
    }

    if (Array.isArray(filterColumnId)) {
      return selectedProviders.some(({ id }) => {
        return filterColumnId.includes(id);
      });
    } else {
      const providerOptionIndex = selectedProviders.findIndex(
        (option) => filterColumnId === option.id
      );

      // If filterColumnId exists in selectedProviders and its value is true, include the column
      if (providerOptionIndex !== -1) {
        return selectedProviders[providerOptionIndex].value;
      }
    }

    // If filterColumnId is defined but does not exist in selectedProviders, exclude the column
    return false;
  });
