import {
  Dispatch,
  FC,
  ReactNode,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import cs from "classnames";
import * as yup from "yup";
import Button from "components/Button";
import Modal from "components/Modal";
import ModalBody from "components/Modal/components/Body";
import ModalHeader from "components/Modal/components/Header";
import Typography from "components/Typography";
import TextInput from "components/TextInput";
import styles from "./styles.module.scss";

export interface IOnSaveParameter {
  newValue: IFormInput["input"];
  onCloseEdit: () => void;
  setIsSubmitting: Dispatch<SetStateAction<boolean>>;
}

type TEditProps =
  | {
      labelEdit?: string | ReactNode;
      onSaveEdit?: (param: IOnSaveParameter) => void;
    }
  | {
      labelEdit: string | ReactNode;
      onSaveEdit: (param: IOnSaveParameter) => void;
    };

export type TMetricProps = {
  className?: string;
  label: string;
  value: number;
} & TEditProps;

interface IFormInput {
  input: number;
}

const formSchema = yup.object().shape({
  input: yup
    .number()
    .transform((value, originalValue) =>
      typeof originalValue === "string" && originalValue.trim() === ""
        ? undefined
        : value
    )
    .required("Required"),
});

const MetricCard: FC<TMetricProps> = ({
  className,
  label,
  labelEdit,
  onSaveEdit,
  value,
}) => {
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const { formState, handleSubmit, register, reset, setFocus } =
    useForm<IFormInput>({
      resolver: yupResolver(formSchema),
      defaultValues: { input: value },
    });

  const onCloseEdit = useCallback(() => setIsEditing(false), []);

  const onResetForm = useCallback(() => {
    reset({ input: value });
  }, [reset, value]);

  useEffect(() => {
    if (onSaveEdit && isEditing) {
      onResetForm();
    }
  }, [isEditing, onResetForm, onSaveEdit, value]);

  useEffect(() => {
    setFocus("input");
  }, [isEditing, setFocus]);

  const { errors } = formState;

  return (
    <>
      <div className={cs(styles.metricCard, className)}>
        <div className={styles.metricCard__label_container}>
          <Typography className={styles.metricCard__label}>{label}</Typography>
          {onSaveEdit ? (
            <button
              onClick={() => {
                setIsEditing((p) => !p);
              }}
            >
              <img src="assets/icons/edit.svg" alt="logo" width="20rem" />
            </button>
          ) : null}
        </div>
        <Typography className={styles.metricCard__value}>{value}</Typography>
      </div>

      <Modal isOpen={isEditing} onClose={onCloseEdit}>
        <ModalHeader onClose={onCloseEdit}>
          <Typography
            className={styles["editModal__label"]}
            fontSize="text-lg"
            fontWeight="semibold"
          >
            {labelEdit}
          </Typography>
        </ModalHeader>
        <ModalBody>
          <form
            onSubmit={
              onSaveEdit
                ? handleSubmit(async ({ input }) =>
                    onSaveEdit({
                      newValue: input,
                      onCloseEdit,
                      setIsSubmitting,
                    })
                  )
                : () => {}
            }
          >
            <TextInput
              disabled={isSubmitting}
              type="number"
              name="input"
              register={register}
              errorMessage={errors.input?.message as string}
            />
            <Button
              className={styles["editModal__submitButton"]}
              disabled={isSubmitting}
              size="lg"
            >
              {isSubmitting ? "Submitting..." : "Submit"}
            </Button>
          </form>
        </ModalBody>
      </Modal>
    </>
  );
};

export default MetricCard;
