import { createColumnHelper } from "@tanstack/react-table";
import { IAPIDealRatingsV2 } from "api/useGetSales";
import Badge from "components/Badge";
import { TInventoryOptionValue } from "constants/common";
import {
  EAPIProviderRanks,
  EAPIProviderRanksReverse,
  EProviderInitials,
} from "types/APIProviderRanks";
import TAggregatorColumnPDF from "types/TAggregatorColumnPDF";
import TAggregatorColumnXLS from "types/TAggregatorColumnXLS";
import getProviderRank from "utils/getProviderRank";

const columnHelper = createColumnHelper<IAPIDealRatingsV2>();

export const getColumns = (inventory: TInventoryOptionValue) => [
  columnHelper.accessor("stock_number", {
    id: "stock_number",
    cell: (info) => info.getValue(),
    header: 'Stock #'
  }),
  columnHelper.accessor("vehicle", {
    id: "vehicle",
    cell: (info) => info.getValue(),
    header: 'Vehicle',
    size: 250,
  }),
  {
    filterColumnId: EProviderInitials.CarGurus,
    ...columnHelper.accessor("cgr_deal_rating", {
      id: "cgr_deal_rating",
      cell: (info) => {
        const { color, icon, label } = getProviderRank(
          info.getValue() as EAPIProviderRanks
        );
        return <Badge color={color} icon={icon} label={label} size="sm" />;
      },
      header: 'CarGurus'
    }),
  },
  {
    filterColumnId: EProviderInitials.Autotrader,
    ...columnHelper.accessor("atc_deal_rating", {
      id: "atc_deal_rating",
      cell: (info) => {
        const { color, icon, label } = getProviderRank(
          info.getValue() as EAPIProviderRanks
        );
        return <Badge color={color} icon={icon} label={label} size="sm" />;
      },
      header: 'Autotrader'
    }),
  },
  {
    filterColumnId: EProviderInitials.CarsDotCom,
    ...columnHelper.accessor("ccm_deal_rating", {
      id: "ccm_deal_rating",
      cell: (info) => {
        const { color, icon, label } = getProviderRank(
          info.getValue() as EAPIProviderRanks
        );
        return <Badge color={color} icon={icon} label={label} size="sm" />;
      },
      header: 'Cars.com'
    }),
  },
  {
    filterColumnId: EProviderInitials.Carfax,
    ...columnHelper.accessor("cfx_deal_rating", {
      id: "cfx_deal_rating",
      cell: (info) => {
        const { color, icon, label } = getProviderRank(
          info.getValue() as EAPIProviderRanks
        );
        return <Badge color={color} icon={icon} label={label} size="sm" />;
      },
      header: 'Carfax'
    }),
  },
  // Temporarily hides carfax column for demo purposes
  // columnHelper.accessor(
  //   (row) => getDealRatingPair(row.deal_ratings, "carfax"),
  //   {
  //     id: "carfax_deal_rating",
  //     cell: getProviderRatingCell,
  //     enableSorting: false,
  //     header: () => <span>Carfax</span>,
  //   }
  // ),
  columnHelper.accessor("price", {
    id: "price",
    cell: (info) => info.getValue(),
    header: 'Price',
    size: 100,
  }),
  // columnHelper.accessor("default_percentage_to_market", {
  //   id: "default_percentage_to_market",
  //   cell: (info) =>
  //     info.getValue() === null
  //       ? "N/A"
  //       : `${((info.getValue() || 0) * 100).toLocaleString()}%`,
  //   header: () => <span>Default % of Market</span>,
  //   size: 102,
  // }),
  columnHelper.accessor("dol", {
    id: "dol",
    cell: (info) => info.getValue(),
    header: 'DOL',
    size: 100,
  }),
  columnHelper.accessor("photos_count", {
    id: "photos_count",
    cell: (info) => info.getValue(),
    header: '# of Photos',
    size: 100,
  }),
];

export const getPdfColumns = (
  inventory: TInventoryOptionValue
): TAggregatorColumnPDF<IAPIDealRatingsV2>[] => [
  {
    label: "Stock #",
    key: "stock_number",
  },
  {
    label: "Vehicle",
    key: "vehicle",
  },
  {
    label: "CarGurus",
    key: "cgr_deal_rating",
    func(value) {
      return EAPIProviderRanksReverse[value as EAPIProviderRanks];
    },
  },
  {
    label: "Autotrader",
    key: "atc_deal_rating",
    func(value) {
      return EAPIProviderRanksReverse[value as EAPIProviderRanks];
    },
  },
  {
    label: "Cars.com",
    key: "ccm_deal_rating",
    func(value) {
      return EAPIProviderRanksReverse[value as EAPIProviderRanks];
    },
  },
  {
    label: "Carfax",
    key: "cfx_deal_rating",
    func(value) {
      return EAPIProviderRanksReverse[value as EAPIProviderRanks];
    },
  },
  // Temporarily hides carfax column for demo purposes
  // {
  //   label: "Carfax",
  //   key: "deal_ratings",
  //   func: (value) =>
  //     getProviderRatingCellPDF(value as IPairProviderRatings[], "carfax"),
  // },
  {
    label: "Price",
    key: "price",
  },
  // {
  //   label: "Default % of Market",
  //   key: "default_percentage_to_market",
  //   func: (value) =>
  //     value === null
  //       ? "N/A"
  //       : `${(((value as number) || 0) * 100).toLocaleString()}%`,
  // },
  {
    label: "DOL",
    key: "dol",
  },
  {
    label: "# of Photos",
    key: "photos_count",
  },
];

export const getXLSColumns = (
  inventory: TInventoryOptionValue
): TAggregatorColumnXLS<IAPIDealRatingsV2>[] => [
  {
    label: "Stock #",
    key: "stock_number",
  },
  {
    label: "Vehicle",
    key: "vehicle",
  },
  {
    label: "CarGurus",
    key: "cgr_deal_rating",
    func(value) {
      return EAPIProviderRanksReverse[value as EAPIProviderRanks];
    },
  },
  {
    label: "Autotrader",
    key: "atc_deal_rating",
    func(value) {
      return EAPIProviderRanksReverse[value as EAPIProviderRanks];
    },
  },
  {
    label: "Cars.com",
    key: "ccm_deal_rating",
    func(value) {
      return EAPIProviderRanksReverse[value as EAPIProviderRanks];
    },
  },
  {
    label: "Carfax",
    key: "cfx_deal_rating",
    func(value) {
      return EAPIProviderRanksReverse[value as EAPIProviderRanks];
    },
  },
  // Temporarily hides carfax column for demo purposes
  // {
  //   label: "Carfax",
  //   key: "deal_ratings",
  //   func: (value) =>
  //     getProviderRatingCellPDF(value as IPairProviderRatings[], "carfax"),
  // },
  {
    label: "Price",
    key: "price",
  },
  // {
  //   label: "Default % of Market",
  //   key: "default_percentage_to_market",
  //   func: (value) =>
  //     value === null
  //       ? "N/A"
  //       : `${(((value as number) || 0) * 100).toLocaleString()}%`,
  // },
  {
    label: "DOL",
    key: "dol",
  },
  {
    label: "# of Photos",
    key: "photos_count",
  },
];

export const defaultOffset = 0;
