import { Select } from "@chakra-ui/react";

type SelectItem<T> = {
    label: string;
    value: T;
};

type DolSelectProps<T> = {
    defaultValue?: SelectItem<T>;
    placeholder?: string;
    options: SelectItem<T>[];
    onChange?: (value: SelectItem<T>) => void;
};

const DolSelect = <T extends string, >(props: DolSelectProps<T>) => {
    return (
        <Select 
            bg="#344054"
            color="#fff"
            placeholder={props.placeholder}
            width="auto"
            onChange={(e) => {
                const value = props.options.find((option) => {
                    return option.value === e.target.value;
                });
                if (value) {
                    props.onChange?.(value);
                }
            }}
            value={props.defaultValue?.value}
        >
            {props.options.map((option, index) => (
                <option 
                    key={index} 
                    value={option.value}
                    style={{ 
                        backgroundColor: "#344054", 
                    }}
                >
                   {option.label}
                </option>
            ))}
        </Select>
    );
};

export default DolSelect;