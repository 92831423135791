import { Box, Tooltip } from "@chakra-ui/react";
import TableV2 from "components/v2/Table";
import { DataType, ITableProps, SortingMode, kaReducer } from "ka-table";
import { useCallback, useMemo, useState } from "react";
import { Text } from '@chakra-ui/react'
import { Column } from "ka-table/Models/Column";
import useGetBoost, { EBoostModules, getBoostAsync, VDPBoostResult } from "api/useGetBoost";
import { useAppSelector } from "hooks/store";
import { selectUserData } from "store/auth/authSlice";
import { ActionButtonV2 } from "components/Table/components/ActionButtons";
import { onGeneratePDFDefaultType } from "utils/helper";
import { TActionProps, TGeneratePdfFunction, TGenerateXLSFunction } from "components/Table/components/ActionButtons/types";
import { toast } from "react-toastify";
import { getPdfColumns, getXLSColumns } from "./constant";
import { format } from "date-fns";
import { CellDef } from "jspdf-autotable";
import { QuestionOutlineIcon } from "@chakra-ui/icons";
const VDPBoostReporting = () => {

  const userAuth = useAppSelector(selectUserData);
  
  const [search, setSearch] = useState<string>('');

  const fetchParameter = useMemo(() => ({
    module: EBoostModules.VDPBoost,
    params: {
      dealership_uid: userAuth.dealerV2.uid,
      inventory: userAuth.inventory.value,
      search: search
    }
  }), [userAuth.dealerV2.uid, userAuth.inventory.value, search]);

  const { data: fetchData, isFetching } = useGetBoost<EBoostModules.VDPBoost>(fetchParameter);

   // [columnKey, direction
  const [tableProps, changeTableProps] = useState<ITableProps<any>>({
    columns: [
      { 
        key: 'vehicle', 
        title: 'Vehicle', 
        dataType: DataType.String,
        width: 100,
      },
      { 
        key: 'current_vdp', 
        title: 'Current VDP', 
        dataType: DataType.Number,
        width: 100
      },
      {
        key: 'suggested_vdp',
        title: 'VDP Variance',
        dataType: DataType.Number,
        width: 100
      },
      { 
        key: 'target_vdp', 
        title: 'Target VDP', 
        dataType: DataType.Number,
        width: 100,
      },
    ],
    rowKeyField: 'id',
    data: fetchData?.data || [],
    treeGroupKeyField: 'treeGroupId',
    treeGroupsExpanded: [],
    sortingMode: SortingMode.Single,
    loading: {
      enabled: true,
    },
    // sort: (props) => setSort(props.column),
    // columnResizing: true,
    // paging: {
    //   enabled: false,
    //   pageIndex: 0,
    //   pageSize: 10,
    //   pagesCount: 15,
    //   pageSizes: [5, 10, 15],
    //   position: PagingPosition.Bottom
    // },
    
  });

  useMemo(() => {
    if (!isFetching) {
      changeTableProps((prevState) => {
        return {
          ...prevState,
          loading: {
            ...prevState.loading,
            enabled: false
          },
          data: fetchData?.data || []
        }
      })
    } else {
      changeTableProps((prevState) => {
        return {
          ...prevState,
          loading: {
            ...prevState.loading,
            enabled: true
          }
        }
      });
    }
  }, [fetchData?.data, isFetching]);

  useMemo(() => {
  
    changeTableProps((prevState) => {

      // const stockNumberColumn: Column<VDPBoostResult> = {
      //   key: 'stock_number',
      //   title: 'Stock Number',
      //   dataType: DataType.String,
      //   width: 100
      // };

      // const columnExists = prevState.columns.some(column => column.key === stockNumberColumn.key);


      // if (tableProps.treeGroupsExpanded && tableProps.treeGroupsExpanded.length > 0) {
  
      //   // Check if the column already exists based on the 'key' property
  
      //   if (!columnExists) {
      //     // If the column doesn't exist, create a copy of the current columns and add the new column
      //     const stockNumberColumns: Column<VDPBoostResult>[] = [...prevState.columns];
          
      //     stockNumberColumns.splice(1, 0, stockNumberColumn); // Insert the new column at index 1
  
      //     return {
      //       ...prevState,
      //       columns: stockNumberColumns // Update the columns with the new list
      //     };
      //   }
      // } else {
      //     return {
      //       ...prevState,
      //       columns: prevState.columns.filter(column => column.key !== stockNumberColumn.key)
      //     };
      // }



      const newColumns: Column<VDPBoostResult>[] = [
        {
          key: 'stock_number',
          title: 'Stock Number',
          dataType: DataType.String,
          width: 100
        },
        {
          key: 'age',
          title: 'Age',
          width: 100,
          dataType: DataType.Number,
        }
      ];

      const columnKeys = newColumns.map(column => column.key);

      const columnsExist = columnKeys.every(key => prevState.columns.some(column => column.key === key));


      if (tableProps.treeGroupsExpanded && tableProps.treeGroupsExpanded.length > 0) {
        // Check if all the columns already exist based on the 'key' property
        if (!columnsExist) {
          // If any of the columns don't exist, create a copy of the current columns and add the new columns
          const updatedColumns: Column<VDPBoostResult>[] = [...prevState.columns];
          
          newColumns.forEach((newColumn, index) => {
            if (!prevState.columns.some(column => column.key === newColumn.key)) {
              updatedColumns.splice(index + 1, 0, newColumn); // Insert the new columns at the appropriate index
            }
          });
      
          return {
            ...prevState,
            columns: updatedColumns // Update the columns with the new list
          };
        }
      } else {
        return {
          ...prevState,
          columns: prevState.columns.filter(column => !columnKeys.includes(column.key))
        };
      }

      // If the column exists, return prevState without any changes
      return prevState;
      
    });
  
  }, [tableProps.treeGroupsExpanded]);
  
  const onDispatch = useCallback((action: any) => {
    // console.log(action);
    switch (action.type) {
      default:
        changeTableProps((prevState: ITableProps) => kaReducer(prevState, action));
    }
  }, []);

  const onGeneratePDF: TActionProps<TGeneratePdfFunction> = useCallback(
    async (onGeneratePDF, setIsLoading) => {
      setIsLoading(true);
      try {
        const tempData = await getBoostAsync<EBoostModules.VDPBoost>(
          fetchParameter
        )();

        const tempPdfColumn = getPdfColumns(userAuth.inventory.value);

        const bodyFormatted = tempData.data.map((values, index) => {
          return tempPdfColumn.map(
            ({ func, key }) => {
              return func?.(values[key]) || values[key];
            }
          );
        });

        const customRows: CellDef[][] = [
          [
            {
              title: `Total`,
              styles: { textColor: [0, 0, 0], fontStyle: 'bold', halign: 'center', }
            },
            {
              title: ``,
              styles: { textColor: [0, 0, 0], fontStyle: 'bold', halign: 'center', }
            },
            {
              title: ``,
              styles: { textColor: [0, 0, 0], fontStyle: 'bold', halign: 'center', }
            },
            {
              title: `${tempData?.total.current_vdp || 0}`,
              styles: { textColor: [0, 0, 0], fontStyle: 'bold', halign: 'center', }
            },
            {
              title: `${tempData?.total.suggested_vdp || 0}`,
              styles: { textColor: [0, 0, 0], fontStyle: 'bold', halign: 'center', }
            },
            {
              title: `${tempData?.total.target_vdp || 0}`,
              styles: { textColor: [0, 0, 0], fontStyle: 'bold', halign: 'center', }
            },
          ],
        ];

        onGeneratePDF(
          {
            head: [tempPdfColumn.map(({ label }) => label)],
            body: [
              ...bodyFormatted,
              ...customRows
            ],
            ...onGeneratePDFDefaultType(`VDP Boost`, format(new Date(), "MMM d, yyyy"))
          },
          "vdp-boost.pdf",
          {
            compress: true
          }
        );
        setIsLoading(false);
      } catch (err) {
        toast.error("Can't export PDF file. Please contact your admin");
      }
    },
    [fetchParameter, userAuth.inventory.value]
  );

  const onGenerateXLSX: TActionProps<TGenerateXLSFunction> = useCallback(
    async (generateXLS, setIsLoading) => {
      setIsLoading(true);
      try {
        const tempData = await getBoostAsync<EBoostModules.VDPBoost>(
          fetchParameter
        )();

        const tempXlsColumn = getXLSColumns(userAuth.inventory.value);

        const bodyFormatted = tempData.data.map((values, index) => {
          return tempXlsColumn.map(
            ({ func, key }) => {
              return func?.(values[key], values) || values[key];
            }
          );
        });


        generateXLS(
          {
            head: [tempXlsColumn.map(({ label }) => label)],
            body: bodyFormatted,
          },
          "vdp-boost.xls",
        );
        setIsLoading(false);
      } catch (err) {
        toast.error("Can't export XLS file. Please contact your admin");
      }
    },
    [fetchParameter, userAuth.inventory.value]
  );

  const conttroller = () => {
    return (
      <Box
          display="flex"
          gap="15px"
          justifyContent="space-between"
          flex="1"
          alignItems="center"
      >
          <Box
              display="flex"
              alignItems="center"
              gap="15px"
              flex="1"
          >
          </Box>
          <Box
            display="flex"
            alignItems="center"
            gap="15px"
          >
            <Tooltip 
              hasArrow={true}
              label={
                <Box>
                  <Text fontSize="sm" marginBottom="10px">
                    VinDrives VDP Boost considers data from your brand, market, pricing and inventory to custom tailor your stores VDP requirements.
                  </Text>
                  <Text fontSize="sm">
                    We take that data and layer it over New and PreOwned – AND – VDP Boost will eliminate ad waste while speeding up inventory turn!
                  </Text>
                </Box>
              } 
              fontSize='md'
            >
              <QuestionOutlineIcon color="orange" />
            </Tooltip>
              <ActionButtonV2 
                  actionConfig={{
                      isDownloadPDF: true,
                      isDownloadXLS: true,
                      isEmailPDF: true,
                      isEmailXLS: true,
                      isPrintPDF: true
                  }}
                  onDownloadPDF={onGeneratePDF}
                  onDownloadXLS={onGenerateXLSX}
                  onGeneratePDF={onGeneratePDF}
              />
          </Box>
      </Box>
    );
  };

  return (
    <Box 
      display="flex"
      flexFlow="column"
      gap="10px"
    >
      {/* <Button
        onClick={updateData}
      >Test</Button> */}
      <TableV2 
        {...tableProps}
        conttroller={conttroller()}
        // treeGroupsExpanded={[0]}
        childComponents={{
            tableWrapper: {
              elementAttributes: () => ({ style: { maxHeight: 600 }})
            },
            summaryCell: {
              content: ({ column, data }) => {
                switch (column.key) {
                  case 'current_vdp':
                    return (
                      <Text 
                        // fontWeight={600}
                        fontSize={15}
                      >
                        Total: {fetchData?.total.current_vdp || 0}
                      </Text>
                    );
                  case 'target_vdp':
                    return (
                      <Text 
                        // fontWeight={600}
                        fontSize={15}
                      >
                        Total: {fetchData?.total.target_vdp || 0}
                      </Text>
                    );
                  case 'suggested_vdp':
                    return (
                      <Text 
                        // fontWeight={600}
                        fontSize={15}
                      >
                        Total: {fetchData?.total.suggested_vdp || 0}
                      </Text>
                    );
                }
              },
            },
            dataRow: {
              elementAttributes: ({ rowData }) => ({
                style: {
                  background: rowData.treeGroupId ? '#E2E8F0' : ''
                }
              }),
            },
            noDataRow: {
                content: () => 'No Data Found',
            },
            cell: {
              content(props) {

                  // console.log('props', props);

                  if (!props.rowData.treeGroupId) {
                    switch (props.column.key) {
                      case 'target_vdp':
                      case 'current_vdp':
                      case 'suggested_vdp':
                        return (
                          <Text 
                            //fontWeight={600}
                            fontSize={15}
                          >
                            {props.value}
                          </Text>
                        )
                    }
                  }

              },
            }
        }}
        dispatch={onDispatch}
        onSearch={setSearch}
      />
    </Box>
  );
}

export default VDPBoostReporting;