import { yupResolver } from "@hookform/resolvers/yup";
import { FC, useCallback } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import TextInput from "components/TextInput";
import useAuthApi from "hooks/useAuthApi";
import { IChangePasswordForm } from "types/ForgotPassword";
import ERoutes from "types/Routes";
import FormContainer from "./FormContainer";

interface IProps {
  userEmail: string;
}

const changePasswordSchema = yup.object().shape({
  newPassword: yup
    .string()
    .required("New Password is required")
    .min(6, "New password must be at least 6 characters"),
  oneTimePassword: yup.string().required("One Time Password is required"),
});

const ChangePassword: FC<IProps> = ({ userEmail }) => {
  const { changePassword } = useAuthApi();
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    setError,
  } = useForm<IChangePasswordForm>({
    resolver: yupResolver(changePasswordSchema),
  });
  const navigate = useNavigate();

  const onSubmit = useCallback(
    async (data: IChangePasswordForm) => {
      try {
        
        const result = await changePassword({
          email: userEmail,
          oneTimePassword: data.oneTimePassword,
          password: data.newPassword,
        });

        const { status, json } = await result;
        
        if (status === 200) {
          navigate(ERoutes.Login, { replace: true });
        } else {

          const jsonBody = await json();

          setError("root", { message: jsonBody.message});

        }
      } catch (e) {
        setError("root", { message: "Something went wrong" });
      }
    },
    [changePassword, navigate, setError, userEmail]
  );

  return (
    <FormContainer
      helperText={{
        message:
          errors.root?.message ||
          `OTP has been sent to your email "${userEmail}"`,
        isError: !!errors.root?.message,
      }}
      isSubmitting={isSubmitting}
      onSubmit={handleSubmit(onSubmit)}
    >
      <TextInput
        disabled={isSubmitting}
        label="New Password"
        type="password"
        name="newPassword"
        register={register}
        errorMessage={errors.newPassword?.message as string}
      />
      <TextInput
        disabled={isSubmitting}
        label="One Time Password"
        type="password"
        name="oneTimePassword"
        register={register}
        errorMessage={errors.oneTimePassword?.message as string}
      />
    </FormContainer>
  );
};

export default ChangePassword;
