import { FC } from "react";
import Chart from "react-apexcharts";
import cs from "classnames";
import DateRange, { IDateRangeProps } from "components/DateRange";
import commonDateRanges, { ICommonDateRange } from "constants/commonDateRanges";
import styles from "./styles.module.scss";

interface IData {
  color: string;
  data:
    | (number | null)[]
    | {
        x: any;
        y: any;
        // fill?: ApexFill;
        fillColor?: string;
        strokeColor?: string;
        meta?: any;
        goals?: any;
        barHeightOffset?: number;
        columnWidthOffset?: number;
      }[]
    | [number, number | null][]
    | [number, (number | null)[]][]
    | number[][];
  id: string;
  name: string;
}

interface ILineChartProps {
  className?: string;
  dateRange: IDateRangeProps["value"];
  data: IData[];
  onChangeDateRange: IDateRangeProps["onChange"];
  options?: ICommonDateRange[];
}

const LineChart: FC<ILineChartProps> = ({
  className,
  data,
  dateRange,
  onChangeDateRange,
  options,
}) => (
  <div className={cs(styles.lineChart, className)}>
    <div className={styles["lineChart__dateRange"]}>
      <DateRange
        onChange={onChangeDateRange}
        quickOptions={options || commonDateRanges}
        value={{ endDate: dateRange.endDate, startDate: dateRange.startDate }}
      />
    </div>
    <div className={styles["lineChart__chart"]}>
      <Chart
        type="line"
        height="100%"
        width="100%"
        options={{
          chart: {
            events: {
              mounted: (chart) => {
                chart.windowResizeHandler();
              },
            },
            toolbar: { show: false },
            zoom: {
              enabled: false,
            },
          },
          tooltip: {
            y: {
              formatter: (value: number) => `${value}`,
            },
          },
          stroke: { curve: "smooth" },
        }}
        series={data}
      />
    </div>
  </div>
);
export default LineChart;
