export const getResultScoreColorName = (
  score: number
): "red" | "yellow" | "green" => {
  if (score <= 3) {
    return "red";
  } else if (score <= 9) {
    return "yellow";
  }
  return "green";
};


export const getResultScoreColorNameV2 = (score: number) => {  
  if (score <= 0.40) {
    return "red";
  } else if (score <= 0.64) {
    return "yellow";
  } else {
    return "green";
  }
}

export const getResultScoreColorNameNew = (score: number) => {
  if (score <= 0.20) {
    return "red";
  } else if (score <= 0.40) {
    return "yellow";
  } else {
    return "green";
  }
}