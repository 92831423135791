import { Column, Table } from "@tanstack/react-table";
import React from "react";
import Popover from "components/Popover";
// import Button from "components/Button";
import { ReactComponent as SettingsIcon } from "assets/icons/settings.svg";
// import { Range } from 'react-range';
// import CustomizeFilters from "../CustomizeFilters";
import RangePicker from "components/RangePicker";
import styles from "../../styles.module.scss";

// A debounced input react component
function StandardInput({
  value: initialValue,
  onChange,
  debounce = 500, 
  ...props
}: {
  value: string | number;
  onChange: (value: string | number) => void;
  debounce?: number;
} & Omit<React.InputHTMLAttributes<HTMLInputElement>, "onChange">) {
  return (
    <input
      {...props}
      value={initialValue}
      onChange={(e) => onChange(e.target.value)}
    />
  );
}

type TColumnFilterProps = {
  column: Column<any, unknown>;
  table: Table<any>;
  inputType: "input" | "range";
};

const ColumnFilter: React.FC<TColumnFilterProps> = ({
  column,
  table,
  inputType,
}) => {
  const columnTitle = (column.columnDef as any)?.title ?? column.id;

  const firstValue = table
    .getPreFilteredRowModel()
    .flatRows[0]?.getValue(column.id);

  const columnFilterValue = column.getFilterValue();

  const sortedUniqueValues = React.useMemo(
    () =>
      typeof firstValue === "number"
        ? []
        : Array.from(column.getFacetedUniqueValues().keys()).sort(),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [column.getFacetedUniqueValues()]
  );

  const tempValue = (columnFilterValue as [number, number]) ?? [0, 500];

  const maxLimit = 2000;
  const [min] = React.useState<number>(0);
  const [max, setMax] = React.useState<number>(500);

  // const onChangeFilter = (newValue: [number, number]) => {
  //   column.setFilterValue((old: [number, number]) => newValue);
  // };

  // console.log('range', column.getFacetedMinMaxValues());

  // const min = column.getFacetedMinMaxValues()?.[0] ? column.getFacetedMinMaxValues()?.[0] ?? 0 : 0;
  // const max = column.getFacetedMinMaxValues()?.[1] ? column.getFacetedMinMaxValues()?.[1] ?? 0 : 500;

  // const min = (columnFilterValue as [number, number])?.[0] ?? min;
  // const valueMax = (columnFilterValue as [number, number])?.[1] ?? max;

  if (
    typeof firstValue === "number" ||
    (column.columnDef as any).type === "number"
  ) {
    if (inputType === "range") {
      return (
        <Popover
          trigger={
            // <Button size="sm" variant="text">
              <SettingsIcon  style={{ userSelect: 'none' }}/>
            // </Button>
          }
        >
          <RangePicker
            values={tempValue}
            step={1}
            min={min}
            max={max}
            onChange={(values) => {

                // dont allow values to go beyond maxLimit
                values[1] <= maxLimit && column.setFilterValue((old: [number, number]) => {
                  
                  // make input always positive
                  const tempMin = values[0] < 0 ? 0 : values[0];
                  const tempMax = values[1] < 0 ? 0 : values[1];
      
                  // if tempMax is greater than max, set max to tempMax
                  if (tempMax > max) {
                    setMax(tempMax);
                  }

                  if (tempMax === 0) {
                    return [tempMin, max];
                  } else if (tempMin > tempMax) {
                    return [tempMax, tempMax];
                  } else {
                    return [tempMin, tempMax];
                  }

                });
            }}
            label={columnTitle}
          />
        </Popover>
      );
    } else {
      return (
        <div className={styles.columnFilter}>
          <StandardInput
            type="number"
            min={Number(column.getFacetedMinMaxValues()?.[0] ?? "")}
            max={Number(column.getFacetedMinMaxValues()?.[1] ?? "")}
            value={(columnFilterValue as [number, number])?.[0] ?? ""}
            onChange={(value) => {
              const maxValue = (columnFilterValue as [number, number])?.[1];

              // Check if the new minimum value is not higher than the current maximum value
              if (
                maxValue === undefined ||
                maxValue === null ||
                maxValue.toString().length === 0 ||
                Number(value) <= maxValue
              ) {
                column.setFilterValue((old: [number, number]) => [
                  value,
                  maxValue,
                ]);
              }
            }}
            placeholder="Min"
            className={styles.columnfilter_input}
          />
          <StandardInput
            type="number"
            min={Number(column.getFacetedMinMaxValues()?.[0] ?? "")}
            max={Number(column.getFacetedMinMaxValues()?.[1] ?? "")}
            value={(columnFilterValue as [number, number])?.[1] ?? ""}
            onChange={(value) => {
              const minValue = (columnFilterValue as [number, number])?.[0];

              // Check if the new maximum value is not lower than the current minimum value
              if (
                minValue === undefined ||
                Number(value) >= minValue ||
                minValue.toString().length > value.toString().length
              ) {
                column.setFilterValue((old: [number, number]) => [
                  minValue,
                  value,
                ]);
              }
            }}
            placeholder="Max"
            className={styles.columnfilter_input}
          />
        </div>
      );
    }
  } else {
    return (
      <>
        <datalist id={column.id + "list"}>
          {sortedUniqueValues.slice(0, 5000).map((value: any) => (
            <option value={value} key={value} />
          ))}
        </datalist>
        <StandardInput
          type="text"
          value={(columnFilterValue ?? "") as string}
          onChange={(value) => column.setFilterValue(value)}
          placeholder={`Search... (${column.getFacetedUniqueValues().size})`}
          className="w-36 border shadow rounded"
          list={column.id + "list"}
        />
        <div className="h-1" />
      </>
    );
  }
};

export default ColumnFilter;
