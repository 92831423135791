import { Column } from "@tanstack/react-table";

// todo: Move the types into this file

export enum ESortOrder {
  Ascending = "asc",
  Descending = "desc",
  None = "",
}

export interface ISortState {
  column: string | null;
  order: ESortOrder;
}

/**
 * @usage make sure the "id" of each "column" is the expected key/string that
 * is expected from the API/BE parameters for the "ordering" payload
 */
export type TSort =
  | {
      mode: "client";
      onSort?: (newOrder: ISortState) => void;
    }
  | ({
      mode: "server";
      onSort: (newOrder: ISortState) => void;
    } & ISortState);

export interface ICustomPropsColumn<D extends object> extends Column<D> {
  // Sample usage in src/pages/Merchandising/components/Tables/components/ChannelStats/constants.tsx
  // Add your custom column properties here
  classNameHeader?: string;
}
