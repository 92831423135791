import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import { utils, writeFile, Range } from "xlsx";
import {
  IHeadObjectXLS,
  TGeneratePdfFunction,
  TGenerateXLSFunction,
} from "./types";

const defaultFilename = "vindrives-file.pdf";

export const generateDownloadPdf: TGeneratePdfFunction = (
  tableConfig,
  filename,
  jsPDFConfig
) => {
  const doc = new jsPDF(jsPDFConfig);
  autoTable(doc, tableConfig);
  doc.save(filename || defaultFilename);
};

export const generatePrintPdf: TGeneratePdfFunction = (
  tableConfig,
  filename,
  jsPDFConfig
) => {
  const doc = new jsPDF(jsPDFConfig);

  doc.setProperties({
    title: filename || defaultFilename,
  });
  
  doc.autoPrint();
  autoTable(doc, tableConfig);

  // Print Get the PDF data as a data URI
  const pdfDataUri = doc.output("datauristring", {
    filename: filename || defaultFilename,
  });

  // Open the PDF file in a new window or iframe for printing
  const printWindow = window.open("", "_blank");
  printWindow?.document.write(
    `
        <style>
          body {
            margin: 0;
          }
        </style>
        <iframe src="${pdfDataUri}" style="width:100vw; height: 100vh;" frameborder="0" allowfullscreen></iframe>
      `
  );

  // Wait for the iframe content to load before printing
  printWindow?.document.addEventListener("load", () => {
    printWindow.print();
  });
};

export const generateEmailPdf: TGeneratePdfFunction = async (
  tableConfig,
  filename = defaultFilename
) => {
  const doc = new jsPDF();
  autoTable(doc, tableConfig);

  const title = `PDF Attachment ${filename}`;
  const body = "Please find the attached PDF file.";

  if ("share" in navigator) {
    const blobPDF = new Blob([doc.output()], { type: "application/pdf" });
    const shareData = {
      files: [
        new File([blobPDF], filename, {
          type: blobPDF.type,
        }),
      ],
      title,
      body,
    };

    if (navigator.canShare(shareData)) {
      await navigator.share(shareData);
    }
  } else {
    doc.save(filename || defaultFilename);

    // Create a mailto link with the subject, body, and PDF attachment
    var mailtoLink =
      "mailto:?subject=" +
      encodeURIComponent(title) +
      "&body=" +
      encodeURIComponent(body);

    // Open the default email application with the mailto link
    // hack for popup blocker
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const windowOpen = window.open(mailtoLink);
  }
};

export const generateDownloadXLS: TGenerateXLSFunction = (
  content,
  fileName
) => {
  const { body, head } = content;

  // Fills up to the right empty strings based of the colspan value
  const formattedHeaders = head.map((headRow) =>
    headRow.reduce(
      (newHeadContent, currentHeadContent): (string | IHeadObjectXLS)[] => {
        let newFormat = [currentHeadContent];

        if (
          typeof currentHeadContent !== "string" &&
          typeof currentHeadContent?.colspan === "number"
        ) {
          const blankSpaces = Array(currentHeadContent.colspan - 1).fill("_");
          return [...newHeadContent, currentHeadContent, ...blankSpaces];
        }

        return [...newHeadContent, ...newFormat];
      },
      [] as (string | IHeadObjectXLS)[]
    )
  );

  const mergeGroupHeaders: Range[] = [];

  // Sets up the merged cells based on the colspan value from "content.head"
  for (let indexRow = 0; indexRow < formattedHeaders.length; indexRow++) {
    const headRow = formattedHeaders[indexRow];

    for (let indexColumn = 0; indexColumn < headRow.length; indexColumn++) {
      const headColumn = headRow[indexColumn];

      if (
        typeof headColumn === "object" &&
        headColumn.hasOwnProperty("colspan") &&
        typeof headColumn.colspan === "number"
      ) {
        formattedHeaders[indexRow][indexColumn] = headColumn.label;
        mergeGroupHeaders.push({
          s: { r: indexRow, c: indexColumn },
          e: { r: indexRow, c: indexColumn + headColumn.colspan - 1 },
        });
      }
    }
  }

  const worksheet = utils.aoa_to_sheet([...formattedHeaders, ...body]);

  // Merges the cell headers
  worksheet["!merges"] = mergeGroupHeaders;

  /* create workbook and append worksheet */
  const workbook = utils.book_new();
  utils.book_append_sheet(workbook, worksheet, "Sheet 1");

  /* export to XLSX */
  writeFile(workbook, fileName);
};

export const generateEmailXLS: TGenerateXLSFunction = (content, fileName) => {
  generateDownloadXLS(content, fileName);

  // Create a mailto link with the subject, body, and PDF attachment
  var mailtoLink =
    "mailto:?subject=" +
    encodeURIComponent(`PDF Attachment ${fileName}`) +
    "&body=" +
    encodeURIComponent("Please find the attached PDF file.");

  // Open the default email application with the mailto link
  // hack for popup blocker
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const windowOpen = window.open(mailtoLink);
};
