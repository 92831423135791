import {
  ChangeEventHandler,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  getCoreRowModel,
  getFilteredRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFacetedMinMaxValues,
  // getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
  flexRender,
  ColumnFiltersState,
  ColumnFilter,
} from "@tanstack/react-table";
import cs from "classnames";
import { ReactComponent as SearchIcon } from "assets/icons/search.svg";
import DateRange, { IDateRangeProps } from "components/DateRange";
import Select, { ISelectProps } from "components/SelectInput";
import TextInput from "components/TextInput";
import Typography from "components/Typography";
import commonDateRanges from "constants/commonDateRanges";
import styles from "./styles.module.scss";
import ActionButtons, { ActionButtonV2, ActionButtonV2Props } from "./components/ActionButtons";
import { IActionButtonsProps } from "./components/ActionButtons/types";
import CustomizeFilters from "./components/CustomizeFilters";
import {
  ICheckListFilterProps,
  IRangeFilterProps,
} from "./components/CustomizeFilters/types";
import Pagination, { IPaginationProps } from "./components/Pagination";
import TableHead from "./components/TableHead";
import { TSort } from "./types";
import LoadingSpinner from "components/LoadingSpinner";
import elementID from "constants/elementID";
import { IProviderCheckList } from "types/IProviderCheckList";

// todo: Move the types into src/components/Table/types.ts
export interface PaginationState {
  pageIndex: number;
  pageSize: number;
}

interface ITableContentStyles {
  maxHeight?: number;
  height?: number;
}

export interface IDropdownFilterKeyPair {
  label: string;
  value: any;
}

interface IDropdownFilter {
  onChange: ISelectProps["onChange"];
  options: IDropdownFilterKeyPair[];
  value: IDropdownFilterKeyPair["value"];
}

interface IQuickSearchFilter {
  value: string;
  onChange?: (text: string) => void;
  onChangeDebounce?: (text: string) => void;
}

export interface TableProps<T> extends Pick<ActionButtonV2Props, 'actionConfig' | 'providers'> {
  checkListFilter?: ICheckListFilterProps;
  className?: string;
  columns: any[];
  data: T[];
  dateRange?: {
    onChange: IDateRangeProps["onChange"];
    quickOptions?: IDateRangeProps["quickOptions"];
    value: IDateRangeProps["value"];
  };
  dropdownFilters?: IDropdownFilter;
  dropnDownPosition?: "left" | "right";
  isCustomizedDisabled?: boolean;
  isLoading?: boolean;
  filterMode?: "client" | "server";
  globalFilter?: string;
  limit?: number;

  onGeneratePDF?: IActionButtonsProps["onGeneratePDF"];
  onGenerateXLS?: IActionButtonsProps["onGenerateXLS"]; // onGenerateXLS is deprecated, use onDownloadXLS instead
  onDownloadPDF?: IActionButtonsProps["onDownloadPDF"];
  onDownloadXLS?: IActionButtonsProps["onDownloadXLS"];
  onOffsetChange?: (newOffset: number) => void;

  onPageSizeChange?: (newPageSize: number) => void;
  onPageIndexChange?: (newPageIndex: number) => void;
  pageSizeOptions?: IPaginationProps<T>["pageSizeOptions"];
  quickSearchFilter?: IQuickSearchFilter;
  rangeFilter?: IRangeFilterProps;
  showPagination?: boolean;
  sort?: TSort;
  tableContentStyles?: ITableContentStyles;
  total?: number;
  enableColumnFilters?: boolean;
  onColumnFilterChange?: (columnFilter: ColumnFilter[] | null) => void;
  manualFiltering?: boolean;
  noDataMessage?: string;
  customButtons?: React.ReactNode;
  // what should i name for column total below the table?
  columnTotal?: {
    data: { title: string, value: string[] | string | number}[]
  };
  actionVersion?: "v1" | "v2";
}

/**
 * If filterMode is "server", the table is based on "limit" & "offset" B.E.'s pagination style. Therefore,
 * "onOffsetChange", "limit", and "total" are required
 *  */
const Table = <T extends object>({
  checkListFilter,
  className,
  columns,
  data,
  dateRange,
  dropdownFilters,
  dropnDownPosition = "left",
  isCustomizedDisabled = false,
  filterMode = "client",
  isLoading = false,
  limit = 10,
  onGeneratePDF,
  onGenerateXLS,
  onDownloadPDF,
  onDownloadXLS,
  onOffsetChange,
  onPageIndexChange,
  // pageSize,
  onPageSizeChange,
  pageSizeOptions,
  quickSearchFilter,
  rangeFilter,
  showPagination = true,
  sort,
  tableContentStyles = {},
  enableColumnFilters = false,
  onColumnFilterChange = undefined,
  manualFiltering,
  noDataMessage = 'No Data',
  /** Is the total number of data from the query depends on the limit's value */
  total = 0,
  customButtons,
  columnTotal,
  actionVersion,
  providers,
  actionConfig
}: TableProps<T>) => {
  
  const pageCount = useMemo(
    () => (filterMode === "server" ? Math.ceil(total / limit) : data.length),
    [data.length, filterMode, limit, total]
  );

  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: limit,
  });

  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>(
    []
  );

  useMemo(() => {
    
    if (onColumnFilterChange && manualFiltering) {
        onColumnFilterChange(columnFilters.length > 0 ? columnFilters : null);
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [columnFilters, manualFiltering]);

  const table = useReactTable<T>({
    data,
    columns,
    enableSorting: sort !== undefined,
    enableColumnFilters: enableColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
    manualFiltering: manualFiltering,
    manualSorting: sort?.mode === "server",
    onColumnFiltersChange: setColumnFilters,
    ...(filterMode === "server"
      ? {
          manualPagination: true,
          pageCount,
          enableGlobalFilter: !!quickSearchFilter?.value,
          state: {
            globalFilter: quickSearchFilter?.value,
            pagination,
            columnFilters: columnFilters,
          },
          onPaginationChange: (...params) => setPagination(...params),
        }
      : {
          enableGlobalFilter: !!quickSearchFilter?.value,
          state: {
            globalFilter: quickSearchFilter?.value,
            columnFilters: columnFilters,
          },
          // getPaginationRowModel: getPaginationRowModel(), // removed this causing the normal render without pagination limit the data to 10
          globalFilterFn: (row, columnId, filterValue) => {
            const safeValue: string = (() => {
              const value = row.getValue(columnId);
              return String(value);
            })();
            return safeValue?.toLowerCase().includes(filterValue.toLowerCase());
          },
        }),
  });

  useEffect(() => {
    if (onOffsetChange) {
      const newOffset = pagination.pageIndex * pagination.pageSize;
      onOffsetChange(newOffset);
    }

    if (onPageIndexChange) {
      onPageIndexChange(pagination.pageIndex);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagination.pageIndex, pagination.pageSize]);

  useEffect(() => {
    if (onPageSizeChange) {
      onPageSizeChange(pagination.pageSize);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagination.pageSize]);

  const onChangeQuickFilter: ChangeEventHandler<HTMLInputElement> = useCallback(
    ({ target }) => {
      quickSearchFilter?.onChange?.(target.value);
      setPagination((prev) => ({ ...prev, pageIndex: 0 }));
    },
    [quickSearchFilter]
  );

  const rowModel = table.getRowModel();

  // Sticky Header functions
  const tableRef = useRef<HTMLTableElement>(null);
  const tableHeaderRef = useRef<HTMLTableSectionElement>(null);

  const handleScroll = () => {
    const navbarHeaderHeight =
      document.getElementById(elementID.headerNavbar)?.clientHeight || 0;

    if (tableRef.current && tableHeaderRef.current) {
      const tableOffset =
        tableRef.current.getBoundingClientRect().top - navbarHeaderHeight;

      if (tableOffset < 0) {
        tableHeaderRef.current.style.setProperty(
          "transform",
          `translateY(${tableOffset * -1}px)`
        );
      } else {
        tableHeaderRef.current.style.removeProperty("transform");
      }
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className={cs(styles.tableContainer, className)}>
      {dateRange ? (
        <div className={styles.tableDateRange}>
          <DateRange
            onChange={dateRange.onChange}
            quickOptions={dateRange.quickOptions || commonDateRanges}
            value={dateRange.value}
          />
        </div>
      ) : null}
      <div className={styles.midSection}>
        {(quickSearchFilter || dropdownFilters) && (
          <div className={styles["midSection__left"]}>
            {dropdownFilters && dropnDownPosition === 'left' && (
              <Select
                className={styles.dropdownFilter}
                classNames={{
                  control: () => styles.dropdownFilter__control,
                  dropdownIndicator: () => styles.dropdownFilter__indicator,
                  menu: () => styles.dropdownFilter__menu,
                  singleValue: () => styles.dropdownFilter__value,
                }}
                components={{ IndicatorSeparator: null }}
                onChange={dropdownFilters.onChange}
                options={dropdownFilters.options}
                value={dropdownFilters.value}
              />
            )}
            {quickSearchFilter && (
              <TextInput
                className={styles.quickSearchFilter}
                name="filter"
                placeholder="Quick Search"
                icon={
                  <SearchIcon className={styles["quickSearchFilter__icon"]} />
                }
                value={quickSearchFilter.value}
                onChange={onChangeQuickFilter}
              />
            )}
            {(checkListFilter || rangeFilter) && (
              <CustomizeFilters
                checkList={checkListFilter}
                range={rangeFilter}
                disabled={isCustomizedDisabled}
              />
            )}
            {dropdownFilters && dropnDownPosition === 'right' && (
              <Select
                className={styles.dropdownFilter}
                classNames={{
                  control: () => styles.dropdownFilter__control,
                  dropdownIndicator: () => styles.dropdownFilter__indicator,
                  menu: () => styles.dropdownFilter__menu,
                  singleValue: () => styles.dropdownFilter__value,
                }}
                components={{ IndicatorSeparator: null }}
                onChange={dropdownFilters.onChange}
                options={dropdownFilters.options}
                value={dropdownFilters.value}
              />
            )}
          </div>
        )}
        {customButtons ?? null}
        {(actionVersion === 'v1' || !actionVersion) && (onGeneratePDF || onGenerateXLS) ? (
          <ActionButtons
            onGeneratePDF={onGeneratePDF}
            onGenerateXLS={onGenerateXLS}
          />
        ) : null}
        {actionVersion === 'v2' ? (
          <ActionButtonV2   
            onGeneratePDF={onGeneratePDF}
            onDownloadPDF={onDownloadPDF}
            onDownloadXLS={onDownloadXLS}
            providers={providers}
            actionConfig={actionConfig}
          />
        ) : null}
      </div>
      {/* <LoadingSpinner isLoading={isLoading}> */}
        <div
          className={styles.tableContent}
          {...(tableContentStyles.height || tableContentStyles.maxHeight
            ? {
                style: { ...tableContentStyles }, 
              }
            : {})}
        >
          <table ref={tableRef} className={styles.table}>
            <TableHead<T> ref={tableHeaderRef} table={table} sort={sort} />
            {isLoading ? (
              <tbody className={styles.body}>
                <tr className={styles["body__row"]}>
                    <td
                      className={cs(
                        styles["body__row__item"],
                        styles["body__row__item--noData"]
                      )}
                      colSpan={table.getAllFlatColumns().length}
                    >
                      <LoadingSpinner isLoading={true} />
                    </td>
                  </tr>
              </tbody>
            ) : (
              <tbody className={styles.body}>
                {rowModel.rows.length ? (
                  rowModel.rows.map((row) => (
                    <tr key={row.id} className={styles["body__row"]}>
                      {row.getVisibleCells().map((cell) => (
                        <td key={cell.id} className={styles["body__row__item"]}>
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </td>
                      ))}
                    </tr>
                  ))
                ) : (
                  <tr className={styles["body__row"]}>
                    <td
                      className={cs(
                        styles["body__row__item"],
                        styles["body__row__item--noData"]
                      )}
                      colSpan={table.getAllFlatColumns().length}
                    >
                      <Typography fontSize="text-md">
                        {noDataMessage}
                      </Typography>
                    </td>
                  </tr>
                )}
                {/* columnTotal type row or column and columnTotal data is string[] */}
                {columnTotal && (
                    <>
                      {columnTotal.data.map((totalColumn, index) => (
                        <tr 
                          key={index}
                          className={styles["body__row"]}
                          style={{ fontWeight: 'bold', borderTop: index === 0 ? '4px solid #d0d5dd' : 'none' }}
                        >
                          <td 
                            className={styles["body__row__item"]}
                            colSpan={table.getAllFlatColumns().length - (Array.isArray(totalColumn.value) ? totalColumn.value.length : 1)}
                          >
                            {totalColumn.title}
                          </td>
                            {Array.isArray(totalColumn.value) ? totalColumn.value.map((innerValue, index) => (
                              <td className={styles["body__row__item"]} key={index}>
                                {innerValue}
                              </td>
                            )) : (
                              <td className={styles["body__row__item"]}>
                                {totalColumn.value}
                              </td>
                            )}
                        </tr>
                      ))}
                    </>
                  )}
              </tbody>
            )}
          </table>
        </div>
      {/* </LoadingSpinner> */}
      {showPagination && (
        <Pagination<T> pageSizeOptions={pageSizeOptions} table={table} />
      )}
    </div>
  );
};
export default Table;
