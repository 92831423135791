import { setDealerships } from 'store/dealership/dealershipSlice';
import { api } from './api';

export type TDealershipData = {
    count:       number;
    next:        null;
    previous:    null;
    results:     TDealer[];
    total_pages: number;
}

export type TDealer = {
    uid:              string;
    name:             string;
    results_managers: TResultsManager[];
    third_party_provider: TThirdPartyProvider[];
    is_vinsolutions:  boolean;
    is_carfax:        boolean;
    used:             boolean;
    new:              boolean;
}

export type TThirdPartyProvider = {
    key:  string;
    label: string;
};

export type TResultsManager = {
    user__email:           string;
    user__first_name:      string;
    user__last_name:       string;
    user__avatar:          string;
    user__phone:           string;
    user__secondary_phone: string;
}

export const dealershipApi = api.injectEndpoints({
    endpoints: (build) => ({
        getDealership: build.query<TDealershipData, any>({
            keepUnusedDataFor: 0,
            query: () => {
                return `dealerships`
            },
            onQueryStarted: async (arg, api) => {
                                
                const { dispatch, queryFulfilled } = api;

                try {
                    
                    const { data } = await queryFulfilled;

                    dispatch(setDealerships({ dealerships: data.results || [] }));

                } catch (error) {
                    console.error('Error fetching dealership data:', error);
                }
            }
        }),
    }),
});

export const { useGetDealershipQuery } = dealershipApi;