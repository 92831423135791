import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "..";
import { ELocalSavedFilters, getDefaultFilters, IResultScore, setDefaultFilters } from "utils/defaultFilters";
import IDateRangeFormatted from "types/IDateRangeFormatted";
import EDaysOnLot from "types/APIDaysOnLot";
import { EResultScoreDateRange } from "types/EResultScoreDateRange";
import { allTime, lastMonth, lastWeek, today } from "constants/commonDateRanges";
import apiDateFormat from "constants/apiDateFormat";
import { format } from "date-fns";

export interface IResultScoresGraph {
    greenScores: number[];
    redScores: number[];
    yellowScores: number[];
}

interface INeedsAttention {
    greenScore: number;
    needsPricing: number;
    redScore: number;
    yellowScore: number;
}

// Define a type for the slice state
interface ResultScoreState {
    dateRangeFormatted: IDateRangeFormatted;
    filterBy: IResultScore["filterBy"];
    needsAttention: INeedsAttention;
    resultScoresCategories: EDaysOnLot[];
    resultScoresGraph: IResultScoresGraph;
    isLoading: boolean;
}

export const defaultResultScores: IResultScoresGraph = {
    greenScores: [],
    redScores: [],
    yellowScores: [],
};

export const defaultCategories = [
    EDaysOnLot.ZeroToFifteen,
    EDaysOnLot.SixteenToThirty,
    EDaysOnLot.ThirtyOneToFortyFive,
    EDaysOnLot.FortySixToSixty,
    EDaysOnLot.SixtyAbove,
];

const defaultFilters = getDefaultFilters<IResultScore>(
    ELocalSavedFilters.ResultScoreGraph,
    { filterBy: EResultScoreDateRange.Today }
);

if (defaultFilters.filterBy !== 'today') {
    setDefaultFilters<IResultScore>(ELocalSavedFilters.ResultScoreGraph, { filterBy: EResultScoreDateRange.Today })
}

const onFilterBy = (filterby: EResultScoreDateRange) => {
    if (filterby === EResultScoreDateRange.LastWeek) {
      return lastWeek;
    } else if (filterby === EResultScoreDateRange.LastMonth) {
      return lastMonth;
    } else if (filterby === EResultScoreDateRange.AllTime) {
      return allTime;
    } else {
      return today;
    }
}

// Define the initial state using that type
const initialState: ResultScoreState = {
    dateRangeFormatted: {
        startDate: format(onFilterBy(defaultFilters.filterBy).startDate, apiDateFormat),
        endDate: format(onFilterBy(defaultFilters.filterBy).endDate, apiDateFormat),
    },
    filterBy: defaultFilters.filterBy,
    needsAttention: {
        greenScore: 0,
        needsPricing: 0,
        redScore: 0,
        yellowScore: 0,
    },
    resultScoresCategories: defaultCategories,
    resultScoresGraph: defaultResultScores,
    isLoading: true
};

const resultScoreSlice = createSlice({
  name: "resultScoreSlice",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    
    setLoading: (state, action: PayloadAction<boolean>) => {
        state.isLoading = action.payload;
    },

    setFilterBy: (state, action: PayloadAction<Pick<ResultScoreState, 'filterBy'>>) => {
        setDefaultFilters<IResultScore>(ELocalSavedFilters.ResultScoreGraph, {
            filterBy: action.payload.filterBy,
        });
        state.filterBy = action.payload.filterBy;
    },
    setNeedsAttention: (state, action: PayloadAction<INeedsAttention>) => {
        state.needsAttention = action.payload;
    },
    setResultScoreGraph: (state, action: PayloadAction<IResultScoresGraph>) => {
        state.resultScoresGraph = action.payload;
    }
  },
});

export const { setFilterBy, setNeedsAttention, setResultScoreGraph, setLoading } = resultScoreSlice.actions;

export const selectResuleScoreData = (state: RootState) => state.resultScore;

export default resultScoreSlice.reducer;
