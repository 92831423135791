import { FC } from "react";
import cs from "classnames";
import Button from "components/Button";
import Select from "components/SelectInput";
import styles from "./styles.module.scss";

interface ITab {
  id: string | number;
  label: string;
}

interface ITabsProps {
  /**
   * index based
   */
  active?: number | null;
  className?: string;
  onClickTab: (tabIndex: number, id?: ITab["id"]) => void;
  tabs: ITab[];
}

interface SelectItem {
  value: number;
  label: string;
}

const Tabs: FC<ITabsProps> = ({
  active = null,
  className,
  onClickTab,
  tabs,
}) => {
  return (
    <>
      <div className={cs(styles.tabs, className, styles.tabDesktop)}>
        {tabs.map((tab, index) => (
          <Button
            key={tab.id}
            variant={active === index ? "primary" : "text"}
            onClick={() => onClickTab(index, tab.id)}
          >
            {tab.label}
          </Button>
        ))}
      </div>
      <div className={cs(className, styles.tabPhone)}>
        <Select
          classNames={{ menu: () => styles["tabPhone__list"] }}
          value={tabs[active ?? 0]}
          options={tabs.map((tabItem, index) => ({
            label: tabItem.label,
            value: index,
          }))}
          onChange={(item) =>
            onClickTab(
              (item as SelectItem).value as number,
              tabs[(item as SelectItem).value].id
            )
          }
        />
      </div>
    </>
  );
};

export default Tabs;
