import {
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useMemo,
  // useMemo,
  useState,
} from "react";
import { ReactComponent as MailIcon } from "assets/icons/mail.svg";
import { ReactComponent as PhoneIcon } from "assets/icons/phone.svg";
import { ReactComponent as CloseIcon } from "assets/icons/x-close.svg";
import Typography from "components/Typography";
// import debounce from "utils/debounce";
import MenuItem from "./MenuItem";
import { getMainMenuItems } from "../constants";
import styles from "../styles.module.scss";
import { useAppSelector } from "hooks/store";
import { selectUserData } from "store/auth/authSlice";
import { selectDealershipsData } from "store/dealership/dealershipSlice";
import useIsProductionApp from "hooks/useIsProductionApp";

interface IProps {
  hoverToggleSidebar: boolean;
  // isMobileView: boolean;
  isSidebarClose: boolean;
  setHoverToggleSidebar: Dispatch<SetStateAction<boolean>>;
  setIsSidebarClose: Dispatch<SetStateAction<boolean>>;
}

const Sidebar: FC<IProps> = ({
  hoverToggleSidebar,
  // isMobileView,
  isSidebarClose,
  setHoverToggleSidebar,
  setIsSidebarClose,
}) => {

  const isProductionApp = useIsProductionApp();
  
  const userAuth = useAppSelector(selectUserData);
  
  const [openSubMenu, setOpenSubMenu] = useState<number | null>(null);

  // const [resultsManagers, setResultsManagers] = useState<IResultManager[]>([]);

  const { dealerships } = useAppSelector(selectDealershipsData);

  const resultsManagers = useMemo(() => {
    return dealerships.find((result) => result.uid === userAuth.dealerV2.uid)?.results_managers || [];
  }, [dealerships, userAuth.dealerV2.uid]);

  // const { data: dealerData, refetch } = useGetDealerships({});

  // const { results: dealerList } = dealerData || {};

  // useMemo(() => {
  //   const newResultsManagers = dealerList?.find((result) => result.uid === userAuth.dealerV2.uid)?.results_managers || [];
  //   setResultsManagers(newResultsManagers);
  // }, [dealerList, userAuth.dealerV2.uid]);
  
  // useEffect(() => {

  //   // Then set up the interval
  //   const intervalId = setInterval(refetch, 10000); // 10000 milliseconds = 10 seconds

  //   // Clear interval on component unmount or when dependencies change
  //   return () => clearInterval(intervalId);

  // }, [refetch]);

  const onOpenSubMenu = useCallback(
    (subMenuIndex: number) => {
      setIsSidebarClose(false);
      setOpenSubMenu((prevOpenSubMenu) =>
        subMenuIndex !== prevOpenSubMenu ? subMenuIndex : null
      );
    },
    [setIsSidebarClose]
  );

  const onCloseSubMenu = useCallback(
    () => setOpenSubMenu(null),
    [setOpenSubMenu]
  );

  const onCloseSidebar = useCallback(
    () => setIsSidebarClose(true),
    [setIsSidebarClose]
  );

  // const onHoverToggleSidebar = debounce(() => {
  //   setHoverToggleSidebar((prevState) => !prevState);
  // }, 200);

  // const resultsManagers = useMemo(
  //   () => userAuth.dealer.resultsManagers,
  //   [userAuth.dealer.resultsManagers]
  // );

  return (
    <div
      className={styles.sidebarContainer}
      // {...(isSidebarClose &&
      //   !isMobileView && {
      //     onMouseOver: !hoverToggleSidebar ? onHoverToggleSidebar : undefined,
      //     onMouseLeave: onHoverToggleSidebar,
      //   })}
    >
      <CloseIcon
        className={styles["sidebarContainer__close"]}
        onClick={onCloseSidebar}
      />
      <img
        className={styles.logo}
        src="assets/images/logo.png"
        alt="logo"
      />
      <ul className={styles.menuItemsContainer}>
        {getMainMenuItems(userAuth.inventory.value, userAuth.roles, userAuth.dealerV2.third_party_provider, isProductionApp).map(({ icon, label, link = "", subMenu }, index) => (
          <MenuItem
            key={index}
            icon={icon}
            index={index}
            isSidebarOpen={hoverToggleSidebar || !isSidebarClose || false}
            link={link}
            label={label}
            // onCloseSidebar={onCloseSidebar}
            onCloseSubMenu={onCloseSubMenu}
            onOpenSubMenu={onOpenSubMenu}
            openSubMenu={openSubMenu}
            subMenu={subMenu}
          />
        ))}
      </ul>
      {!isSidebarClose && !!resultsManagers.length && (
        <div className={styles.managerInfo}>
          <Typography
            className={styles["managerInfo__title"]}
            fontSize="text-sm"
            fontWeight="medium"
          >
            Your VinDrives Results Manager
          </Typography>

          {resultsManagers.map((resultsManager, index) => (
            <div key={index} className={styles["managerInfo__container"]}>
              <div className={styles["managerInfo__divider"]} />
              <Typography fontWeight="semibold">
                {`${resultsManager.user__first_name} ${resultsManager.user__last_name}`}
              </Typography>
              {(resultsManager.user__phone || resultsManager.user__secondary_phone) && (
                <div className={styles["managerInfo__phone"]}>
                  <PhoneIcon />
                  <Typography fontSize="text-sm" fontWeight="medium">
                    {resultsManager.user__phone || resultsManager.user__secondary_phone}
                  </Typography>
                </div>
              )}
              {resultsManager.user__email && (
                <div className={styles["managerInfo__email"]}>
                  <MailIcon />
                  <Typography fontSize="text-sm" fontWeight="medium">
                    {resultsManager.user__email}
                  </Typography>
                </div>
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Sidebar;
