import { FC, ReactNode } from "react";
import { createPortal } from "react-dom";
import cs from "classnames";
import styles from "./styles.module.scss";
import useClickOutside from "hooks/useClickOutside";

interface IModalProps {
  children?: ReactNode;
  classNameContainer?: string;
  classNameModal?: string;
  isOpen: boolean;
  onClose: () => void;
}

const Modal: FC<IModalProps> = ({
  children,
  classNameContainer,
  classNameModal,
  isOpen,
  onClose,
}) => {
  const refOutside = useClickOutside<HTMLDivElement>(() => onClose());

  return isOpen
    ? createPortal(
        <div
          className={cs(
            styles.background,
            styles.openModal,
            classNameContainer
          )}
        >
          <div
            ref={refOutside}
            className={cs(classNameModal, styles.container)}
          >
            {children}
          </div>
        </div>,
        document.body
      )
    : null;
};

export default Modal;
