// import { useAppSelector } from "hooks/store";
// import { selectReportingTabData } from "store/reporting/reportingTabSlice";
import { EOrderingReports } from "api/useGetStatistics";
import useUrlQuery from "hooks/useUrlQuery";
import FrontLineReadyTrend from "pages/Reporting/components/FrontLineReadyTrend";
import { ReportingUrlParams } from "utils/urlParams";
import ReportingTabs from "./components/ReportingTabs";

const Reporting = () => {

  // const reportingTab = useAppSelector(selectReportingTabData);
  
  const { getParam } = useUrlQuery<ReportingUrlParams>();

  const activeTable = getParam("activeTable");
  
  return (
    <>
      {activeTable !== EOrderingReports.VDPBoost && (
        <FrontLineReadyTrend />
      )}
      <ReportingTabs />
    </>
  );
};

export default Reporting;

