import { ChevronDownIcon, SettingsIcon } from "@chakra-ui/icons";
import { Box, Button, IconButton, Popover, PopoverArrow, PopoverContent, PopoverTrigger } from "@chakra-ui/react";
import { HiOutlineMinusCircle, HiOutlinePlusCircle } from "react-icons/hi";
import { IProviderCheckList } from "types/IProviderCheckList";

type CustomizeProviderProps = {
    isPopover?: boolean;
    providers: IProviderCheckList[];
    onChange?: (provider: IProviderCheckList) => void;
};

export const CustomizeProvider = (props: CustomizeProviderProps) => {

    const onProviderChecklist = (provider: IProviderCheckList) => {
        if (props.onChange) {
            props.onChange(provider);
        }
    };

    const contentComponent = () => {
        return (
            <Box
                display="flex"
                flexFlow="column"
                gap="10px"
            >
                {props.providers.map((provider) => {
                    return (
                        <Box
                            key={provider.id}
                            display="flex"
                            justifyContent="space-between"
                            gap="10px"
                            alignItems="center"
                        >
                            <Box>{provider.label}</Box>
                            <Box>
                                <IconButton
                                    isRound={true}
                                    aria-label='checklist'
                                    fontSize='24px'
                                    size="12px"
                                    backgroundColor="transparent"
                                    icon={provider.value ? (
                                        <HiOutlineMinusCircle />
                                    ) : <HiOutlinePlusCircle />}
                                    onClick={() => onProviderChecklist(provider)}
                                />
                            </Box>
                        </Box>
                    );
                })}
            </Box>
        );
    };

    if (!props.isPopover) {
        return contentComponent();
    }
    
    return (
        <Popover>
            <PopoverTrigger>  
                <Button 
                    leftIcon={<SettingsIcon fontSize="14px"/>} 
                    rightIcon={<ChevronDownIcon />}
                    variant='solid'
                    fontSize="14px"
                >
                    Customize
                </Button>
            </PopoverTrigger>
            <PopoverContent
                padding="15px"
            >
                <PopoverArrow />
                {contentComponent()}
            </PopoverContent>
        </Popover>
    );
}