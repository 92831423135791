import FrontLineReadyTable from "./components/FrontLineReadyTable";
import NeedsAttention from "./components/NeedsAttention";
import ResultsSummary from "./components/ResultsSummary";
import ResultScores from "./components/ResultScores";
import ResultScoresLegend from "./components/ResultScoresLegend";
import TopVehiclesVDPTable from "./components/TopVehiclesVDPTable";
import TopVDPByBodyStyle from "./components/TopVDPByBodyStyle";
// import styles from "./styles.module.scss";
import useUrlQuery from "hooks/useUrlQuery";
import { HeaderParams } from "utils/urlParams";
import { useEffect } from "react";
import { Box } from "@chakra-ui/react";
import { useAppSelector } from "hooks/store";
import { selectUserData } from "store/auth/authSlice";
import { useGetNeedAttentionQuery } from "api/needAttentionApi";
import { selectResuleScoreData } from "store/resultScore/resultScoreSlice";

const Home = () => {
  
  const userAuth = useAppSelector(selectUserData);

  const resultScore = useAppSelector(selectResuleScoreData);

  const { setParam } = useUrlQuery<HeaderParams>();
  
  useGetNeedAttentionQuery({
    date_from: resultScore.dateRangeFormatted.startDate || '',
    date_to: resultScore.dateRangeFormatted.endDate || '',
    dealership_uid: userAuth.dealerV2.uid,
    inventory: userAuth.inventory.value,
  });

  useEffect(() => {
    setParam({
      inventory: userAuth.inventory.value
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userAuth.inventory.value])
  
  return (
    <>
    {/* <ResultScoresProvider> */}
      <Box display="flex" gap="20px" flexFlow="column" flex="1">
        <Box flex="1">
          <ResultsSummary />
        </Box>
        <Box display="flex" flex="1" gap="10px" flexFlow={{ base: "column", md: "column", xl: "row" }}>
          <Box flex="2" display="flex" gap="10px" flexFlow="column">
            <Box>
              <ResultScores />
            </Box>
            <Box>
              <ResultScoresLegend />
            </Box>
            <Box>
              <FrontLineReadyTable />
            </Box>
          </Box>
          <Box flex="1" display="flex" gap="10px" flexFlow="column">
            <Box>
              <NeedsAttention />
            </Box>
            <Box>
              <TopVehiclesVDPTable />
            </Box> 
            <Box>
              <TopVDPByBodyStyle />
            </Box>
          </Box>
        </Box>
      </Box>
    {/* </ResultScoresProvider> */}
    </>
  );
};
export default Home;
