import { Table } from "@tanstack/react-table";
import { ReactComponent as ArrowRightIcon } from "assets/icons/arrow-right.svg";
import { ReactComponent as ArrowLeftIcon } from "assets/icons/arrow-left.svg";
import Button from "components/Button";
import Select from "components/SelectInput";
import Typography from "components/Typography";
import styles from "../../styles.module.scss";

export interface IPaginationProps<T> {
  pageSizeOptions?: number[];
  table: Table<T>;
}

const Pagination = <T,>({ pageSizeOptions, table }: IPaginationProps<T>) => (
  <div className={styles.tableNavigation}>
    <div className={styles["tableNavigation__pages"]}>
      <Typography fontSize="text-sm">
        {`Page ${table.getState().pagination.pageIndex + 1}` +
          `${
            table.getState().pagination.pageSize > 0 &&
            ` of ${table.getPageCount()}`
          }`}
      </Typography>
    </div>
    <div className={styles["tableNavigation__paginationActions"]}>
      {pageSizeOptions && (
        <>
          <Typography
            className={
              styles["tableNavigation__paginationActions__rowsPerPageLabel"]
            }
            fontSize="text-sm"
          >
            Rows per page:
          </Typography>
          <Select
            menuPlacement="top"
            options={pageSizeOptions.map((option) => ({
              label: option,
              value: option,
            }))}
            onChange={(newSize) =>
              table.setPageSize(
                (newSize as { label: number; value: number }).value
              )
            }
            components={{ IndicatorSeparator: null }}
            menuPortalTarget={document.body}
            value={{
              label: table.getState().pagination.pageSize,
              value: table.getState().pagination.pageSize,
            }}
          />
        </>
      )}
      <Button
        size="sm"
        className={styles["tableNavigation__paginationActions__prevNext"]}
        variant="outline"
        onClick={() => table.previousPage()}
        disabled={!table.getCanPreviousPage()}
      >
        <ArrowLeftIcon
          className={
            styles["tableNavigation__paginationActions__prevNext__icon"]
          }
        />
      </Button>
      <Button
        size="sm"
        className={styles["tableNavigation__paginationActions__prevNext"]}
        variant="outline"
        onClick={() => table.nextPage()}
        disabled={!table.getCanNextPage()}
      >
        <ArrowRightIcon
          className={
            styles["tableNavigation__paginationActions__prevNext__icon"]
          }
        />
      </Button>
    </div>
  </div>
);

export default Pagination;
