import { logo2Base64Png } from "constants/common";
import {
  // ICommonDateRange,
  IDateRange,
} from "constants/commonDateRanges";
import { options } from "constants/providerFilters";
import { UserOptions } from "jspdf-autotable";
import { sourceOptionsFilter } from "pages/Merchandising/components/Tables/components/TipReport/constants";
import { format } from "date-fns";
// import apiDateFormat from "constants/apiDateFormat";

function delay(seconds: number) {
  return new Promise((res) => setTimeout(res, seconds * 1000));
}

function mapProvidersById(selected_providers: string[]) {
  return options.map((provider) => {
    const is_selected = selected_providers.find(
      (selected) => selected.toLowerCase() === provider.id.toLowerCase()
    );
    return { ...provider, value: !!is_selected };
  });
}

function mapProvidersByValue(selected_provider: string) {
  return sourceOptionsFilter.find(
    (source) => source.value.toLowerCase() === selected_provider.toLowerCase()
  );
}

function isValidDate(date: any) {
  return date instanceof Date && !isNaN(date.getTime());
}

function onGeneratePDFDefaultType(
  title: string,
  dateRange?: string
): Pick<UserOptions, "headStyles" | "willDrawPage" | "didDrawPage" | "margin" | "bodyStyles"> {
  // let pdfTitle = title;
  // if (dateRange) {
  //   console.log('dateRange', dateRange);
  //   pdfTitle = `${title} (${dateRange})`;
  // }

  return {
    headStyles: { fillColor: [255, 0, 0], halign: 'center', valign: 'middle', fontSize: 9 },
    bodyStyles: { halign: 'center', valign: 'middle', fontSize: 9 },
    willDrawPage: function (data) {
      const { doc, settings } = data;

      // Header
      doc.setFontSize(14);
      doc.setTextColor(40);

      // Draw a black rectangle at the top
      doc.setFillColor(0, 0, 0); // black color
      let rectX = settings.margin.left;
      let rectY = 15;
      let rectWidth =
        doc.internal.pageSize.getWidth() -
        settings.margin.left -
        settings.margin.right;
      let rectHeight = 15;
      
      doc.rect(rectX, rectY, rectWidth, rectHeight, "F");

      if (logo2Base64Png) {
        let logoWidth = 1288; // Original width of the logo
        let logoHeight = 520; // Original height of the logo
        let aspectRatio = logoWidth / logoHeight;

        // Allowance for the logo
        let allowance = 1;
        let maxWidth = rectWidth - 2 * allowance; // Subtract the allowance from both sides
        let maxHeight = rectHeight - 2 * allowance; // Subtract the allowance from top and bottom

        // Scale the logo to fit within the black rectangle while maintaining the aspect ratio
        if (maxWidth / maxHeight > aspectRatio) {
          // If the rectangle is wider than the logo (relative to their heights), scale the logo based on its height
          logoHeight = maxHeight;
          logoWidth = logoHeight * aspectRatio;
        } else {
          // If the rectangle is taller than the logo (relative to their widths), scale the logo based on its width
          logoWidth = maxWidth;
          logoHeight = logoWidth / aspectRatio;
        }

        const logoX = rectX + allowance + (maxWidth - logoWidth) / 2; // Calculate the x-coordinate for the logo to be centered within the rectangle
        const logoY = rectY + allowance + (maxHeight - logoHeight) / 2; // Calculate the y-coordinate for the logo to be centered within the rectangle
        doc.addImage(
          logo2Base64Png,
          "png",
          logoX,
          logoY,
          logoWidth,
          logoHeight
        );
      }

      // show in right side
      if (dateRange) {
        const pageWidth = doc.internal.pageSize.getWidth();
        const textWidth =
          (doc.getStringUnitWidth(dateRange) * doc.internal.getFontSize()) /
          doc.internal.scaleFactor;
        const textOffset = pageWidth - textWidth - settings.margin.right;
        doc.setTextColor(169, 169, 169); // Set the text color to gray
        doc.text(dateRange, textOffset, 40);
        doc.setTextColor(0, 0, 0); // Reset the text color to black for subsequent text
      }

      doc.setFont(undefined, "bold");
      doc.text(title, settings.margin.left, 40);
      doc.setFont(undefined, "normal"); // Reset the font style to normal for subsequent text
      // doc.text(title, settings.margin.left + 15, 22) // original
    },
    // didDrawPage: function (data) {
    //   // Footer
    //   var str = 'Page ' + data.pageNumber
    //   // Total page number plugin only available in jspdf v1.0+

    //   if (typeof data.doc.putTotalPages === 'function') {
    //     str = str + ' of ' + data.table.pageNumber
    //   }
    //   data.doc.setFontSize(10);

    //   // jsPDF 1.4+ uses getHeight, <1.4 uses .height
    //   var pageSize = data.doc.internal.pageSize;
    //   var pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
    //   data.doc.text(str, data.settings.margin.left, pageHeight - 10);
    // },
    margin: { top: 45 },
  };
}

function capitalizeFirstLetter(str: string): string {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

function formattedDateRange(dateRange: IDateRange) {
  return `${format(dateRange.startDate, "MMM d, yyyy")} - ${format(
    dateRange.endDate,
    "MMM d, yyyy"
  )}`;
}

function formatNumber(value: number, decimal?: number) {
  return value
      .toFixed(decimal ? decimal : 0)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

function defaultValueToDash(value: any) {
  if (!value || value === 'nan' || value === 'NaN' || value === null || value === undefined) {
    return '-';
  } else {
    return value;
  }
}

export {
  delay,
  mapProvidersById,
  mapProvidersByValue,
  isValidDate,
  onGeneratePDFDefaultType,
  capitalizeFirstLetter,
  formattedDateRange,
  formatNumber,
  defaultValueToDash
};
