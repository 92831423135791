import React, { FC } from "react";
import ReactSwitch, { ReactSwitchProps } from "react-switch";

interface ISwitchProps extends ReactSwitchProps {}

const Switch: FC<ISwitchProps> = ({ ...restProps }) => (
  <ReactSwitch
    {...restProps}
    checkedIcon={false}
    uncheckedIcon={false}
    onColor="#475467"
    offColor="#EAECF0"
    height={20}
    width={36}
  />
);

export default Switch;
