import { createColumnHelper } from "@tanstack/react-table";
import { TTipReportV2Results } from "api/useGetSales";
import Badge from "components/Badge";
import { EAPIProviderRanks, EProviderInitials } from "types/APIProviderRanks";
// import formatCellVehicle from "utils/formatCellVehicle";
import getProviderRank from "utils/getProviderRank";
import styles from "pages/Merchandising/styles.module.scss";
import { IDropdownFilterKeyPair } from "components/Table";
import TAggregatorColumnXLS from "types/TAggregatorColumnXLS";
import TAggregatorColumnPDF from "types/TAggregatorColumnPDF";
import { Box } from "@chakra-ui/react";

const columnHelper = createColumnHelper<TTipReportV2Results>();
export const columns = [
  {
    // classNameHeader: styles.stockNumberHeader,
    ...columnHelper.accessor("stock_number", {
      id: "stockNumber",
      cell: (info) => info.getValue(),
      enableSorting: false,
      header: () => <span>Stock #</span>,
      enableColumnFilter: false,
      // size: 120,
      minSize: 100,
      maxSize: 100
    }),
  },
  {
    classNameHeader: styles.vehicleHeader,
    ...columnHelper.accessor('vehicle', {
      id: "vehicle",
      cell: (info) => info.getValue(),
      header: () => <span>Vehicle</span>,
      enableColumnFilter: false,
    }),
  },
  {
    classNameHeader: styles.dealRatingHeader,
    ...columnHelper.accessor("deal_rating", {
      id: "deal_rating",
      cell: (info) => {
        const { color, icon, label } = getProviderRank(info.getValue() as EAPIProviderRanks);
        return <Badge color={color} icon={icon} label={label} size="sm" />;
      },
      header: () => <span>Deal Rating</span>,
      enableColumnFilter: false,
    }),
  },
  {
    classNameHeader: styles.priceHeader,
    ...columnHelper.accessor("price", {
      id: "price",
      size: 150,
      cell: (info) => (
        <Box overflowWrap="normal">
          {`$${Math.round(Number(info.getValue() || 0)).toLocaleString()}`}
        </Box>
      ),
      header: () => <span>Price</span>,
      enableColumnFilter: false,
    }),
  },
  {
    classNameHeader: styles.newDealRatingHeader,
    ...columnHelper.accessor("new_deal_rating", {
      id: "new_deal_rating",
      cell: (info) => {
        const { color, icon, label } = getProviderRank(info.getValue() as EAPIProviderRanks);
        return <Badge color={color} icon={icon} label={label} size="sm" />;
      },
      header: () => <span>Next Deal Rating</span>,
      enableColumnFilter: false,
    }),
  },
  {
    classNameHeader: styles.newPriceHeader,
    ...columnHelper.accessor("new_price", {
      id: "new_price",
      size: 150,
      cell: (info) => (
        <Box overflowWrap="normal">
          {`$${Math.round(info.getValue() || 0).toLocaleString()}`}
        </Box>
      ),
      header: () => <span>Price at Next Deal Rating</span>,
      enableColumnFilter: false,
    }),
  },
  {
    classNameHeader: styles.priceChangeHeader,
    type: "number",
    title: "Price Change",
    ...columnHelper.accessor("price_change", {
      id: "price_change",
      size: 150,
      cell: (info) => (
        <Box overflowWrap="normal">
          {`$${Math.round(info.getValue() || 0).toLocaleString()}`}
        </Box>
      ),
      header: () => <span>Price Change at Next Deal Rating</span>,
      enableColumnFilter: true,
    }),
  },
  {
    classNameHeader: styles.nextDealRatingHeader,
    filterColumnId: EProviderInitials.Autotrader,
    ...columnHelper.accessor("great_deal_rating", {
      id: "great_deal_rating",
      cell: (info) => {
        const { color, icon, label } = getProviderRank(info.getValue() as EAPIProviderRanks);
        return <Badge color={color} icon={icon} label={label} size="sm" />;
      },
      header: () => <span>Great Deal Rating</span>,
      enableColumnFilter: false,
    }),
  },
  {
    classNameHeader: styles.priceNextDealRatingHeader,
    filterColumnId: EProviderInitials.Autotrader,
    ...columnHelper.accessor("price_at_great_deal_rating", {
      id: "price_at_great_deal_rating",
      size: 150,
      cell: (info) => (
        <Box overflowWrap="normal">
          {`$${Math.round(info.getValue() || 0).toLocaleString()}`}
        </Box>
      ),
      enableSorting: false,
      header: () => <span>Price at Great Deal Rating</span>,
      enableColumnFilter: false,
    }),
  },
  {
    classNameHeader: styles.priceChangeDealRatingHeader,
    filterColumnId: EProviderInitials.Autotrader,
    ...columnHelper.accessor("price_change_at_great_deal_rating", {
      id: "price_change_at_great_deal_rating",
      size: 150,
      cell: (info) => (
        <Box overflowWrap="normal">
          {`$${Math.round(info.getValue() || 0).toLocaleString()}`}
        </Box>
      ),
      enableSorting: false,
      header: () => <span>Price Change at Great Deal Rating</span>,
      enableColumnFilter: false,
    }),
  },
  {
    classNameHeader: styles.ageHeader,
    ...columnHelper.accessor("age", {
      id: "age",
      size: 150,
      cell: (info) => (
        <Box overflowWrap="normal">
          {(info.getValue() || 0).toLocaleString()}
        </Box>
      ),
      header: () => <span>Age</span>,
      enableColumnFilter: false,
    }),
  },
];

export const sourceOptionsFilter: IDropdownFilterKeyPair[] = [
  { label: "Autotrader", value: EProviderInitials.Autotrader },
  { label: "Cars.com", value: EProviderInitials.CarsDotCom },
  // { label: "Carfax", value: EProviderInitials.Carfax },
  { label: "CarGurus", value: EProviderInitials.CarGurus },
];

export const xlsColumns: TAggregatorColumnXLS<TTipReportV2Results>[] = [
  { label: "Stock #", key: "stock_number" },
  {
    label: "Vehicle",
    key: "vehicle",
    // func: (value, row) => (row ? formatCellVehicle(row) : "-"),
  },
  {
    label: "Deal Rating",
    key: "deal_rating",
    func: (value) => getProviderRank(value as EAPIProviderRanks).label,
  },
  {
    label: "Price",
    key: "price",
    func: (value) => `$${Math.round(Number(value)).toLocaleString()}`,
  },
  {
    label: "Next Deal Rating",
    key: "new_deal_rating",
    func: (value) => getProviderRank(value as EAPIProviderRanks).label,
  },
  {
    label: "Price at Next Deal Rating",
    key: "new_price",
    func: (value) => `$${Math.round(Number(value)).toLocaleString()}`,
  },
  {
    label: "Price Change at Next Deal Rating",
    key: "price_change",
    func: (value) => `$${Math.round(Number(value)).toLocaleString()}`,
  },
  {
    label: "Great Deal Rating",
    key: "great_deal_rating",
    func: (value) => getProviderRank(value as EAPIProviderRanks).label,
    filterColumnId: EProviderInitials.Autotrader,
  },
  {
    label: "Price at Great Deal Rating",
    key: "price_at_great_deal_rating",
    func: (value) => `$${Math.round(Number(value)).toLocaleString()}`,
    filterColumnId: EProviderInitials.Autotrader,
  },
  {
    label: "Price Change at Great Deal Rating",
    key: "price_change_at_great_deal_rating",
    func: (value) => `$${Math.round(Number(value)).toLocaleString()}`,
    filterColumnId: EProviderInitials.Autotrader,
  },
  {
    label: "Age",
    key: "age",
  },
];

export const pdfColumns: TAggregatorColumnPDF<TTipReportV2Results>[] = [
  {
    key: "stock_number",
    label: "Stock #",
  },
  {
    key: "vehicle",
    label: "Vehicle",
    // func: (value, row) => (row ? formatCellVehicle(row) : "-"),
  },
  {
    key: 'deal_rating',
    label: 'Deal Rating',
  },
  {
    key: 'price',
    label: 'Price',
    func: (value) => `$${Math.round(Number(value)).toLocaleString()}`,
  },
  {
    key: 'new_deal_rating',
    label: 'Next Deal Rating',
  },
  {
    key: 'new_price',
    label: 'Price at Next Deal Rating',
    func: (value) => `$${Math.round(Number(value)).toLocaleString()}`,
  },
  {
    key: 'price_change',
    label: 'Price Change at Next Deal Rating',
    func: (value) => `$${Math.round(Number(value)).toLocaleString()}`,
  },
  {
    filterColumnId: EProviderInitials.Autotrader,
    key: 'great_deal_rating',
    label: 'Great Deal Rating'
  },
  {
    filterColumnId: EProviderInitials.Autotrader,
    key: 'price_at_great_deal_rating',
    label: 'Price at Great Deal Rating',
    func: (value) => `$${Math.round(Number(value)).toLocaleString()}`,
  },
  {
    filterColumnId: EProviderInitials.Autotrader,
    key: 'price_change_at_great_deal_rating',
    label: 'Price Change at Great Deal Rating',
    func: (value) => `$${Math.round(Number(value)).toLocaleString()}`,
  },
  {
    key: 'age',
    label: 'Age'
  },
];


export const defaultOffset = 0;
