import { Box, IconButton, Select } from "@chakra-ui/react";
import { Table } from "@tanstack/react-table";
import { pageSizeOptions } from "constants/defaultPageSizes";
import { RiArrowLeftDoubleFill, RiArrowLeftSLine, RiArrowRightDoubleFill, RiArrowRightSLine } from "react-icons/ri";

type TablePaginationProps<TData> = {
    table: Table<TData>
    rowCount?: number;
};

const TablePagination = <T, >(props: TablePaginationProps<T>) => {

    const { table, rowCount } = props;

    return (
        <Box 
            display="flex"
            justifyContent="space-between"
            gap="10px"
            alignItems="center"
            flexFlow="row wrap"
        >
            <Box>
                <span>
                    Page{" "}
                    <strong>
                    {table.getState().pagination.pageIndex + 1} of{" "}
                    {Math.ceil((rowCount || 0) / table.getState().pagination.pageSize)}
                    </strong>{" "}
                </span>
            </Box>
            <Box 
                display="flex"
                justifyContent="flex-end"
                gap="10px"
                pt="10px"
            >
            <Select 
                defaultValue={table.getState().pagination.pageSize}
                onChange={(e) => {
                    const tempValue = e.target.value;
                    table.setPageSize(Number(tempValue));
                }}
            >
                {pageSizeOptions.map(pageSize => (
                    <option key={pageSize} value={pageSize.toString()}>{pageSize}</option>
                ))}
            </Select>
                <IconButton 
                    aria-label="First Page"
                    icon={<RiArrowLeftDoubleFill />} 
                    onClick={() => table.firstPage()}
                    disabled={!table.getCanPreviousPage()}
                    opacity={!table.getCanPreviousPage() ? 0.3 : 1}
                />
                <IconButton 
                    aria-label="Previos Page"
                    icon={<RiArrowLeftSLine />} 
                    onClick={() => table.previousPage()}
                    disabled={!table.getCanPreviousPage()}
                    opacity={!table.getCanPreviousPage() ? 0.3 : 1}
                />
                <IconButton 
                    aria-label="Next Page"
                    icon={<RiArrowRightSLine />} 
                    onClick={() => table.nextPage()}
                    disabled={!table.getCanNextPage()}
                    opacity={!table.getCanNextPage() ? 0.3 : 1}
                />
                <IconButton 
                    aria-label="Last Page"
                    icon={<RiArrowRightDoubleFill />} 
                    onClick={() => table.lastPage()}
                    disabled={!table.getCanNextPage()}
                    opacity={!table.getCanNextPage() ? 0.3 : 1}
                />
            </Box>
        </Box>
    );
};

export default TablePagination;