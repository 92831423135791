import { useQuery } from "@tanstack/react-query";
import { IDealership, TInventory } from "types/APIParameters";
import fetchData from "utils/fetchData";

export type VDPBoostResult = {
    data: VDPBoostData[];
    total: VDPBoostTotal;
};

export type VDPBoostTotal = {
  current_vdp: string;
  suggested_vdp: string;
  target_vdp: string;
}

export type VDPBoostData = {
    vehicle:       string;
    make:          string;
    model:         string;
    target_vdp:    string;
    treeGroupId:   number;
    id:            number;
    current_vdp:   string;
    stock_number:  string;
    suggested_vdp: string;
    age: number;
}

interface IParams extends IDealership {}

interface VDPBoostParams extends IParams, TInventory {}

export enum EBoostModules {
  VDPBoost = 'api/boost/vdp-boost',
}

interface IOptions<T extends string> {
  module: EBoostModules;
  params?: T extends EBoostModules.VDPBoost
    ? VDPBoostParams 
    : IParams;
}

type FetchDataReturnType<T extends IOptions<T>["module"]> =
  T extends EBoostModules.VDPBoost
    ? VDPBoostResult
    : T;

const useGetBoost = <T extends IOptions<T>["module"]>({
  module,
  params,
}: IOptions<T>) => {
  return useQuery({
    refetchOnWindowFocus: false,
    queryFn: getBoostAsync<T>({ module, params }),
    queryKey: ["getBoost", module, ...Object.values(params || {})],
  });
};

export const getBoostAsync = <T extends IOptions<T>["module"]>({
  module,
  params,
}: IOptions<T>) =>
  fetchData<FetchDataReturnType<T>>({
    isAuth: true,
    method: "GET",
    params,
    url: `${process.env.REACT_APP_API_URL || ""}${
      module ? `${module}` : ""
    }`,
  });

export default useGetBoost;
