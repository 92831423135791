import {
  IAPIDealRatings,
  // IAPIPreShootReport,
  IAPIRecentActivityReport,
  IAPIRecentActivityReportV2,
  IAPITipReport,
} from "api/useGetSales";

const formatCellVehicle = (
  row:
    | IAPIRecentActivityReport
    | IAPIRecentActivityReportV2
    | IAPIDealRatings
    | IAPITipReport
    // | IAPIPreShootReport
) => `${row.year} ${row.make} ${row.model}${row?.trim ? ` ${row.trim}` : ""}`;

export default formatCellVehicle;
