import AttentionList from "components/AttentionList";
import { allTime } from "constants/commonDateRanges";
import IDateRangeFormatted from "types/IDateRangeFormatted";
import { format } from "date-fns";
import apiDateFormat from "constants/apiDateFormat";
import { useAppSelector } from "hooks/store";
import { selectResuleScoreData } from "store/resultScore/resultScoreSlice";
import { Box } from "@chakra-ui/react";

const NeedsAttention = () => {

  const { needsAttention } = useAppSelector(selectResuleScoreData);

  const dateRange: IDateRangeFormatted = {
    startDate: format(allTime.startDate, apiDateFormat),
    endDate: format(allTime.endDate, apiDateFormat),
  };
  
  return (
    <Box>
      <AttentionList
        dateRange={dateRange}
        items={[
          {
            reviewFilter: "red",
            score: needsAttention.redScore,
            title: "Vehicles with Red Results Score",
          },
          {
            reviewFilter: "yellow",
            score: needsAttention.yellowScore,
            title: "Vehicles with Yellow Results Score",
          },
        ]}
      />
    </Box>
  );
};

export default NeedsAttention;
