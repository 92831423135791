import {
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import cs from "classnames";
import Button from "components/Button";
import styles from "../styles.module.scss";
import { IData } from "../types";

type TFilterItem = Omit<IData, "values">;

interface IProps {
  filterItems: TFilterItem[];
  hideSeries: string[];
  filterLabel?: string;
  setHideSeries: Dispatch<SetStateAction<string[]>>;
}

const FilterSeries: FC<IProps> = ({
  filterItems,
  filterLabel,
  hideSeries,
  setHideSeries,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [showFilterSeries, setShowFilterSeries] = useState<boolean>(false);

  const toggleShowFilterSeries = useCallback(
    () => setShowFilterSeries((prevState) => !prevState),
    [setShowFilterSeries]
  );

  const onSelectSeries = useCallback(
    (selectedSeries: string) =>
      setHideSeries((prevState) =>
        prevState.includes(selectedSeries)
          ? prevState.filter(
              (currentSeries) => currentSeries !== selectedSeries
            )
          : [...prevState, selectedSeries]
      ),
    [setHideSeries]
  );

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target as Node)
      ) {
        toggleShowFilterSeries();
      }
    };

    if (showFilterSeries) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [containerRef, toggleShowFilterSeries, showFilterSeries]);

  return (
    <div ref={containerRef} className={styles.filterSeriesContainer}>
      <Button onClick={toggleShowFilterSeries}>Customize</Button>
      {showFilterSeries && (
        <div className={styles.filterSeriesModal}>
          <p className={styles.filterTitle}>
            {filterLabel || "Show only results of:"}
          </p>
          <hr className={styles.filterSeriesDivider} />
          <div className={styles.seriesContainer}>
            {filterItems.map(({ color, id, label }) => (
              <button
                key={id}
                className={cs(styles.series, {
                  [styles.seriesSelected]: hideSeries.includes(id),
                })}
                style={{
                  backgroundColor: color || "currentColor",
                }}
                onClick={() => onSelectSeries(id)}
              >
                {label}
              </button>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default FilterSeries;
