import { Navigate, Outlet } from "react-router-dom";
import ERoutes from "types/Routes";
import styles from "./styles.module.scss";
import { useAppSelector } from "hooks/store";
import { selectIsAuthenticated } from "store/auth/authSlice";

const UserManagementLayout = () => {

  const isAuthenticated = useAppSelector(selectIsAuthenticated);

  return isAuthenticated ? (
    <Navigate to={ERoutes.Home} replace />
  ) : (
    <div className={styles.userManagementContainer}>
      <Outlet />
    </div>
  );
};
export default UserManagementLayout;
