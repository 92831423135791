import { delay } from "./helper";

const configGoogle = async () => {
  function asyncGA(): Promise<{ (..._: any): void }> {
    return new Promise(async (res) => {
      let status = false,
        tag = null;
      while (!status) {
        await delay(1);
        tag = window.dataLayer;
        if (tag) status = true;
      }
      function push(..._: any) {
        tag.push(arguments);
      }
      res(push);
    });
  }

  const push = await asyncGA();
  push("js", new Date());
  push("config", "G-6175H2BKY3");
};

export { configGoogle };
