import { useSearchParams } from 'react-router-dom';

interface UseUrlQuery<T extends Record<string, string | string[]>> {
  getParam: <K extends keyof T>(key: K) => T[K] | null;
  getMultipleParams: <K extends keyof T>(key: K) => T[K] | null;
  setParam: (params: Partial<T>) => void;
  setMultipleParams: (params: Partial<T>) => void;
  replaceParam: (params: T) => void;
  removeParam: <K extends keyof T>(key: K) => void;
  removeMultipleParams: <K extends keyof T>(keys: K[]) => void;
}

export const useUrlQuery = <T extends Record<string, string | string[]>>(): UseUrlQuery<T> => {
  const [searchParams, setSearchParams] = useSearchParams();

  const getParam = <K extends keyof T>(key: K): T[K] | null => {
    const value = searchParams.get(key as string);
    return value as T[K] | null; // return (value ? value.split(',') : null) as T[K] | null;
  };

  const getMultipleParams = <K extends keyof T>(key: K): T[K] | null => {
    const params = new URLSearchParams(window.location.search);
    const values = params.getAll(key as string);
    return (values.length > 0 ? values : null) as T[K] | null;
  };

  const setParam = (params: Partial<T>): void => {
    const newParams = { ...Object.fromEntries(searchParams), ...params };
    setSearchParams(newParams as Record<string, string>);
  };

  const setMultipleParams = (params: Partial<T>): void => {
    const newParams = new URLSearchParams(window.location.search);
    Object.entries(params).forEach(([key, values]) => {
      newParams.delete(key);
      if (Array.isArray(values)) {
        values.forEach(value => newParams.append(key, value));
      } else if (values) {
        newParams.append(key, values as string);
      }
    });
    setSearchParams(newParams);
  };

  const replaceParam = (params: T): void => {
    setSearchParams(params as Record<string, string>);
  };

  const removeParam = <K extends keyof T>(key: K): void => {
    searchParams.delete(key as string);
    setSearchParams(new URLSearchParams(searchParams));
  };

  const removeMultipleParams = <K extends keyof T>(keys: K[]): void => {
    keys.forEach(key => searchParams.delete(key as string));
    setSearchParams(new URLSearchParams(searchParams));
  };

  return { getParam, getMultipleParams, setParam, setMultipleParams, replaceParam, removeParam, removeMultipleParams };
};

export default useUrlQuery;