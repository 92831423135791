import { useCallback, useEffect, useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import Tabs from "components/Tabs";
import { EMerchandisingReports } from "types/MerchandisingPage";
import { EQueryParameterKeys, ERoutesAnchors } from "types/Routes";
import styles from "pages/Merchandising/styles.module.scss";
import { useAppSelector } from "hooks/store";
import { selectUserData } from "store/auth/authSlice";
import TipReportV2 from "pages/v2/Merchandising/TipReport";
import ChannelStatsV2 from "pages/v2/Merchandising/ChannelStats";
import PreShootReport from "pages/v2/Merchandising/PreShootReport";
import DealRatingsV2 from "pages/v2/Merchandising/DealRatings";

const Tables = () => {

  const [searchParams, setSearchParams] = useSearchParams();

  const userAuth = useAppSelector(selectUserData);

  const onChangeActiveTable = useCallback(
    (_: number, newActiveTableId?: string | number) =>
      setSearchParams({
        [EQueryParameterKeys.ActiveTable]:
          newActiveTableId as EMerchandisingReports,
      }),
    [setSearchParams]
  );

  const activeTableId = useMemo(
    () => searchParams.get(EQueryParameterKeys.ActiveTable),
    [searchParams]
  );

  const getTableTabs = useCallback(() => ([
    ...(userAuth.inventory.value === 'used' && userAuth.dealerV2.third_party_provider.length > 0 ? [
      { id: EMerchandisingReports.DealRatings, label: "Deal Ratings by Channel" },
      { id: EMerchandisingReports.TipReport, label: "Tip Report" },
    ] : []),
    { id: EMerchandisingReports.ChannelStats, label: "Channel Stats Report" },
    { id: EMerchandisingReports.PreShootReport, label: "Pre Shoot Report" },
  ]), [userAuth.inventory.value, userAuth.dealerV2.third_party_provider]);

  const activeTableIndex = useMemo(
    () => getTableTabs().findIndex((tableTab) => tableTab.id === activeTableId),
    [activeTableId, getTableTabs]
  );


  useEffect(() => {
    if (activeTableIndex === -1) {
      setSearchParams(prevSearchParams => ({
        ...prevSearchParams,
        activeTable: EMerchandisingReports.ChannelStats
      }));
    }
  }, [activeTableIndex, setSearchParams]);

  return (
    <div id={ERoutesAnchors.MerchandisingTables} className={styles.tables}>
      <Tabs
        className={styles["tables__tabOptions"]}
        active={activeTableIndex !== -1 ? activeTableIndex : 0}
        tabs={getTableTabs()}
        onClickTab={onChangeActiveTable}
      />
      {activeTableId === EMerchandisingReports.DealRatings && (
        <div className={styles["tables__tab--dealRatingsTable"]}>
          <DealRatingsV2 />
        </div>
      )}
      {activeTableId === EMerchandisingReports.TipReport && (
        <div className={styles["tables__tab--tipReportTable"]}>
          <TipReportV2 />
        </div>
      )}
      {activeTableId === EMerchandisingReports.ChannelStats && (
        <div className={styles["tables__tab--channelStatsTable"]}>
          <ChannelStatsV2 />
        </div>
      )}
      {activeTableId === EMerchandisingReports.PreShootReport && (
        <div className={styles["tables__tab--preShootReportTable"]}>
          <PreShootReport />
        </div>
      )}
    </div>
  );
};
export default Tables;
