import { FC, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import Chart from "react-apexcharts";
import cs from "classnames";
import ButtonGroup from "components/ButtonGroup";
import FilterSeries from "./components/FilterSeries";
import styles from "./styles.module.scss";
import { IData, IFilter } from "./types";
import LoadingSpinner from "components/LoadingSpinner";

import ERoutes, { EQueryParameterKeys } from "types/Routes";
import { format, subDays } from "date-fns";
import { allTime } from "constants/commonDateRanges";
import IDateRangeFormatted from "types/IDateRangeFormatted";
import apiDateFormat from "constants/apiDateFormat";

interface IProps {
  className?: string;
  data: IData[];
  filter?: string;
  filters?: IFilter[];
  filterSeriesLabel?: string;
  isLoading?: boolean;
  onChangeFilter?: (filter: string) => void;
  showLegend?: boolean;
  showXYGridLines?: boolean;
  showYAxis?: boolean;
  xAxisFormat?: ApexXAxis;
}

const BarChart: FC<IProps> = ({
  className,
  data,
  filter,
  filters,
  filterSeriesLabel,
  isLoading = false,
  onChangeFilter,
  showLegend = true,
  showXYGridLines = true,
  showYAxis = true,
  xAxisFormat = {},
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const topSectionRef = useRef<HTMLDivElement>(null);
  const [hideSeries, setHideSeries] = useState<string[]>([]);

  let filteredData = useMemo(() => [...data], [data]);
  if (hideSeries.length) {
    filteredData = filteredData.filter(({ id }) => !hideSeries.includes(id));
  }

  const navigate = useNavigate();

  return (
    <LoadingSpinner isLoading={isLoading}>
      <div
        ref={containerRef}
        className={cs(styles.barGraphContainer, className)}
      >
        <div ref={topSectionRef} className={styles.barGraphTopSection}>
          {filters?.length ? (
            <ButtonGroup
              activeButton={filters.findIndex(({ id }) => filter === id)}
              items={filters.map(({ id, label }) => ({ label, value: id }))}
              onClick={(index, value) => {
                onChangeFilter?.(value);
              }}
            />
          ) : null}
          <FilterSeries
            filterItems={data}
            filterLabel={filterSeriesLabel}
            hideSeries={hideSeries}
            setHideSeries={setHideSeries}
          />
        </div>
        <div className={styles.barGraphContent}>
          <Chart
            className={styles["barGraphContent__chart"]}
            type="bar"
            height="100%"
            width="100%"
            options={{
              chart: {
                stacked: true,
                toolbar: { show: false },
                zoom: {
                  enabled: false,
                },
                events: {
                  click: function (event, chartContext, config) {
                    
                    if (config.seriesIndex === -1) {
                      return;
                    }

                    const dolRange = config.config.xaxis.categories[
                      config.dataPointIndex
                    ].replace(/\+/g, "");

                    const dateRange: IDateRangeFormatted = {
                      startDate: format(allTime.startDate, apiDateFormat),
                      endDate: format(allTime.endDate, apiDateFormat),
                    };
                    
                    // let startDate, endDate;

                    // if (localStorage.defaultFilters.includes("lastMonth")) {
                    //   startDate = format(
                    //     new Date(
                    //       new Date().getFullYear(),
                    //       new Date().getMonth() - 1,
                    //       1
                    //     ),
                    //     "yyyy-MM-dd"
                    //   );
                    //   endDate = format(
                    //     new Date(
                    //       new Date().getFullYear(),
                    //       new Date().getMonth(),
                    //       0
                    //     ),
                    //     "yyyy-MM-dd"
                    //   );
                    // } else if (localStorage.defaultFilters.includes("today")) {
                    //   startDate = format(new Date(), "yyyy-MM-dd");
                    //   endDate = format(new Date(), "yyyy-MM-dd");
                    // } else {
                    //   startDate = format(subDays(new Date(), 7), "yyyy-MM-dd");
                    //   endDate = format(subDays(new Date(), 1), "yyyy-MM-dd");
                    // }

                    const splitDolRange = dolRange.split('-');

                    const dolMax = splitDolRange[1];
                    const dolMin = splitDolRange[0] === '60' ? 61 : splitDolRange[0];

                    console.log('dolRange', dolMin, dolMax);  

                    const tempUrl = `${ERoutes.VDPResults}?${
                      EQueryParameterKeys.ResultScore
                    }=${config.config.series[
                      config.seriesIndex
                    ].name.toLowerCase()}&${
                      EQueryParameterKeys.EndDate
                    }=${dateRange.endDate}&${
                      EQueryParameterKeys.StartDate
                    }=${dateRange.startDate}&${
                      EQueryParameterKeys.dol_min
                    }=${dolMin}&${
                      EQueryParameterKeys.dol_max
                    }=${dolMax || 2000}`;

                    navigate(tempUrl);
                  },
                },
              },
              grid: {
                yaxis: {
                  lines: {
                    show: showYAxis && showXYGridLines,
                  },
                },
                xaxis: {
                  lines: {
                    show: showXYGridLines,
                  },
                },
              },
              legend: {
                show: showLegend,
              },
              plotOptions: {
                bar: {
                  columnWidth: "54%",
                  horizontal: false,
                  borderRadius: 10,
                },
              },
              stroke: {
                show: false,
              },
              xaxis: {
                ...xAxisFormat,
              },
              yaxis: {
                axisTicks: { show: showYAxis },
                show: showYAxis,
                max: (maxSeriesValue: number) => maxSeriesValue,
              },
            }}
            series={filteredData.map(({ color, label, values }) => ({
              color,
              data: values,
              name: label,
            }))}
          />
        </div>
      </div>
    </LoadingSpinner>
  );
};
export default BarChart;
