import React from "react";
import ReactDOM from "react-dom/client";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { configGoogle } from "utils/services";
import { is_prod } from "utils/checker";

import "./index.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
// import { AuthProvider } from "./contexts/AuthContext";
import { ChakraProvider, extendTheme, type ThemeConfig  } from '@chakra-ui/react'
import { BrowserRouter as Router } from "react-router-dom";
import { Provider } from "react-redux";
import store from "store";

localStorage.setItem('chakra-ui-color-mode', 'light');

const queryClient = new QueryClient();

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

if (is_prod) {
  configGoogle();
  console.log = function () {};
}

const config: ThemeConfig = {
  initialColorMode: 'light',
  useSystemColorMode: false,
}

const theme = extendTheme({
  config
});

root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <Router>
        {/* <AuthProvider> */}
          <ChakraProvider theme={theme}>
            <Provider store={store}>
              <App />
            </Provider>
          </ChakraProvider>
        {/* </AuthProvider> */}
      </Router>
    </QueryClientProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
