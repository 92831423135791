import { VDPBoostData } from "api/useGetBoost";
import { TInventoryOptionValue } from "constants/common";
import TAggregatorColumnPDF from "types/TAggregatorColumnPDF";
import TAggregatorColumnXLS from "types/TAggregatorColumnXLS";

export const getPdfColumns = (
  inventory: TInventoryOptionValue
): TAggregatorColumnPDF<VDPBoostData>[] => [
  {
    label: "Vehicle",
    key: "vehicle",
  },
  {
    label: "Stock #",
    key: "stock_number",
  },
  {
    label: 'Age',
    key: 'age'
  },
  {
    label: "Current VDP",
    key: "current_vdp",
  },
  {
    label: "VDP Variance",
    key: "suggested_vdp",
  },
  {
    label: "Target VDP",
    key: "target_vdp",
  },
];

export const getXLSColumns = (
  inventory: TInventoryOptionValue
): TAggregatorColumnXLS<VDPBoostData>[] => [
  {
    label: "Vehicle",
    key: "vehicle",
  },
  {
    label: "Stock #",
    key: "stock_number",
  },
  {
    label: 'Age',
    key: 'age'
  },
  {
    label: "Current VDP",
    key: "current_vdp",
  },
  {
    label: "VDP Variance",
    key: "suggested_vdp",
  },
  {
    label: "Target VDP",
    key: "target_vdp",
  },
];
