import { CloseIcon, SearchIcon } from '@chakra-ui/icons';
import { IconButton, Input, InputGroup, InputLeftElement, InputRightElement } from '@chakra-ui/react';
import { ChangeEventHandler } from 'react';

type SearchFieldProps<T> = {
    value?: string | readonly string[] | number | undefined;
    onChange?: ChangeEventHandler<T> | undefined;
    onClear?: () => void;
};

const SearchField: React.FC<SearchFieldProps<HTMLInputElement>> = (props) => {
    return (
    <InputGroup
        maxWidth={300}
    >
        <InputLeftElement pointerEvents='none'>
            <SearchIcon color='gray.300' />
        </InputLeftElement>
        <Input 
            type='text' 
            placeholder='Search' 
            defaultValue={props.value}
            // value={props.value}
            onChange={props.onChange}
            // height="43px"
        />
        {props.onClear && props.value ? (
            <InputRightElement>
                <IconButton 
                    aria-label="Clear search"
                    backgroundColor="transparent"
                    onClick={props.onClear}
                    // eslint-disable-next-line react/jsx-no-undef
                    icon={<CloseIcon fontSize={10} />}
                />
            </InputRightElement>
        ) : null}
    </InputGroup>
    );
};

export default SearchField;