import { FC, ReactNode } from "react";
import cs from "classnames";
import { ReactComponent as CloseIcon } from "assets/icons/x-close.svg";
import Button from "components/Button";
import styles from "../../styles.module.scss";

interface IHeaderProps {
  children?: ReactNode;
  className?: string;
  onClose?: () => void;
}

const Header: FC<IHeaderProps> = ({ children, className, onClose }) => (
  <div className={cs(styles.header, className)}>
    {children}
    {onClose && (
      <Button
        className={styles.closeButton}
        onClick={() => onClose()}
        variant="text"
        size="xxl"
      >
        <CloseIcon />
      </Button>
    )}
  </div>
);

export default Header;
