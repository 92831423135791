import { createColumnHelper } from "@tanstack/react-table";
import { Link } from "react-router-dom";
// import { endOfDay, format, parseISO, sub } from "date-fns";
import { EOrderingReports } from "api/useGetStatistics";
import {
  IAPISalesToFrontLineReady,
  // TSalesToFrontLineReady,
} from "api/useGetSales";
import Typography from "components/Typography";
// import apiDateFormat from "constants/apiDateFormat";
import styles from "pages/Home/styles.module.scss";
// import EDaysOnLot from "types/APIDaysOnLot";
import IDateRangeFormatted from "types/IDateRangeFormatted";
import { EMerchandisingReports } from "types/MerchandisingPage";
import ERoutes, { EQueryParameterKeys } from "types/Routes";
import { ISalesFrontLineReady } from "./types";
import { EProviderInitials } from "types/APIProviderRanks";
import { format } from "date-fns";
import { allTime } from "constants/commonDateRanges";
import apiDateFormat from "constants/apiDateFormat";

const columnHelperSalesTrend = createColumnHelper<ISalesFrontLineReady>();

export const getColumnsSalesTrend = (dateRange: IDateRangeFormatted) => [
  columnHelperSalesTrend.accessor("daysOnLot", {
    cell: (info) => (
      <Typography fontSize="text-sm">{info.getValue()}</Typography>
    ),
    header: () => <span>Days on Lot</span>,
  }),
  columnHelperSalesTrend.accessor("flrInStock", {
    cell: (info) => (
      <Typography fontSize="text-sm">{info.getValue()}</Typography>
    ),
    header: () => <span>FLR / in Stock</span>,
  }),
  {
    filterColumnId: EProviderInitials.CarGurus,
    ...columnHelperSalesTrend.accessor("tippable_cargurus", {
      cell: (info) => {

        const { daysOnLot } = info.row.original;
        
        const tempDolFrom = daysOnLot === "60+" ? "61" : daysOnLot.split("-")[0];

        return (
          <Link
            className={styles["withLink"]}
            to={`${ERoutes.Merchandising}?${EQueryParameterKeys.ActiveTable}=${
              EMerchandisingReports.TipReport
            }&${EQueryParameterKeys.Source}=${
              EProviderInitials.CarGurus
            }&${EQueryParameterKeys.EndDate}=${dateRange.endDate}&${
              EQueryParameterKeys.StartDate
            }=${dateRange.startDate}&${
              EQueryParameterKeys.DolRange
            }=${encodeURIComponent(info.row.original.daysOnLot)}&${
              EQueryParameterKeys.dolFrom
            }=${encodeURIComponent(tempDolFrom)}&${
              EQueryParameterKeys.dolTo
            }=${encodeURIComponent(info.row.original.daysOnLot.split("-")[1])
            }`}
          >
            {info.getValue()}
          </Link>
        )
      },
      header: () => (
        <span>
          Tippable <br /> CarGurus
        </span>
      ),
      enableMultiSort: true,
    }),
  },
  {
    filterColumnId: EProviderInitials.Autotrader,
    ...columnHelperSalesTrend.accessor("tippable_autotrader", {
      cell: (info) => {

        const { daysOnLot } = info.row.original;
        
        const tempDolFrom = daysOnLot === "60+" ? "61" : daysOnLot.split("-")[0];

        return (
          <Link
            className={styles["withLink"]}
            to={`${ERoutes.Merchandising}?${EQueryParameterKeys.ActiveTable}=${
              EMerchandisingReports.TipReport
            }&${EQueryParameterKeys.Source}=${
              EProviderInitials.Autotrader
            }&${EQueryParameterKeys.EndDate}=${dateRange.endDate}&${
              EQueryParameterKeys.StartDate
            }=${dateRange.startDate}&${
              EQueryParameterKeys.DolRange
            }=${encodeURIComponent(info.row.original.daysOnLot)}&${
              EQueryParameterKeys.dolFrom
            }=${encodeURIComponent(tempDolFrom)}&${
              EQueryParameterKeys.dolTo
            }=${encodeURIComponent(info.row.original.daysOnLot.split("-")[1])
            }`}
          >
            {info.getValue()}
          </Link>
        )
      },
      header: () => (
        <span>
          Tippable <br /> Autotrader
        </span>
      ),
      enableMultiSort: true,
    }),
  },
  {
    filterColumnId: EProviderInitials.Carfax,
    ...columnHelperSalesTrend.accessor("tippable_carfax", {
      cell: (info) => {

        const { daysOnLot } = info.row.original;
        
        const tempDolFrom = daysOnLot === "60+" ? "61" : daysOnLot.split("-")[0];

        return (
          <Link
            className={styles["withLink"]}
            to={`${ERoutes.Merchandising}?${EQueryParameterKeys.ActiveTable}=${
              EMerchandisingReports.TipReport
            }&${EQueryParameterKeys.Source}=${
              EProviderInitials.Carfax
            }&${EQueryParameterKeys.EndDate}=${dateRange.endDate}&${
              EQueryParameterKeys.StartDate
            }=${dateRange.startDate}&${
              EQueryParameterKeys.DolRange
            }=${encodeURIComponent(info.row.original.daysOnLot)}&${
              EQueryParameterKeys.dolFrom
            }=${encodeURIComponent(tempDolFrom)}&${
              EQueryParameterKeys.dolTo
            }=${encodeURIComponent(info.row.original.daysOnLot.split("-")[1])
            }`}
          >
            {info.getValue()}
          </Link>
        )
      },
      header: () => (
        <span>
          Tippable <br /> Carfax
        </span>
      ),
      enableMultiSort: true,
    }),
  },
  {
    filterColumnId: EProviderInitials.CarsDotCom,
    ...columnHelperSalesTrend.accessor("tippable_cars_com", {
      cell: (info) => {

        const { daysOnLot } = info.row.original;
        
        const tempDolFrom = daysOnLot === "60+" ? "61" : daysOnLot.split("-")[0];

        return (
          <Link
            className={styles["withLink"]}
            to={`${ERoutes.Merchandising}?${EQueryParameterKeys.ActiveTable}=${
              EMerchandisingReports.TipReport
            }&${EQueryParameterKeys.Source}=${
              EProviderInitials.CarsDotCom
            }&${EQueryParameterKeys.EndDate}=${dateRange.endDate}&${
              EQueryParameterKeys.StartDate
            }=${dateRange.startDate}&${
              EQueryParameterKeys.DolRange
            }=${encodeURIComponent(info.row.original.daysOnLot)}&${
              EQueryParameterKeys.dolFrom
            }=${encodeURIComponent(tempDolFrom)}&${
              EQueryParameterKeys.dolTo
            }=${encodeURIComponent(info.row.original.daysOnLot.split("-")[1])
            }`}
          >
            {info.getValue()}
          </Link>
        )
      },
      header: () => (
        <span>
          Tippable <br /> Cars.com
        </span>
      ),
      enableMultiSort: true,
    }),
  },
  columnHelperSalesTrend.accessor("zeroleads", {
    cell: ({ getValue, row }) => {
      const { 
        daysOnLot, 
      } = row.original;
      const tempDaysOnLot = daysOnLot === "60+" ? "61" : daysOnLot;

      return (
        <>
        <Link
          className={styles["withLink"]}
          to={`${ERoutes.Reporting}?${EQueryParameterKeys.ActiveTable}=${
            EOrderingReports.MostLeads
          }&${EQueryParameterKeys.EndDate}=${dateRange.endDate}&${
            EQueryParameterKeys.StartDate
          }=${format(allTime.startDate, apiDateFormat)}&${
            EQueryParameterKeys.DolRange
          }=${encodeURIComponent(tempDaysOnLot)}&${
            EQueryParameterKeys.LeadsZero
          }=${true}`}
        >
          {getValue()}
        </Link>
        </>
      );
    },
    header: () => <span>Zero Leads</span>,
    enableMultiSort: true,
  }),
];

export const getColumnsSalesTrendNewInventory = (dateRange: IDateRangeFormatted) => [
  columnHelperSalesTrend.accessor("daysOnLot", {
    cell: (info) => (
      <Typography fontSize="text-sm">{info.getValue()}</Typography>
    ),
    header: () => <span>Days on Lot</span>,
  }),
  columnHelperSalesTrend.accessor("flrInStock", {
    cell: (info) => (
      <Typography fontSize="text-sm">{info.getValue()}</Typography>
    ),
    header: () => <span>FLR / in Stock</span>,
  }),
  columnHelperSalesTrend.accessor("missing_photo", {
    cell: ({ getValue, row }) => {
      const { daysOnLot } = row.original;
      
      const tempDolRange = daysOnLot.includes("+")
        ? [parseInt(daysOnLot) + 1, 2000]
        : daysOnLot.split("-").map(Number);

      return (
        <Link
          className={styles["withLink"]}
          to={`${ERoutes.VDPResults}?&${EQueryParameterKeys.EndDate}=${
            dateRange.endDate
          }&${EQueryParameterKeys.StartDate}=${
            dateRange.startDate
          }&is_missing_photos=${true}&dol_min=${tempDolRange[0]}&dol_max=${tempDolRange[1]}`}
        >
          {getValue()}
        </Link>
      );
    },
    header: () => <span>Missing Photos</span>,
  }),
  
  columnHelperSalesTrend.accessor("less_than_50_vdps", {
    cell: ({ getValue, row }) => {
      
      const { daysOnLot } = row.original;
      
      const tempDolRange = daysOnLot.includes("+")
        ? [parseInt(daysOnLot) + 1, 2000]
        : daysOnLot.split("-").map(Number);

      return (
        <Link
          className={styles["withLink"]}
          to={`${ERoutes.VDPResults}?&${EQueryParameterKeys.EndDate}=${
            dateRange.endDate
          }&${EQueryParameterKeys.StartDate}=${
            dateRange.startDate
          }&is_less_than_50_vdps=${true}&dol_min=${tempDolRange[0]}&dol_max=${tempDolRange[1]}`}
        >
          {getValue()}
        </Link>
      );
    },
    header: () => <span>Less than 50 VDPs</span>,
  }),
  columnHelperSalesTrend.accessor("zeroleads", {
    cell: ({ getValue, row }) => {
      const {
        daysOnLot,
        // oldestVehicleOnLotDate
      } = row.original;

      const tempDaysOnLot = daysOnLot === "60+" ? "61" : daysOnLot;

      return (
        <Link
          className={styles["withLink"]}
          to={`${ERoutes.Reporting}?${EQueryParameterKeys.ActiveTable}=${
            EOrderingReports.MostLeads
          }&${EQueryParameterKeys.EndDate}=${dateRange.endDate}&${
            EQueryParameterKeys.StartDate
          }=${dateRange.startDate}&${
            EQueryParameterKeys.DolRange
          }=${encodeURIComponent(tempDaysOnLot)}&${
            EQueryParameterKeys.LeadsZero
          }=${true}`}
        >
          {getValue()}
        </Link>
      );
    },
    header: () => <span>Zero Leads</span>,
    enableMultiSort: true,
  }),
];

// export const getSalesTrendFormat = (
//   data: IAPISalesToFrontLineReady | undefined
// ): ISalesFrontLineReady[] => {

//   const getValues = ({ daysOnLot, values }: IGetValuesParam) => {
    
//     const [
//       { quantity = 0 },
//       { tippable_autotrader = 0 },
//       { tippable_cargurus = 0 },
//       // eslint-disable-next-line @typescript-eslint/no-unused-vars
//       { tippable_carfax = 0 },
//       // eslint-disable-next-line @typescript-eslint/no-unused-vars
//       { "tippable_cars.com": tippable_cars_com = 0 },
//       { oldestVehicleOnLotDate = "", zeroleads = 0 },
//       { flr = 0 },
//       { missing_photo = 0 },
//       { less_than_50_vdps = 0 },
//     ] = values[daysOnLot];

//     return {
//       daysOnLot,
//       flrInStock: `${flr} / ${quantity}`,
//       tippableAutotrader: tippable_autotrader,
//       tippableCarGurus: tippable_cargurus,
//       oldestVehicleOnLotDate: oldestVehicleOnLotDate || "",
//       zeroleads: zeroleads,
//       missing_photo: missing_photo,
//       less_than_50_vdps: less_than_50_vdps,
//     };
//   };

//   const salesToFrontLineDefault: TSalesToFrontLineReady = [
//     {
//       quantity: 0,
//     },
//     {
//       tippable_autotrader: 0,
//     },
//     {
//       tippable_cargurus: 0,
//     },
//     {
//       tippable_carfax: 0,
//     },
//     {
//       "tippable_cars.com": 0,
//     },
//     {
//       zeroleads: 0,
//       oldestVehicleOnLotDate: "",
//     },
//     {
//       flr: 0,
//     },
//     {
//       missing_photo: 0,
//     },
//     {
//       less_than_50_vdps: 0,
//     },
//   ];

//   const defaultValues: IAPISalesToFrontLineReady = {
//     "0-15": salesToFrontLineDefault,
//     "16-30": salesToFrontLineDefault,
//     "31-45": salesToFrontLineDefault,
//     "46-60": salesToFrontLineDefault,
//     "60+": salesToFrontLineDefault,
//   };

//   return [
//     getValues({
//       daysOnLot: EDaysOnLot.ZeroToFifteen,
//       values: data || defaultValues,
//     }),
//     getValues({
//       daysOnLot: EDaysOnLot.SixteenToThirty,
//       values: data || defaultValues,
//     }),
//     getValues({
//       daysOnLot: EDaysOnLot.ThirtyOneToFortyFive,
//       values: data || defaultValues,
//     }),
//     getValues({
//       daysOnLot: EDaysOnLot.FortySixToSixty,
//       values: data || defaultValues,
//     }),
//     getValues({
//       daysOnLot: EDaysOnLot.SixtyAbove,
//       values: data || defaultValues,
//     }),
//   ];
// };

//   return [
//     getValues({
//       daysOnLot: EDaysOnLot.ZeroToFifteen,
//       values: data || defaultValues,
//     }),
//     getValues({
//       daysOnLot: EDaysOnLot.SixteenToThirty,
//       values: data || defaultValues,
//     }),
//     getValues({
//       daysOnLot: EDaysOnLot.ThirtyOneToFortyFive,
//       values: data || defaultValues,
//     }),
//     getValues({
//       daysOnLot: EDaysOnLot.FortySixToSixty,
//       values: data || defaultValues,
//     }),
//     getValues({
//       daysOnLot: EDaysOnLot.SixtyAbove,
//       values: data || defaultValues,
//     }),
//   ];
// };

export const getSalesTrendFormatV2 = (
  data: IAPISalesToFrontLineReady | undefined
): ISalesFrontLineReady[] => {
  const transformData = (originalData: any) => {
    let result: ISalesFrontLineReady[] = [];

    for (let key in originalData) {
      let newObj: any = { daysOnLot: key };

      originalData[key].forEach((obj: any) => {
        let prop = Object.keys(obj)[0];
        if (prop === "flr") {
          newObj["flrInStock"] = `${obj[prop]} / ${newObj["quantity"]}`;
        } else if (prop === "tippable_cars.com") {
          console.log("tippable_cars_com", obj[prop]);
        } else {
          newObj[prop] = obj[prop];
        }
      });

      result.push(newObj as ISalesFrontLineReady);
    }

    return result;
  };
  return transformData(data);
};
