import { useCallback, useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";
import DateRange, { IDateRangeProps } from "components/DateRange";
import {
    TActionProps,
    TGeneratePdfFunction,
    TGenerateXLSFunction,
} from "components/Table/components/ActionButtons/types";
import commonDateRanges, { ICommonDateRange, lastWeek } from "constants/commonDateRanges";
import { format, set } from "date-fns";
import apiDateFormat from "constants/apiDateFormat";
import {
    ELocalSavedFilters,
    ITableMarketing,
    getDefaultFilters,
} from "utils/defaultFilters";
import { getColumns, getPdfColumns, getXLSColumns } from "./constants";
import { formattedDateRange, onGeneratePDFDefaultType } from "utils/helper";
import useGetProviders, { EProvidersModules, IAPIGetLeadROI, getProvidersAsync } from "api/useGetProviders";
import { ESortOrder, ISortState } from "components/Table/types";
import formatOrderingAPI from "utils/formatOrderingAPI";
import { Alert, AlertIcon, Box, Radio, RadioGroup, Stack, Text } from "@chakra-ui/react";
import useUrlQuery from "hooks/useUrlQuery";
import { CommonUrlParams } from "utils/urlParams";
import { TInventoryOption, TInventoryOptionValue, inventories } from "constants/common";
import { useAppDispatch, useAppSelector } from "hooks/store";
import { selectUserData, setInventory } from "store/auth/authSlice";
import { ActionButtonV2 } from "components/Table/components/ActionButtons";
import DataTable from "components/v2/DataTable";
import { SortingState } from "@tanstack/react-table";

const LeadROI = () => {

    const userAuth = useAppSelector(selectUserData);

    const dispatch = useAppDispatch();

    const { getParam, setMultipleParams } = useUrlQuery<CommonUrlParams>();

    const startDateParams = getParam("startDate");
    const emdDateParams = getParam("endDate");
    const sortByParams = getParam("sortBy");


    const defaultFilters = getDefaultFilters<ITableMarketing>(
        ELocalSavedFilters.LeadROITable,
        { dateRange: lastWeek }
    );

    const [dateRange, setDateRange] = useState<IDateRangeProps["value"]>(
        {
            startDate: startDateParams ? new Date(startDateParams) : defaultFilters.dateRange.startDate,
            endDate: emdDateParams ? new Date(emdDateParams) : defaultFilters.dateRange.endDate,
        }
    );

    const [sortBy, setSortBy] = useState<SortingState>(sortByParams && typeof sortByParams === 'string' ? [{
        id: sortByParams.replace("-", ""),
        desc: sortByParams.startsWith("-") ? true : false,
    }] : [{ id: 'name', desc: false }]);;

    useEffect(() => {
        setMultipleParams({
            startDate: format(dateRange.startDate, apiDateFormat),
            endDate: format(dateRange.endDate, apiDateFormat),
            inventory: userAuth.inventory.value,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dateRange.startDate, dateRange.endDate, userAuth.inventory.value]);

    const sortedBy: ISortState = useMemo(() => {

        if (!sortBy.length) {
            return {
                column: null,
                order: ESortOrder.None,
            };
        }
        return {
            column: sortBy[0].id as keyof IAPIGetLeadROI,
            order: sortBy[0].desc ? ESortOrder.Descending : ESortOrder.Ascending,
        };
    }, [sortBy]);

    const fetchParameters = useMemo(
        () => ({
            module: EProvidersModules.LeadROI,
            params: {
                page_size: 1000,
                date_from: format(dateRange.startDate, apiDateFormat),
                date_to: format(dateRange.endDate, apiDateFormat),
                dealership_uid: userAuth.dealerV2.uid,
                inventory: userAuth.inventory.value,
                ...(userAuth.inventory.value === 'all' && {
                    inventory: '',
                }) as any,
                ...(formatOrderingAPI<keyof IAPIGetLeadROI>(sortedBy).length > 0 && {
                    ordering: formatOrderingAPI<keyof IAPIGetLeadROI>(sortedBy),
                }),
            },
        }),
        [dateRange.endDate, dateRange.startDate, sortedBy, userAuth.dealerV2.uid, userAuth.inventory.value]
    );

    const { data, isFetching } = useGetProviders<EProvidersModules.LeadROI>(fetchParameters);

    const onSort = useCallback((sort: SortingState) => {
        // if (!sort.length) return;
        setSortBy(sort);
        setMultipleParams({
            sortBy: sort.length > 0 ? `${sort[0].desc ? '-' : ''}${sort[0].id}` : '',
        });
    }, []);

    const activeIndexQuickOption = useMemo(
        () =>
            commonDateRanges.findIndex(
                ({ value: quickOptionValue }) =>
                    format(quickOptionValue.startDate, apiDateFormat) ===
                    format(dateRange.startDate, apiDateFormat) &&
                    format(quickOptionValue.endDate, apiDateFormat) ===
                    format(dateRange.endDate, apiDateFormat)
            ),
        [dateRange.endDate, dateRange.startDate]
    );

    const onGeneratePDF: TActionProps<TGeneratePdfFunction> = useCallback(
        async (onGeneratePDF, setIsLoading) => {
            setIsLoading(true);
            try {
                const { results } = await getProvidersAsync<EProvidersModules.LeadROI>(
                    fetchParameters
                )();

                const pdfColumns = getPdfColumns(userAuth.inventory.value);

                const bodyFormatted = results.map((values, index) => {
                    return pdfColumns.map(
                        ({ func, key }) => {
                            //   if (key === 'rank') {
                            //     return index + 1;
                            //   }
                            return func?.(values[key]) || values[key];
                        }
                    );
                });

                const dateRangeTitle = activeIndexQuickOption !== -1 ? `${commonDateRanges[activeIndexQuickOption].label} (${formattedDateRange(dateRange)})` : formattedDateRange(dateRange);

                onGeneratePDF(
                    {
                        head: [pdfColumns.map(({ label }) => label)],
                        body: bodyFormatted,
                        ...onGeneratePDFDefaultType(`Lead ROI`, dateRangeTitle)
                    },
                    "lead-roi.pdf",
                    {
                        compress: true,
                    }
                );
                setIsLoading(false);
            } catch (err) {
                toast.error("Can't export PDF file. Please contact your admin");
            }
        },
        [activeIndexQuickOption, dateRange, fetchParameters, userAuth.inventory.value]
    );

    const onGenerateXLSX: TActionProps<TGenerateXLSFunction> = useCallback(
        async (generateXLS, setIsLoading) => {
            setIsLoading(true);
            try {
                const { results } = await getProvidersAsync<EProvidersModules.LeadROI>(
                    fetchParameters
                )();

                const bodyFormatted = results.map((values, index) => {
                    return getXLSColumns(userAuth.inventory.value).map(
                        ({ func, key }) => {
                            //   if (key === 'rank') {
                            //     return index + 1;
                            //   }
                            return func?.(values[key], values) || values[key];
                        }
                    );
                }) as (string | number)[][];

                generateXLS(
                    {
                        head: [getXLSColumns(userAuth.inventory.value).map(({ label }) => label)],
                        body: bodyFormatted,
                    },
                    "lead-roi.xls",
                );
                setIsLoading(false);
            } catch (err) {
                toast.error("Can't export XLS file. Please contact your admin");
            }
        },
        [fetchParameters, userAuth.inventory.value]
    );

    let totals: Omit<IAPIGetLeadROI, 'name'> = {
        good_lead_count: 0,
        sold_lead_count: 0,
        total_lead_count: 0,
        website_good_lead_count: 0,
        website_sold_lead_count: 0,
        website_total_lead_count: 0
    }
    if (data && data.results) {

        totals = data!.results.reduce((acc, result) => {
            acc.total_lead_count += result.total_lead_count ?? 0;
            acc.good_lead_count += result.good_lead_count ?? 0;
            acc.sold_lead_count += result.sold_lead_count ?? 0;
            acc.website_total_lead_count += result.website_total_lead_count ?? 0;
            acc.website_good_lead_count += result.website_good_lead_count ?? 0;
            acc.website_sold_lead_count += result.website_sold_lead_count ?? 0;
            return acc;
        }, {
            total_lead_count: 0,
            good_lead_count: 0,
            sold_lead_count: 0,
            website_total_lead_count: 0,
            website_good_lead_count: 0,
            website_sold_lead_count: 0
        });
    }

    const onRadioChange = (nextValue: TInventoryOptionValue) => {
        const selectedInventory: TInventoryOption = inventories.find((inventory) => inventory.value === nextValue) || inventories[0];
        dispatch(setInventory({ inventory: selectedInventory }));
    };

    const filteredDateRanges: ICommonDateRange[] = commonDateRanges.filter(range => {
        return range.label !== "Last 30 Days" && range.label !== "Last 60 Days";
    });

    const filteredInventories = useMemo(() => {
        if (userAuth.dealerV2.new && userAuth.dealerV2.used) {
            return inventories;
        }
        return inventories.filter((inventory) => {
            if (userAuth.dealerV2.used && inventory.value === 'used') {
                return true;
            }
            if (userAuth.dealerV2.new && inventory.value === 'new') {
                return true;
            }
            return false;
        });
    }, [userAuth.dealerV2.new, userAuth.dealerV2.used]);

    const onDateChange = (newValue: [Date, Date]) => {
        // setDateRange({ startDate, endDate });
        setDateRange({
            startDate: set(newValue[0], { hours: 0, minutes: 0, seconds: 0 }),
            endDate: set(newValue[1], { hours: 23, minutes: 59, seconds: 59 }),
        });
    };

    const conttroller = () => {
        return (
            <Box
                display="flex"
                gap="15px"
                justifyContent="space-between"
                flex="1"
                alignItems="center"
            >
                <Box display="flex" alignItems="center" gap="15px" flex="1">
                    <RadioGroup onChange={onRadioChange} value={userAuth.inventory.value}>
                        <Stack direction='row'>
                            {filteredInventories.map((inventory) => (
                                <Radio key={inventory.value} value={inventory.value} colorScheme='red'>
                                    {inventory.label}
                                </Radio>
                            ))}
                        </Stack>
                    </RadioGroup>
                </Box>
                <Box>
                    <ActionButtonV2
                        actionConfig={{
                            isDownloadPDF: true,
                            isDownloadXLS: true,
                            isEmailPDF: true,
                            isEmailXLS: true,
                            isPrintPDF: true,
                        }}
                        onDownloadPDF={onGeneratePDF}
                        onDownloadXLS={onGenerateXLSX}
                        onGeneratePDF={onGeneratePDF}
                    />
                </Box>
            </Box>
        );
    };

    return (
        <>
            <Box display="flex" flexDirection="column" gap="15px">
                <DateRange
                    onChange={onDateChange}
                    quickOptions={filteredDateRanges}
                    value={dateRange}
                />
                <DataTable<IAPIGetLeadROI>
                    data={data?.results || []}
                    rowCount={data?.results.length || 0}
                    // onPageChange={onPageChange}
                    sort={sortBy}
                    onSort={onSort}
                    columns={getColumns(userAuth.inventory.value) as any}
                    pageIndex={1}
                    pageSize={10}
                    isLoading={isFetching}
                    conttroller={conttroller()}
                    columnTotal={{
                        data: [
                            {
                                title: 'Total',
                                value: [`${totals.total_lead_count ?? 0}`, `${totals.good_lead_count ?? 0}`, `${totals.sold_lead_count ?? 0}`]
                            },
                            {
                                title: 'Total Website',
                                value: [`${totals.website_total_lead_count ?? 0}`, `${totals.website_good_lead_count ?? 0}`, `${totals.website_sold_lead_count ?? 0}`]
                            }
                        ]
                    }}
                    // onSearch={setQuickSearch}
                    // pageCount={data?.total_pages}
                    // onColumnVisibilityChange={setColumnVisibility}
                    // columnVisibility={columnVisibility}
                    // onColumnSizingChange={setColumnSizing}
                    // columnSizing={columnSizing}
                    // showColumns={true}
                    // onUpdateData={updateData}
                    // columnsSidebarChildren={columnSidebarChilrend()}
                    showColumns={true}
                    triggerButtonType="text"
                    showPagination={false}
                />
                <Box>
                    {userAuth.dealer.is_vinsolutions && (
                        <Alert status='info' width="max-content" borderRadius="5px">
                            <AlertIcon />
                            <Text fontSize="14px" fontStyle="italic">
                                Sold count is based on Sold from Leads
                            </Text>
                        </Alert>
                    )}
                </Box>
            </Box>
        </>
    );
};
export default LeadROI;
