import FrontLineReadyVehicles from "./components/FrontLineReadyVehicles";
import Tables from "./components/Tables";

const Merchandising = () => {
  return (
    <>
      <FrontLineReadyVehicles />
      <Tables />
    </>
  );
};
export default Merchandising;
