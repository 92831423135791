import { FC, useMemo } from "react";
import cs from "classnames";
import styles from "./styles.module.scss";
import { getResultScoreColorNameNew, getResultScoreColorNameV2 } from "./utils";
import { useAppSelector } from "hooks/store";
import { selectUserData } from "store/auth/authSlice";

interface IResultScoreColorBadgeProps {
  value: number;
}

const ResultScoreColorBadge: FC<IResultScoreColorBadgeProps> = ({ value }) => {
  
  const userAuth = useAppSelector(selectUserData);
  
  const colorPerScore = useMemo(() => {
    if (userAuth.inventory.value === 'used') {
      return getResultScoreColorNameV2(value);
    } else {
      return getResultScoreColorNameNew(value);
    }
  }, [value, userAuth.inventory.value]);

  return (
    <div
      className={cs(
        styles.resultsScoreBadge,
        styles[`resultsScoreBadge--${colorPerScore}`]
      )}
    />
  );
};

export default ResultScoreColorBadge;
