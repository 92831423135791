import { FC } from "react";
import { format } from "date-fns";
import Typography from "components/Typography";
import styles from "./styles.module.scss";

interface ITableDateRangeLabelProps {
  endDate: Date;
  title: string;
}

const TableDateRangeLabel: FC<ITableDateRangeLabelProps> = ({
  endDate,
  title,
}) => {
  return (
    <div className={styles["topSection"]}>
      <Typography fontSize="text-xl" fontWeight="bold">
        {title}
      </Typography>

      <div className={styles["topSection__dateLabel"]}>
        <Typography fontSize="text-sm" fontWeight="bold">
          as of{" "}
          {format(endDate, "MMM d, yyyy")}
        </Typography>
      </div>
    </div>
  );
};

export default TableDateRangeLabel;
