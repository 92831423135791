import { useCallback, useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";
import { format } from "date-fns";
import useGetSales, {
    EModules,
    IAPIPreShootReport,
    TPreShootReportOrderKeys,
    getSalesAsync,
} from "api/useGetSales";
import { PaginationState } from "components/Table";
import { ESortOrder, ISortState } from "components/Table/types";
import TableDateRangeLabel from "components/TableDateRangeLabel";
import {
    TActionProps,
    TGeneratePdfFunction,
    TGenerateXLSFunction,
} from "components/Table/components/ActionButtons/types";
import apiDateFormat from "constants/apiDateFormat";
import { lastWeek as defaultDateRange } from "constants/commonDateRanges";
import { useDebounce } from "hooks";
import {
    ELocalSavedFilters,
    ISavedDefaultFilters,
    getDefaultFilters,
    setDefaultFilters,
    tableFiltersWithProviders,
} from "utils/defaultFilters";
import formatOrderingAPI from "utils/formatOrderingAPI";
import { onGeneratePDFDefaultType } from "utils/helper";
import useUrlQuery from "hooks/useUrlQuery";
import { PreShootReportUrlParams } from "utils/urlParams";
import { useAppSelector } from "hooks/store";
import { selectUserData } from "store/auth/authSlice";
import DataTable from "components/v2/DataTable";
import { Box } from "@chakra-ui/react";
import { ActionButtonV2 } from "components/Table/components/ActionButtons";
import { ColumnSizingState, SortingState, VisibilityState } from "@tanstack/react-table";
import { getColumns, getPdfColumns, getXLSColumns } from "./constants";

type TSavedFilters =
    ISavedDefaultFilters[ELocalSavedFilters.PreShootReportTable];

const PreShootReport = () => {

    const userAuth = useAppSelector(selectUserData);

    const { getParam, setMultipleParams } =
        useUrlQuery<PreShootReportUrlParams>();

    const sortByParams = getParam("sortBy");
    const searchParams = getParam("search");

    const defaultFilters = getDefaultFilters<TSavedFilters>(
        ELocalSavedFilters.PreShootReportTable,
        tableFiltersWithProviders
    );

    const [columnVisibility, setColumnVisibility] = useState<VisibilityState>(defaultFilters.columnVisibility || {});

    const [columnSizing, setColumnSizing] = useState<ColumnSizingState>(defaultFilters.columnSizing || {});

    // const [offset, setOffset] = useState<number>(defaultOffset);
    const [page, setPage] = useState<number>(0);
    const [pageSize, setPageSize] = useState<number>(defaultFilters.pageSize);

    const [sortBy, setSortBy] = useState<SortingState>(sortByParams && typeof sortByParams === 'string' ? [{
        id: sortByParams.replace("-", ""),
        desc: sortByParams.startsWith("-") ? true : false,
    }] : []);

    const [quickSearch, setQuickSearch] = useState<string>(searchParams ?? "");
    const quickSearchApi = useDebounce<string>(quickSearch, 350);

    useEffect(() => {
        setMultipleParams({
            inventory: userAuth.inventory.value,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userAuth.inventory.value]);

    const sortedBy: ISortState = useMemo(() => {

        if (!sortBy.length) {
            return {
                column: null,
                order: ESortOrder.None,
            };
        }
        return {
            column: sortBy[0].id as TPreShootReportOrderKeys,
            order: sortBy[0].desc ? ESortOrder.Descending : ESortOrder.Ascending,
        };
    }, [sortBy]);

    const fetchParameters = useMemo(
        () => ({
            module: EModules.PreShootReportV2,
            params: {
                date_from: format(defaultDateRange.startDate, apiDateFormat),
                date_to: format(defaultDateRange.endDate, apiDateFormat),
                dealership_uid: userAuth.dealerV2.uid,
                // limit: pageSize,
                // offset,
                page: page + 1,
                page_size: pageSize,
                ordering: formatOrderingAPI<TPreShootReportOrderKeys>(sortedBy),
                search: quickSearchApi,
                inventory: userAuth.inventory.value,
            },
        }),
        [page, pageSize, quickSearchApi, sortedBy, userAuth.dealerV2.uid, userAuth.inventory.value]
    );
    const { data, isFetching } =
        useGetSales<EModules.PreShootReportV2>(fetchParameters);

    useEffect(() => {
        setDefaultFilters<TSavedFilters>(ELocalSavedFilters.PreShootReportTable, {
            pageSize,
            columnSizing,
            columnVisibility
        });
    }, [columnSizing, columnVisibility, pageSize]);

    const onGeneratePDF: TActionProps<TGeneratePdfFunction> = useCallback(
        async (onGeneratePDF, setIsLoading) => {
            setIsLoading(true);

            try {
                let hasNextPage = true;
                let collectedResults: IAPIPreShootReport[] = [];
                // let mapOffset = 0;

                let tempPage = 1;

                do {
                    const { next, results } =
                        await getSalesAsync<EModules.PreShootReportV2>({
                            ...fetchParameters,
                            params: {
                                ...fetchParameters.params,
                                page: tempPage
                                // offset: mapOffset 
                            },
                        })();
                    collectedResults = [...collectedResults, ...results];

                    tempPage++;
                    hasNextPage = !!next;
                    // mapOffset = mapOffset + pageSize;
                } while (hasNextPage);

                const bodyFormatted = collectedResults.map((values) => {
                    return getPdfColumns(userAuth.inventory.value).map(
                        ({ func, key }) => func?.(values[key]) || values[key]
                    );
                });

                const dateRangeTitle = `${format(defaultDateRange.endDate, "MMM d, yyyy")}`;

                onGeneratePDF(
                    {
                        head: [getPdfColumns(userAuth.inventory.value).map(({ label }) => label)],
                        body: bodyFormatted,
                        ...onGeneratePDFDefaultType(`Pre Shoot Report`, dateRangeTitle)
                    },
                    "pre-shoot-report.pdf",
                    {
                        compress: true
                    }
                );
                setIsLoading(false);
            } catch (err) {
                toast.error("Can't export PDF file. Please contact your admin");
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [fetchParameters, pageSize]
    );

    const onGenerateXLSX: TActionProps<TGenerateXLSFunction> = useCallback(
        async (generateXLS, setIsLoading) => {
            setIsLoading(true);

            try {
                let hasNextPage = true;
                let collectedResults: IAPIPreShootReport[] = [];
                // let mapOffset = 0;

                let tempPage = 1;

                do {
                    const { next, results } =
                        await getSalesAsync<EModules.PreShootReportV2>({
                            ...fetchParameters,
                            params: {
                                ...fetchParameters.params,
                                page: tempPage
                                // offset: mapOffset 
                            },
                        })();
                    collectedResults = [...collectedResults, ...results];

                    tempPage++;
                    hasNextPage = !!next;
                    // mapOffset = mapOffset + pageSize;
                } while (hasNextPage);

                const bodyFormatted = collectedResults.map((values) => {
                    return getXLSColumns(userAuth.inventory.value).map(
                        ({ func, key }) => func?.(values[key], values) || values[key]
                    );
                });

                generateXLS(
                    {
                        head: [getXLSColumns(userAuth.inventory.value).map(({ label }) => label)],
                        body: bodyFormatted,
                    },
                    "pre-shoot-report.xls",
                );
                setIsLoading(false);
            } catch (err) {
                toast.error("Can't export XLS file. Please contact your admin");
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [fetchParameters, pageSize]
    );

    const onPageChange = useCallback((pagination: PaginationState) => {
        setPage(pagination.pageIndex);
        setPageSize(pagination.pageSize);
    }, []);

    const onSort = useCallback((sort: SortingState) => {
        // if (!sort.length) return;
        setSortBy(sort);
        setMultipleParams({
          sortBy: sort.length > 0 ? `${sort[0].desc ? '-' : ''}${sort[0].id}` : '',
        });
    }, []);

    const onSearch = useCallback((search: string) => {
        setQuickSearch(search);
        setPage(0);
        setMultipleParams({
          search: search,
        });
    }, []);

    const conttroller = () => {
        return (
            <Box
                display="flex"
                gap="15px"
                justifyContent="space-between"
                flex="1"
                alignItems="center"
            >
                <Box display="flex" alignItems="center" gap="15px" flex="1" />
                <Box>
                    <ActionButtonV2
                        actionConfig={{
                            isDownloadPDF: true,
                            isPrintPDF: true,
                            isEmailPDF: true,
                            isDownloadXLS: true,
                            isEmailXLS: true
                        }}
                        onDownloadPDF={onGeneratePDF}
                        onDownloadXLS={onGenerateXLSX}
                        onGeneratePDF={onGeneratePDF}
                    />
                </Box>
            </Box>
        );
    };
    
    return (
        <>
            <TableDateRangeLabel
                endDate={defaultDateRange.endDate}
                title="This Week's Snapshot"
            />
            <DataTable<IAPIPreShootReport>
                data={data?.results || []}
                rowCount={data?.count || 0}
                onPageChange={onPageChange}
                sort={sortBy}
                onSort={onSort}
                columns={getColumns(userAuth.inventory.value) as any}
                pageIndex={page}
                pageSize={pageSize}
                isLoading={isFetching}
                conttroller={conttroller()}
                onSearch={onSearch}
                search={quickSearch}
                pageCount={data?.total_pages || 0}
                onColumnVisibilityChange={setColumnVisibility}
                columnVisibility={columnVisibility}
                onColumnSizingChange={setColumnSizing}
                columnSizing={columnSizing}
                showColumns={true}
                // onUpdateData={updateData}
                // columnsSidebarChildren={columnSidebarChilrend()}
                triggerButtonType="text"
            />
        </>
    );
};

export default PreShootReport;

