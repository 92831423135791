import { EProviderInitials } from "types/APIProviderRanks";

const getProviderName = (provider: EProviderInitials) => {
  switch (provider) {
    case EProviderInitials.Autotrader:
      return "Autotrader.com";

    case EProviderInitials.CarGurus:
      return "CarGurus";

    case EProviderInitials.Carfax:
      return "Carfax";

    case EProviderInitials.CarsDotCom:
      return "Cars.com";

    case EProviderInitials.Vinmanager:
      return "Vinmanager";

    case EProviderInitials.GoogleAnalytics:
      return "Google Analytics";

    default:
      return "Unsupported Provider";
  }
};

export default getProviderName;
