import { format, parseISO } from "date-fns";

const formatDateRangeLabel = (startDate: string, endDate: string): string => {
  const formattedStartDate = parseISO(startDate);
  const formattedEndDate = parseISO(endDate);

  const startMonth = format(formattedStartDate, "MMM");
  const endMonth = format(formattedEndDate, "MMM");
  const startDay = format(formattedStartDate, "dd");
  const endDay = format(formattedEndDate, "dd");

  return `${startMonth} ${startDay}-${
    startMonth !== endMonth ? `${endMonth} ` : ""
  }${endDay}`;
};

export default formatDateRangeLabel;
