import React, { useCallback, forwardRef, ForwardedRef } from "react";
import { Table, flexRender } from "@tanstack/react-table";
import cs from "classnames";
import { ReactComponent as ChevronDownIcon } from "assets/icons/chevron-down.svg";
import { ReactComponent as ChevronSelectVertIcon } from "assets/icons/chevron-selector-vertical.svg";
import { ReactComponent as ChevronUpIcon } from "assets/icons/chevron-up.svg";
import styles from "../../styles.module.scss";
import { ICustomPropsColumn, TSort, ESortOrder } from "../../types";
import ColumnFilter from "../ColumnFilter";

interface ITableHeadProps<T> {
  className?: string;
  sort?: TSort;
  table: Table<T>;
}

const TableHead = <T extends object>(
  { className, sort, table }: ITableHeadProps<T>,
  ref: ForwardedRef<HTMLTableSectionElement>
) => {
  const onSortServer = useCallback(
    (columnId: string) => {
      if (sort && sort?.mode === "server" && sort?.onSort) {
        const newOrder: ESortOrder =
          sort.order === ESortOrder.None || columnId !== sort.column
            ? ESortOrder.Ascending
            : sort.order === ESortOrder.Ascending
            ? ESortOrder.Descending
            : ESortOrder.None;

        sort.onSort({ column: columnId, order: newOrder });
      }
    },
    [sort]
  );

  return (
    <thead {...(className && { className })} ref={ref}>
      {table.getHeaderGroups().map((headerGroup) => (
        <tr key={headerGroup.id} className={styles["table__header"]}>
          {headerGroup.headers.map((header) => (
            <th
              key={header.id}
              id={header.id}
              style={{ width: header.getSize() }}
              className={cs(
                styles["table__header__item"],
                (header.column.columnDef as ICustomPropsColumn<T>)
                  .classNameHeader
              )}
              colSpan={header.colSpan}
            >
              <div className={styles["table__header__title"]}>
                <div className={styles["table-title-flex"]} style={{ flexFlow: 'row'}}>
                    <div className={styles["table__header__title__padding"]}>
                      {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                    </div>
                    {header.column.getCanFilter() ? (
                      <div>
                        <ColumnFilter column={header.column} table={table} inputType="range" />
                      </div>
                    ) : null}
                </div>
                <div
                  {...{
                    className: header.column.getCanSort()
                      ? styles["table__header__item--sortable"]
                      : "",
                    onClick:
                      sort?.mode === "client"
                        ? header.column.getToggleSortingHandler()
                        : () =>
                            header.column.getCanSort() &&
                            onSortServer(header.column.id),
                  }}
                >
                  {{
                    asc: (
                      <ChevronUpIcon
                        className={styles["table__header__item--sortable__icon"]}
                      />
                    ),
                    desc: (
                      <ChevronDownIcon
                        className={styles["table__header__item--sortable__icon"]}
                      />
                    ),
                  }[
                    sort?.mode === "server" && sort.column === header.column.id
                      ? sort.order
                      : (header.column.getIsSorted() as string)
                  ] ??
                    (header.column.getCanSort() && (
                      <ChevronSelectVertIcon
                        className={styles["table__header__item--sortable__icon"]}
                      />
                    ))}
                </div>
              </div>
            </th>
          ))}
        </tr>
      ))}
    </thead>
  );
};

export default forwardRef(TableHead) as <T extends object>(
  props: ITableHeadProps<T> & React.RefAttributes<Element>
) => JSX.Element;
