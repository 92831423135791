import { Range, getTrackBackground } from "react-range";
import styles from "./styles.module.scss";
import Typography from "components/Typography";

type RangePickerProps = {
  min: number;
  max: number;
  values: number[];
  step: number;
  onChange: (values: number[]) => void;
  label: string;
};

const RangePicker: React.FC<RangePickerProps> = ({
  min,
  max,
  values,
  onChange,
  label,
}) => {
  return (
    <div className={styles.rangePickerWrapper}>
      <Typography
        className={styles.rangeLabel}
        fontWeight="semibold"
        fontSize="text-md"
      >
        {label}
      </Typography>
      <div className={styles.rangeContainer}>
        <Range
          values={values}
          step={1}
          min={min}
          max={max}
          onChange={onChange}
          renderTrack={({ props, children }) => (
            <div
              {...props}
              style={{
                ...props.style,
                background: getTrackBackground({
                  values: values,
                  colors: ["#EAECF0", "#3E4784", "#EAECF0"],
                  min: min,
                  max: max,
                }),
              }}
              className={styles.renderTrack}
            >
              {children}
            </div>
          )}
          renderThumb={({ props, isDragged }) => (
            <div
              {...props}
              style={{
                ...props.style,
                boxShadow: isDragged
                  ? "box-shadow: 0px 0px 0px 4px #F4EBFF, 0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10)"
                  : "none",
              }}
              className={styles.renderThumb}
            />
          )}
          renderMark={({ props }) => (
            <div {...props} style={props.style} className={styles.rangeMark} />
          )}
        />
      </div>
      <div className={styles.textInputs}>
        <div
          className={styles["textInputs__input"]}
        >
          <label>Min</label>
          <input
            type="number"
            onChange={({ target }) => {
                const tempValue = Number(target.value);
                onChange([tempValue, values[1]])
            }}
            value={values[0]}
          />
        </div>
        <div
          className={styles["textInputs__input"]}
        >
          <label>Max</label>
          <input
            type="number"
            onChange={({ target }) => {
                const tempValue = Number(target.value);
                onChange([values[0], tempValue])
            }}
            value={values[1]}
          />
        </div>
      </div>
    </div>
  );
};

export default RangePicker;
