import { useCallback, useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import Tabs from "components/Tabs";
import { EMarketingReports } from "types/MarketingPage";
import { EQueryParameterKeys } from "types/Routes";
import styles from "pages/Marketing/styles.module.scss";
import { UserRolesEnum } from "enums/UserRolesEnum";
import { useAppSelector } from "hooks/store";
import { selectUserData } from "store/auth/authSlice";
import VDPsByChannel from "pages/v2/Marketing/VDPsByChannel";
import BudgetROI from "pages/v2/Marketing/BudgetROI";
import TopTenWebsiteSources from "pages/v2/Marketing/TopTenWebsiteSources";
import VDPROI from "pages/v2/Marketing/VDPROI";
import InternalReporting from "pages/v2/Marketing/InternalReporting";
import LeadROI from "pages/v2/Marketing/LeadROI";
import useIsProductionApp from "hooks/useIsProductionApp";

const Tables = () => {

  const isProductionApp = useIsProductionApp();
  
  const [searchParams, setSearchParams] = useSearchParams();

  const userAuth = useAppSelector(selectUserData);
  
  // const tableTabs = [
  //   { id: EMarketingReports.BudgetROI, label: "Budget ROI" },
  //   { id: EMarketingReports.VDPsByChannel, label: "VDPs by Channel" },
  //   {
  //     id: EMarketingReports.TopTenWebsiteSources,
  //     label: "Top 10 Website Sources",
  //   },
  //   {
  //     id: EMarketingReports.VDPROI,
  //     label: "VDP ROI",
  //   },
  //   { id: EMarketingReports.LeadROI, label: "Lead ROI" },
  // ];

  // if (userAuth.roles.includes(UserRolesEnum.SuperAdmin)) {
  //   tableTabs.push({ id: EMarketingReports.InternalReporting, label: "Internal Reporting" });
  // }

  const getTableTabs = useCallback(() => ([
    ...(userAuth.dealerV2.third_party_provider.length > 0 ? [
      { id: EMarketingReports.BudgetROI, label: "Budget ROI" },
      { id: EMarketingReports.VDPsByChannel, label: "VDPs by Channel" },
    ] : []),
    {
      id: EMarketingReports.TopTenWebsiteSources,
      label: "Top 10 Website Sources",
    },
    {
      id: EMarketingReports.VDPROI,
      label: "VDP ROI",
    },
    ...(!isProductionApp ? [
      { id: EMarketingReports.LeadROI, label: "Lead ROI" }
    ] : []),
    ...(userAuth.roles.includes(UserRolesEnum.SuperAdmin) && !isProductionApp ? [
      { id: EMarketingReports.InternalReporting, label: "Internal Reporting" }
    ] : [])
  ]), [userAuth.dealerV2.third_party_provider, userAuth.roles, isProductionApp]);

  const onChangeActiveTable = useCallback(
    (_: number, newActiveTableId?: string | number) =>
      setSearchParams({
        [EQueryParameterKeys.ActiveTable]:
          newActiveTableId as EMarketingReports,
      }),
    [setSearchParams]
  );

  const activeTableId = useMemo(
    () => searchParams.get(EQueryParameterKeys.ActiveTable),
    [searchParams]
  );

  const activeTableIndex = useMemo(
    () => getTableTabs().findIndex((tableTab) => tableTab.id === activeTableId),
    [activeTableId]
  );

  return (
    <div className={styles.tables}>
      <Tabs
        className={styles["tables__tabOptions"]}
        active={activeTableIndex}
        tabs={getTableTabs()}
        onClickTab={onChangeActiveTable}
      />
      {activeTableId === EMarketingReports.VDPsByChannel && (
        <div className={styles["tables__tab--vdpByChannel"]}>
          <VDPsByChannel />
        </div>
      )}
      {activeTableId === EMarketingReports.BudgetROI && (
        <div className={styles["tables__tab--vdpByChannel"]}>
          <BudgetROI />
        </div>
      )}
      {activeTableId === EMarketingReports.TopTenWebsiteSources && (
        <div className={styles["tables__tab--vdpByChannel"]}>
          <TopTenWebsiteSources />
        </div>
      )}
      {activeTableId === EMarketingReports.VDPROI && (
        <div className={styles["tables__tab--vdpROI"]}>
          <VDPROI />
        </div>
      )}
      {activeTableId === EMarketingReports.InternalReporting && userAuth.roles.includes(UserRolesEnum.SuperAdmin) && (
        <div className={styles["tables__tab--vdpROI"]}>
          <InternalReporting />
        </div>
      )}
      {activeTableId === EMarketingReports.LeadROI && (
        <div className={styles["tables__tab--vdpROI"]}>
          <LeadROI />
        </div>
      )}
    </div>
  );
};
export default Tables;
