import { FC, ReactNode } from "react";
import cs from "classnames";
import styles from "../../styles.module.scss";

interface IBodyProps {
  children?: ReactNode;
  className?: string;
}

const Body: FC<IBodyProps> = ({ children, className }) => (
  <div className={cs(styles.body, className)}>{children}</div>
);

export default Body;
