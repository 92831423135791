import { FC, ReactNode } from "react";
import { ReactComponent as LoadingCircle } from "assets/icons/loading-circle.svg";
import cs from "classnames";
import styles from "./styles.module.scss";
import Typography from "components/Typography";

interface ILoadingSpinnerProps {
  children?: ReactNode;
  className?: string;
  isLoading: boolean;
}

const LoadingSpinner: FC<ILoadingSpinnerProps> = ({
  children,
  className,
  isLoading,
}) => {

  return (
    <div
      className={cs(
        styles.loadingContainer,
        {
          [styles["loadingContainer--isLoading"]]: isLoading,
        },
        className
      )}
    >
      {children}
      {isLoading && (
        <div className={styles.content}>
          <LoadingCircle className={styles.content__icon} />
          <Typography
            className={styles.content__caption}
            fontSize="text-sm"
            fontWeight="medium"
          >
            Loading...
          </Typography>
        </div>
      )}
    </div>
  );
};

export default LoadingSpinner;
