import { configureStore } from "@reduxjs/toolkit";
import authSlice from "./auth/authSlice";
import resultScoreSlice from "./resultScore/resultScoreSlice";
// import { needAttentionApi } from "api/needAttentionApi";
import { api } from "api/api";
import dealershipSlice from "./dealership/dealershipSlice";
const store = configureStore({
    reducer: {
        auth: authSlice,
        resultScore: resultScoreSlice,
        dealerships: dealershipSlice,
        [api.reducerPath]: api.reducer,
    },
    middleware: (getDefaultMiddleware) => 
        getDefaultMiddleware().concat(
            api.middleware,
        )
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch


export default store;