import { useMemo } from "react";
import { createColumnHelper } from "@tanstack/react-table";
import Table from "components/Table";
import Typography from "components/Typography";
import styles from "pages/Home/styles.module.scss";
import autoFormatNumber from "utils/autoFormatNumber";
import useGetSales, { EModules } from "api/useGetSales";
import { useAppSelector } from "hooks/store";
import { selectUserData } from "store/auth/authSlice";

interface ITopVDP {
  stockNumber: string;
  vdpCount: number;
  vehicle: string;
}

const columnHelperTopVDP = createColumnHelper<ITopVDP>();
const columnsTopVDP = [
  columnHelperTopVDP.accessor("stockNumber", {
    cell: (info) => info.getValue(),
    header: () => <span>Stock #</span>,
    size: 48,
  }),
  columnHelperTopVDP.accessor("vehicle", {
    id: "year",
    cell: (info) => info.getValue(),
    header: () => <span>Vehicle</span>,
    size: 233,
  }),
  columnHelperTopVDP.accessor("vdpCount", {
    cell: (info) => autoFormatNumber(info.getValue()),
    header: () => <span>VDP Count</span>,
    size: 83,
  }),
];

const TopVehiclesVDPTable = () => {

  const userAuth = useAppSelector(selectUserData);
  
  const { data, isFetching } = useGetSales<EModules.VDPSByVehicle>({
    module: EModules.VDPSByVehicle,
    params: {
      dealership_uid: userAuth.dealerV2.uid,
      limit: 5,
      inventory: userAuth.inventory.value,
    },
    enabled: userAuth.inventory.value === "all" ? false : true,
  });

  const formattedData: ITopVDP[] = useMemo(
    () =>
      Array.isArray(data)
        ? data.map((vehicle) => ({
            stockNumber: vehicle.stock_number,
            vdpCount: vehicle.total_vdps,
            vehicle: `${vehicle.vehicle_year} ${vehicle.vehicle_make} ${vehicle.vehicle_model} ${vehicle.vehicle_trim}`,
          }))
        : [],
    [data]
  );

  return (
    <div className={styles.topVehicle}>
      <Typography
        className={styles["topVehicle__label"]}
        fontSize="text-lg"
        fontWeight="bold"
      >
        Top Vehicle VDP's
      </Typography>
      <Table
        data={formattedData}
        columns={columnsTopVDP}
        isLoading={isFetching}
        showPagination={false}
      />
    </div>
  );
};

export default TopVehiclesVDPTable;
