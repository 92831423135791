import { Fragment } from "react";
import cs from "classnames";
import Typography from "components/Typography";
import styles from "pages/Home/styles.module.scss";
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Text,
} from "@chakra-ui/react";
import React from "react";

const resultsScoresLegendItems = [
  {
    color: "green",
    info: "The Stock Numbers represented in this segment are within acceptable parameters of overall views, sold leads, and current comparative pricing models to reach goals. Green vehicles are going to sell faster, and you should not overspend on marketing for these units.",
  },
  {
    color: "yellow",
    info: "The Stock Numbers represented in this segment are performing at a sub-optimum level and adjustments should be made to one or more action items. ",
  },
  {
    color: "red",
    info: "The Stock Numbers represented in this segment are performing poorly on multiple levels. Critical actions should be taken as soon as possible to address all factors affecting VDP views and effectiveness. Red vehicles are often not receiving enough VDP activity to sell quickly.",
  },
];

export const ResultScoresLegend = () => {

  return (
    <>
      <Accordion
        allowToggle={true}
        defaultIndex={0}
        borderColor="gray.200"
      >
        <AccordionItem
          borderWidth="1px !important"
        >
          <AccordionButton
            borderColor="inherit"
            borderBottom="1px"
            borderBottomColor="gray.200"
          >
            <Box as="span" flex="1" textAlign="left">
              <Text fontSize="xl" fontWeight={600}>
                What are Result Scores?
              </Text>
            </Box>
            <AccordionIcon />
          </AccordionButton>
          <AccordionPanel>
            <Text fontSize={14} paddingBottom={10}>
              Result Scores are VinDrives' proprietary analysis and grading of
              actionable Key Performance Indicators (KPIs) and Critical Success
              Factors (CSFs) of the dealership's VDP views and subsequent
              actions as measured against the dealership's potential sales
              metrics and goals.
            </Text>
            <div className={styles["resultScoresLegend__items"]}>
              {resultsScoresLegendItems.map(({ color, info }, index) => (
                <Fragment key={index}>
                  <div className={styles["resultScoresLegend__items__legend"]}>
                    <Typography
                      fontSize="text-md"
                      fontWeight="semibold"
                      className={cs(
                        styles["resultScoresLegend__items__legend__label"],
                        styles[
                          `resultScoresLegend__items__legend__label--${color}`
                        ]
                      )}
                    >
                      Results Score {color}
                    </Typography>
                    <Typography
                      fontSize="text-sm"
                      className={
                        styles["resultScoresLegend__items__legend__info"]
                      }
                    >
                      {info}
                    </Typography>
                  </div>
                  {index + 1 !== resultsScoresLegendItems.length && (
                    <div
                      className={styles["resultScoresLegend__items__divider"]}
                    />
                  )}
                </Fragment>
              ))}
            </div>
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </>
  );
};


// const ResultScoresLegend = () => (
//   <div className={styles["resultScoresLegend"]}>
//     <Typography
//       fontSize="text-xl"
//       fontWeight="semibold"
//       className={styles["resultScoresLegend__title"]}
//     >
//       What are Result Scores?
//     </Typography>
//     <Typography
//       fontSize="text-sm"
//       className={styles["resultScoresLegend__description"]}
//     >
//       Result Scores are VinDrives' proprietary analysis and grading of
//       actionable Key Performance Indicators (KPIs) and Critical Success Factors
//       (CSFs) of the dealership's VDP views and subsequent actions as measured
//       against the dealership's potential sales metrics and goals.
//     </Typography>

//     <div className={styles["resultScoresLegend__items"]}>
//       {resultsScoresLegendItems.map(({ color, info }, index) => (
//         <Fragment key={index}>
//           <div className={styles["resultScoresLegend__items__legend"]}>
//             <Typography
//               fontSize="text-md"
//               fontWeight="semibold"
//               className={cs(
//                 styles["resultScoresLegend__items__legend__label"],
//                 styles[`resultScoresLegend__items__legend__label--${color}`]
//               )}
//             >
//               Results Score {color}
//             </Typography>
//             <Typography
//               fontSize="text-sm"
//               className={styles["resultScoresLegend__items__legend__info"]}
//             >
//               {info}
//             </Typography>
//           </div>
//           {index + 1 !== resultsScoresLegendItems.length && (
//             <div className={styles["resultScoresLegend__items__divider"]} />
//           )}
//         </Fragment>
//       ))}
//     </div>
//   </div>
// );

export default ResultScoresLegend;