import { Box } from "@chakra-ui/react";
import Button from "components/Button";
import { FC, useMemo, useState } from "react";

interface SeeMoreTextProps {
  className?: string;
  maxLength: number;
  text: string;
}

const SeeMoreText: FC<SeeMoreTextProps> = ({ className, maxLength, text }) => {
  const [expanded, setExpanded] = useState(false);
  const displayText = useMemo(
    () => (expanded ? text : `${text.slice(0, maxLength).trim()}...`),
    [expanded, maxLength, text]
  );

  const toggleExpanded = () => {
    setExpanded(!expanded);
  };

  return (
    <Box 
      className={className}
      wordBreak="break-all"
      whiteSpace="normal"
    >
      {displayText}
      <span
        style={{ 
          cursor: "pointer",
          fontWeight: 600
        }}
        onClick={toggleExpanded}
      >
        {expanded ? " [See less]" : " [See more]"}
      </span>
      {/* {text.length > maxLength && (
        <Button variant="text" onClick={toggleExpanded}>
          {expanded ? " [See less]" : " [See more]"}
        </Button>
      )} */}
    </Box>
  );
};

export default SeeMoreText;
