import { FC, ReactNode } from "react";
import { Navigate } from "react-router-dom";
import SidebarHeader from "../layouts/SidebarHeader";
import ERoutes from "../types/Routes";
import { useAppSelector } from "hooks/store";
import { selectIsAuthenticated, selectUserData } from "store/auth/authSlice";
import { useGetDealershipQuery } from "api/dealershipApi";

interface IProps {
  children: ReactNode;
}

const ProtectedRoutes: FC<IProps> = ({ children }) => {

  const userAuth = useAppSelector(selectUserData);

  const isAuthenticated = useAppSelector(selectIsAuthenticated);

  useGetDealershipQuery('dealership', {
    pollingInterval: 10000, // Set polling interval to 5000ms (5 seconds)
  });

  // console.log('data:', data);
    
  return isAuthenticated ? (
    <SidebarHeader>{userAuth.dealerV2?.uid ? children : null}</SidebarHeader>
  ) : (
    <Navigate to={ERoutes.Login} replace />
  );
};

export default ProtectedRoutes;
