import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TDealer } from "api/dealershipApi";
import { RootState } from "store";

type DealershipState = {
    dealerships: TDealer[];
};

// Define the initial state using that type
const initialState: DealershipState = {
    dealerships: [],
};

const dealershipSlice = createSlice({
  name: "dealershipSlice",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setDealerships: (state, action: PayloadAction<Pick<DealershipState, 'dealerships'>>) => {
        state.dealerships = action.payload.dealerships
    }
  },
});

export const { setDealerships } = dealershipSlice.actions;

export const selectDealershipsData = (state: RootState) => state.dealerships;

export default dealershipSlice.reducer;
