import { FC, MouseEventHandler, useCallback, useState } from "react";
import { Range as ReactRange, getTrackBackground } from "react-range";
import { ReactComponent as ChevronDownIcon } from "assets/icons/chevron-down.svg";
import { ReactComponent as MinusCircleIcon } from "assets/icons/minus-circle.svg";
import { ReactComponent as PlusCircleIcon } from "assets/icons/plus-circle.svg";
import { ReactComponent as SettingsIcon } from "assets/icons/settings.svg";
import Button from "components/Button";
import Typography from "components/Typography";
import useClickOutside from "hooks/useClickOutside";
import styles from "../../styles.module.scss";
import { ICustomizeFiltersProps, IRangeFilterProps } from "./types";

const CustomizeFilters: FC<ICustomizeFiltersProps> = ({ checkList, range, disabled }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const ref = useClickOutside<HTMLDivElement>(() => setIsOpen(false));

  const toggleMenu = useCallback(
    () => setIsOpen((prevState) => !prevState),
    []
  );

  const onClickCheckListOption: MouseEventHandler<HTMLDivElement> = useCallback(
    ({ currentTarget }) => {
      if (checkList) {
        const newValue = checkList.options.map((checkListOption) => ({
          ...checkListOption,
          value:
            currentTarget.id === checkListOption.id
              ? !checkListOption.value
              : checkListOption.value,
        }));

        checkList.onChange(newValue);
      }
    },
    [checkList]
  );

  return (
    <div ref={ref} className={styles.customizeFilters}>
      <Button size="sm" variant="text" onClick={toggleMenu} disabled={disabled}>
        <SettingsIcon /> Customize <ChevronDownIcon />
      </Button>
      {isOpen && (
        <div className={styles["customizeFilters__menu"]}>
          {range && (
            <>
              <div className={styles["customizeFilters__menu__range"]}>
                <Typography
                  className={styles["customizeFilters__menu__range__label"]}
                  fontWeight="semibold"
                  fontSize="text-md"
                >
                  {range.label}
                </Typography>
                <div
                  className={styles["customizeFilters__menu__range__slider"]}
                >
                  <ReactRange
                    draggableTrack
                    step={1}
                    min={range.min || 0}
                    max={range.max || 10}
                    values={range.value}
                    onChange={(values) =>
                      range.onChange(values as IRangeFilterProps["value"])
                    }
                    renderTrack={({ props, children }) => (
                      <div
                        onMouseDown={props.onMouseDown}
                        onTouchStart={props.onTouchStart}
                        style={{
                          ...props.style,
                          display: "flex",
                          width: "100%",
                          borderRadius: "4px",
                          background: getTrackBackground({
                            values: range.value,
                            colors: ["#EAECF0", "#3E4784", "#EAECF0"],
                            min: range.min || 0,
                            max: range.max || 100,
                          }),
                        }}
                      >
                        <div
                          ref={props.ref}
                          style={{
                            height: "8px",
                            width: "100%",
                            alignSelf: "center",
                          }}
                        >
                          {children}
                        </div>
                      </div>
                    )}
                    renderThumb={({ props, isDragged }) => (
                      <div
                        {...props}
                        className={
                          styles["customizeFilters__menu__range__slider__thumb"]
                        }
                        style={{
                          ...props.style,
                          boxShadow: isDragged
                            ? "box-shadow: 0px 0px 0px 4px #F4EBFF, 0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10)"
                            : "none",
                        }}
                      />
                    )}
                  />
                </div>
                <div
                  className={
                    styles["customizeFilters__menu__range__textInputs"]
                  }
                >
                  <div
                    className={
                      styles["customizeFilters__menu__range__textInputs__input"]
                    }
                  >
                    <label>Minimum</label>
                    <input
                      type="number"
                      onChange={({ target }) =>
                        range.onChange([Number(target.value), range.value[1]])
                      }
                      value={range.value[0]}
                    />
                  </div>
                  <div
                    className={
                      styles["customizeFilters__menu__range__textInputs__input"]
                    }
                  >
                    <label>Maximum</label>
                    <input
                      type="number"
                      onChange={({ target }) =>
                        range.onChange([range.value[0], Number(target.value)])
                      }
                      value={range.value[1]}
                    />
                  </div>
                </div>
              </div>
              <div className={styles["customizeFilters__menu__divider"]} />
            </>
          )}

          {checkList &&
            checkList.options.map((checkListOption) => (
              <div
                key={checkListOption.id}
                id={checkListOption.id}
                onClick={onClickCheckListOption}
                className={styles["customizeFilters__menu__option"]}
              >
                <Typography fontSize="text-md" fontWeight="semibold">
                  {checkListOption.label}
                </Typography>

                {checkListOption.value ? (
                  <MinusCircleIcon
                    className={styles["customizeFilters__menu__option__icon"]}
                  />
                ) : (
                  <PlusCircleIcon
                    className={styles["customizeFilters__menu__option__icon"]}
                  />
                )}
              </div>
            ))}
        </div>
      )}
    </div>
  );
};
export default CustomizeFilters;
