// todo: Move this to API folder and convert to react-query

import ELocalStorageKeys from "types/LocalStorageKey";

export interface IChangePasswordParam {
  email: string;
  oneTimePassword: string;
  password: string;
}

interface IForgotPasswordParam {
  email: string;
}

interface ILoginUserParam {
  email: string;
  password: string;
  recaptchaToken: string | null;
}

interface IRegisterUserParam {
  firstName: string;
  lastName: string;
  password: string;
  token: string;
}

interface IVerifyTokenParam {
  email: string;
  token: string;
}

const commonHeaders = {};

const commonPostsHeaders = {
  "Content-Type": "application/json",
};

const useAuthApi = () => {
  const changePassword = (body: IChangePasswordParam) => {

    const url = `${process.env.REACT_APP_API_URL}api/v2/users/confirm_forgot_password`;

    return fetch(url, {
      method: "POST",
      headers: { ...commonHeaders, ...commonPostsHeaders },
      body: JSON.stringify({
        email: body.email,
        code: body.oneTimePassword,
        password: body.password,
      }),
    });

    // const url = `${process.env.REACT_APP_COGNITO_AUTH_API_URL || ""
    //   }cognitoconfirmforgotpassword`;

    // return fetch(url, {
    //   method: "POST",
    //   headers: { ...commonHeaders, ...commonPostsHeaders },
    //   body: JSON.stringify({
    //     EmailAddress: body.email,
    //     Code: body.oneTimePassword,
    //     Password: body.password,
    //   }),
    // });
  };

  const forgotPassword = (body: IForgotPasswordParam) => {

    const url = `${process.env.REACT_APP_API_URL}api/v2/users/forgot_password`;

    return fetch(url, {
      method: "POST",
      headers: { ...commonHeaders, ...commonPostsHeaders },
      body: JSON.stringify({
        email: body.email,
      }),
    });

    // const url = `${process.env.REACT_APP_COGNITO_AUTH_API_URL || ""
    //   }cognitoforgotpassword`;

    // return fetch(url, {
    //   method: "POST",
    //   headers: { ...commonHeaders, ...commonPostsHeaders },
    //   body: JSON.stringify({
    //     EmailAddress: body.email,
    //   }),
    // });
  };

  const loginUser = (body: ILoginUserParam) => {

    const url = `${process.env.REACT_APP_API_URL}api/v2/users/login`;

    return fetch(url, {
      method: "POST",
      headers: { ...commonHeaders, ...commonPostsHeaders },
      body: JSON.stringify({
        email_address: body.email,
        password: body.password,
        recaptcha_token: body.recaptchaToken,
      }),
    });

    // const url = `${process.env.REACT_APP_COGNITO_AUTH_API_URL || ""
    //   }cognitologinuser`;
    // return fetch(url, {
    //   method: "POST",
    //   headers: { ...commonHeaders, ...commonPostsHeaders },
    //   body: JSON.stringify({
    //     EmailAddress: body.email,
    //     Password: body.password,
    //     RecaptchaToken: body.recaptchaToken,
    //   }),
    // });
  };

  const registerUser = (body: IRegisterUserParam) => {
    const urlDjango = `${process.env.REACT_APP_API_URL || ""
      }api/account/complete`;

    return fetch(urlDjango, {
      method: "POST",
      headers: { ...commonHeaders, ...commonPostsHeaders },
      body: JSON.stringify({
        token: body.token,
        first_name: body.firstName,
        last_name: body.lastName,
        password: body.password,
      }),
    });
  };

  const verifyToken = (body: IVerifyTokenParam) => {

    const url = `${process.env.REACT_APP_API_URL}api/v2/users/verify_token`;

    return fetch(url, {
      method: "POST",
      headers: { 
        ...commonHeaders, 
        ...commonPostsHeaders,
        Authorization: `Bearer ${localStorage.getItem(ELocalStorageKeys.AccessToken)}`,
      },
      body: JSON.stringify({
        email_address: body.email,
        token: body.token,
      }),
    });

    // const url = `${process.env.REACT_APP_COGNITO_AUTH_API_URL || ""
    //   }cognitoverifytoken`;

    // return fetch(url, {
    //   method: "POST",
    //   headers: { ...commonHeaders, ...commonPostsHeaders },
    //   body: JSON.stringify({
    //     EmailAddress: body.email,
    //     Token: body.token,
    //   }),
    // });
  };
  const logoutUser = () => {

    const url = `${process.env.REACT_APP_API_URL}api/v2/users/logout`;
    return fetch(url, {
      method: "POST",
      headers: {
        ...commonHeaders,
        ...commonPostsHeaders,
        Authorization: `Bearer ${localStorage.getItem(ELocalStorageKeys.AccessToken)}`,
      },
    });

    // const url = `${process.env.REACT_APP_COGNITO_AUTH_API_URL || ""
    //   }cognitologoutuser`;
    // return fetch(url, {
    //   method: "GET",
    //   headers: {
    //     ...commonHeaders,
    //     ...commonPostsHeaders,
    //     Authorization: `Bearer ${localStorage.getItem(ELocalStorageKeys.AccessToken) || ""
    //       }`,
    //   },
    // });
  };

  return {
    changePassword,
    forgotPassword,
    loginUser,
    registerUser,
    verifyToken,
    logoutUser,
  };
};

export default useAuthApi;
