import {
  FC,
  forwardRef,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import DatePicker from "react-datepicker";
import { format } from "date-fns";
import ButtonGroup from "components/ButtonGroup";
import Typography from "components/Typography";
import Button from "components/Button";
import apiDateFormat from "constants/apiDateFormat";
import { IDateRange } from "constants/commonDateRanges";
import useScreenSize from "hooks/useScreenSize";
import styles from "./styles.module.scss";

interface ICustomDateRange {
  label: string;
  value: IDateRange;
}

export interface IDateRangeProps {
  onChange: (newValue: [Date, Date]) => void;
  quickOptions: ICustomDateRange[];
  value: IDateRange;
}

interface ICustomInputProps {
  isActive: boolean;
  onClick?: () => void;
}

const CustomInput = forwardRef<HTMLButtonElement, ICustomInputProps>(
  ({ isActive, onClick }, ref) => (
    <Button
      variant={isActive ? "primary" : "outline"}
      onClick={onClick}
      ref={ref}
    >
      Custom Dates
    </Button>
  )
);

const DateRange: FC<IDateRangeProps> = ({ onChange, quickOptions, value }) => {
  const [customDates, setCustomDates] = useState<IDateRange>({
    endDate: value.endDate,
    startDate: value.startDate,
  });
  const screenSize = useScreenSize();
  const isMobileView = useMemo(
    () => screenSize !== "2xl" && screenSize !== "xl",
    [screenSize]
  );

  useEffect(() => {
    setCustomDates({
      endDate: value.endDate,
      startDate: value.startDate,
    });
  }, [value.endDate, value.startDate]);

  const onChangeDate = useCallback(
    ([startDate, endDate]: [Date, Date]) => {
      setCustomDates({
        endDate,
        startDate,
      });

      if (endDate && startDate) {
        onChange([startDate, endDate]);
      }
    },
    [onChange]
  );

  const onClickQuickOptions = useCallback(
    (_: number, newValue: IDateRange) =>
      onChange([newValue.startDate, newValue.endDate]),
    [onChange]
  );

  const labelSection = () => (
    <div className={styles["dateRange__labelSection"]}>
      <Typography fontSize="text-sm">
        {activeIndexQuickOption !== -1
          ? quickOptions[activeIndexQuickOption].label
          : "Custom Dates"}
      </Typography>
      <Typography fontSize="text-sm" fontWeight="bold">
        {format(value.startDate, "MMM d, yyyy")} -{" "}
        {format(value.endDate, "MMM d, yyyy")}
      </Typography>
    </div>
  );

  const activeIndexQuickOption = useMemo(
    () =>
      quickOptions.findIndex(
        ({ value: quickOptionValue }) =>
          format(quickOptionValue.startDate, apiDateFormat) ===
            format(value.startDate, apiDateFormat) &&
          format(quickOptionValue.endDate, apiDateFormat) ===
            format(value.endDate, apiDateFormat)
      ),
    [quickOptions, value.endDate, value.startDate]
  );

  return (
    <div className={styles["dateRange"]}>
      <div className={styles["dateRange__leftSection"]}>
        <Typography
          className={styles["dateRange__leftSection__label"]}
          fontSize="text-md"
          fontWeight="medium"
        >
          Date Range
        </Typography>
        <div className={styles["dateRange__leftSection__options"]}>
          <ButtonGroup
            activeButton={activeIndexQuickOption}
            className={styles["dateRange__leftSection__options__quickRange"]}
            items={quickOptions}
            onClick={onClickQuickOptions}
          />
          {isMobileView && labelSection()}

          <DatePicker
            customInput={
              <CustomInput isActive={activeIndexQuickOption === -1} />
            }
            wrapperClassName={
              styles["dateRange__leftSection__options__customRange"]
            }
            selected={customDates.startDate}
            startDate={customDates.startDate}
            endDate={customDates.endDate}
            onChange={onChangeDate}
            maxDate={new Date()}
            selectsRange
          />
        </div>
      </div>
      {!isMobileView && labelSection()}
    </div>
  );
};

export default DateRange;
