import { useMutation } from "@tanstack/react-query";
import { IDealership, TInventory } from "types/APIParameters";
import { EProviderInitials } from "types/APIProviderRanks";
import fetchUtil from "utils/fetchData";

export enum EModules {
  AddFrontLineReady = "sales/add-front-line-ready",
  AddSalesGoal = "sales/add-sales-goal",
  AddSalesPace = "sales/add-sales-pace",
  AddUpdateBudget = "sales/add-update-budget",
  AddSalesGoalV2 = "v2/sales/result-summary/sales-goal",
  SetBudgetROI = "v2/sales/set-budget",
}

interface ISetBudgetROIForm extends IParams, TInventory {
  budget: number;
  source: EProviderInitials;
}

interface IParams {
  dealership_uid: string;
}

interface IAddUpdateBudgetForm {
  budget: number;
  source: EProviderInitials;
}

interface IAddSalesPaceForm {
  sales_pace: number;
}

interface IAddSalesGoalForm {
  goal: number;
}

interface IAddSalesGoalV2Form extends TInventory, IDealership { 
  goal: number;
}

interface IAddFrontLineReadyForm {
  front_line_ready: number;
}

type TFetchForm<T> = T extends EModules.AddUpdateBudget
  ? IAddUpdateBudgetForm
  : T extends EModules.AddSalesPace
  ? IAddSalesPaceForm
  : T extends EModules.AddSalesGoal
  ? IAddSalesGoalForm
  : T extends EModules.AddFrontLineReady
  ? IAddFrontLineReadyForm
  : T extends EModules.AddSalesGoalV2
  ? IAddSalesGoalV2Form
  : T extends EModules.SetBudgetROI
  ? ISetBudgetROIForm
  : {};

interface IOptions {
  module: EModules;
  params?: IParams;
}

const usePostSales = <T extends IOptions["module"]>({
  module,
  params,
}: IOptions) =>
  useMutation((form: TFetchForm<T>) => {
    return fetchUtil({
      body: form,
      isAuth: true,
      method: "POST",
      params,
      url: `${process.env.REACT_APP_API_URL || ""}api${
        module ? `/${module}` : ""
      }`,
    })();
  });

export default usePostSales;
