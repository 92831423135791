import { Box, Button, IconButton, Popover, PopoverArrow, PopoverBody, PopoverContent, PopoverTrigger } from "@chakra-ui/react";
import { TDealer } from "api/dealershipApi";
import DropdownMenu from "components/v2/DropdownMenu";
import { inventories, TInventoryOption } from "constants/common";
import { useAppDispatch, useAppSelector } from "hooks/store";
import { Dispatch, SetStateAction, useEffect, useMemo } from "react";
import { MdMenu } from "react-icons/md";
import { logoutUser, selectUserData, setDealerV2, setInventory } from "store/auth/authSlice";
import { selectDealershipsData } from "store/dealership/dealershipSlice";
import { ELocalSavedFilters, getDefaultFilters, ISavedDefaultFilters } from "utils/defaultFilters";
import { TbLogout } from "react-icons/tb";
import { useLocation } from "react-router-dom";
import useScreenSize from "hooks/useScreenSize";
import { ChevronDownIcon } from "@chakra-ui/icons";

type HeaderProps = {
    isOpen: boolean;
    pageTitle: string;
    setIsSidebarClose: Dispatch<SetStateAction<boolean>>;
};

type TSavedFilter = ISavedDefaultFilters[ELocalSavedFilters.SelectedDealerHeaderV2];

type TDealerV2 = TDealer & { label: string; value: string };

const Header = (props: HeaderProps) => {

    const { pathname } = useLocation();
    
    const isMarketingPage = pathname === '/marketing-results'; 

    const dispatch = useAppDispatch();

    const userAuth = useAppSelector(selectUserData);

    const { dealerships } = useAppSelector(selectDealershipsData);

    const screenSize = useScreenSize();
    
    const isMobileView = useMemo(
        () => screenSize !== "2xl" && screenSize !== "xl",
        [screenSize]
    );

    const dealers: TDealerV2[] = dealerships.map((dealer) => {
        return {
            ...dealer,
            label: dealer.name,
            value: dealer.uid
        };
    }).sort((a, b) => a.name.localeCompare(b.name));

    useEffect(() => {
        
        if (dealerships.length > 0) {
            const defaultDealer = getDefaultFilters<TSavedFilter>(
                ELocalSavedFilters.SelectedDealerHeaderV2,
                dealerships[0]
            );
                    
            dispatch(setDealerV2({
                dealerV2: defaultDealer
            }));
        }
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dealerships]);

    const selectedDealer = {
        ...userAuth.dealerV2,
        label: userAuth.dealerV2.name,
        value: userAuth.dealerV2.uid
    };

    const filteredInventories = useMemo(() => {

        if (userAuth.dealerV2.new && userAuth.dealerV2.used) {
            if (!isMarketingPage) {
                return inventories.filter((inventory) => inventory.value !== 'all');
            }
            return inventories;
        }

        if (!isMarketingPage) {
            return inventories
                .filter((inventory) => {
                    if (userAuth.dealerV2.used && inventory.value === 'used') {
                        return true;
                    }
                    if (userAuth.dealerV2.new && inventory.value === 'new') {
                        return true;
                    }
                    return false;
                })
                .filter((inventory) => inventory.value !== 'all');
        }

        return inventories.filter((inventory) => {
            if (userAuth.dealerV2.used && inventory.value === 'used') {
                return true;
            }
            if (userAuth.dealerV2.new && inventory.value === 'new') {
                return true;
            }
            return false;
        });

    }, [isMarketingPage, userAuth.dealerV2.new, userAuth.dealerV2.used]);

    useEffect(() => {
        if (!filteredInventories.includes(userAuth.inventory)) {
            dispatch(setInventory({
                inventory: filteredInventories[0]
            }));
        }
    }, [dispatch, filteredInventories, userAuth.inventory]);
    
    const handleMenuClick = () => {
        props.setIsSidebarClose(!props.isOpen);
    };

    const handleInventoryChange = (value: TInventoryOption) => {
        dispatch(setInventory({
            inventory: value
        }));
    };
    
    const handleDealerChange = (value: TDealerV2) => {
        dispatch(setDealerV2({
            dealerV2: value
        }));
    };

    const handleLogout = () => {
        dispatch(logoutUser());
    };

    return (
        <Box
            gridArea="header"
            position="sticky"
            top={0}
            zIndex={6}
        >
            <Box
                backgroundColor="#ffffff"
                padding="15px 21px"
                display="flex"
                alignContent="center"
                zIndex={2}
                position="inherit"
            >
                <Box
                    display="flex"
                    alignItems="center"
                    flex="1"
                    gap="10px"
                >
                    <Box
                        onClick={handleMenuClick}
                    >
                        <MdMenu 
                            style={{
                                height: "24px",
                                width: "24px",
                            }}    
                        />
                    </Box>
                    <Box
                        flex="1"
                        fontSize={!isMobileView ? '1.875rem' : '1.5rem'}
                        fontWeight={700}
                    >
                        {props.pageTitle}
                    </Box>
                </Box>
                {!isMobileView ? (
                    <Box
                        display="flex"
                        gap="10px"
                    >
                        <DropdownMenu 
                            menus={filteredInventories}
                            title="Inventory"
                            value={userAuth.inventory}
                            onChange={handleInventoryChange}
                        />
                        <DropdownMenu 
                            menus={dealers}
                            title="Please select a dealer"
                            value={selectedDealer}
                            onChange={handleDealerChange}
                        />

                        <Button 
                            onClick={handleLogout}
                            leftIcon={(
                                <TbLogout 
                                    style={{
                                        height: "20px",
                                        width: "20px",
                                    }}
                                />
                        )}
                            variant="outline"
                            padding="21px 16px"
                            fontSize="14px"
                        >
                            Logout
                        </Button>

                    </Box>
                ) : (
                    <Box>
                        <Popover>
                            <PopoverTrigger>
                                <IconButton
                                    aria-label='Options'
                                    variant="outline"
                                    padding="16px"
                                    icon={<ChevronDownIcon />}
                                />
                            </PopoverTrigger>
                            <PopoverContent>
                                <PopoverArrow />
                                    <PopoverBody>
                                        <Box
                                            display="flex"
                                            flexFlow="column"
                                            gap="10px"
                                        >
                                            <DropdownMenu 
                                                menus={filteredInventories}
                                                title="Inventory"
                                                value={userAuth.inventory}
                                                onChange={handleInventoryChange}
                                            />
                                            <DropdownMenu 
                                                menus={dealers}
                                                title="Please select a dealer"
                                                value={selectedDealer}
                                                onChange={handleDealerChange}
                                            />

                                            <Button 
                                                onClick={handleLogout}
                                                leftIcon={(
                                                    <TbLogout 
                                                        style={{
                                                            height: "20px",
                                                            width: "20px",
                                                        }}
                                                    />
                                            )}
                                                variant="outline"
                                                padding="21px 16px"
                                                fontSize="14px"
                                            >
                                                Logout
                                            </Button>
                                        </Box>
                                    </PopoverBody>
                            </PopoverContent>
                        </Popover>
                    </Box>
                )}
                
            </Box>
        </Box>
    );
}

export default Header;