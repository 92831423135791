import { useCallback, useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";
import DateRange, { IDateRangeProps } from "components/DateRange";
import {
    TActionProps,
    TGeneratePdfFunction,
    TGenerateXLSFunction,
} from "components/Table/components/ActionButtons/types";
import commonDateRanges, { ICommonDateRange, lastWeek } from "constants/commonDateRanges";
import useGetSales, {
    EModules,
    TBudgetROIDataV2,
    getSalesAsync,
} from "api/useGetSales";
import { format, set } from "date-fns";
import apiDateFormat from "constants/apiDateFormat";
import {
    ELocalSavedFilters,
    ITableMarketing,
    getDefaultFilters,
} from "utils/defaultFilters";
import { getColumns, getPdfColumns, getXLSColumns } from "./constants";
import { formattedDateRange, onGeneratePDFDefaultType } from "utils/helper";
import useUrlQuery from "hooks/useUrlQuery";
import { CommonUrlParams } from "utils/urlParams";
import { Alert, AlertIcon, Box, Radio, RadioGroup, Stack, Text } from "@chakra-ui/react";
import { TInventoryOption, TInventoryOptionValue, inventories } from "constants/common";
import { useAppDispatch, useAppSelector } from "hooks/store";
import { selectUserData, setInventory } from "store/auth/authSlice";
import { IEditBudgetState } from "pages/Marketing/components/Tables/components/BudgetROI/types";
import DataTable from "components/v2/DataTable";
import { ActionButtonV2 } from "components/Table/components/ActionButtons";
import { EditBudgetModal } from "./modals/editBudgetModal";
import { EProviderInitials } from "types/APIProviderRanks";
// import formatOrderingAPI from "utils/formatOrderingAPI";
// import { ESortOrder, ISortState } from "components/Table/types";
// import { SortingState } from "@tanstack/react-table";

const BudgetROI = () => {

    const userAuth = useAppSelector(selectUserData);

    const dispatch = useAppDispatch();

    const { getParam, setMultipleParams } = useUrlQuery<CommonUrlParams>();

    const startDateParams = getParam("startDate");
    const endDateParams = getParam("endDate");
    // const sortByParams = getParam("sortBy");

    const [refreshTable, setRefreshTable] = useState<number>(0);
    const [editBudget, setEditBudget] = useState<IEditBudgetState>({
        isOpen: false,
        provider: null,
        value: 0,
    });

    const defaultFilters = getDefaultFilters<ITableMarketing>(
        ELocalSavedFilters.BudgetROITable,
        { dateRange: lastWeek }
    );

    const [dateRange, setDateRange] = useState<IDateRangeProps["value"]>(
        {
            startDate: startDateParams ? new Date(startDateParams) : defaultFilters.dateRange.startDate,
            endDate: endDateParams ? new Date(endDateParams) : defaultFilters.dateRange.endDate,
        }
    );

    // const [sortBy, setSortBy] = useState<SortingState>(sortByParams && typeof sortByParams === 'string' ? [{
    //     id: sortByParams.replace("-", ""),
    //     desc: sortByParams.startsWith("-") ? true : false,
    // }] : []);;

    useEffect(() => {
        setMultipleParams({
            startDate: format(dateRange.startDate, apiDateFormat),
            endDate: format(dateRange.endDate, apiDateFormat),
            inventory: userAuth.inventory.value
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dateRange.endDate, dateRange.startDate, userAuth.inventory.value]);

    // const sortedBy: ISortState = useMemo(() => {

    //     if (!sortBy.length) {
    //         return {
    //             column: null,
    //             order: ESortOrder.None,
    //         };
    //     }
    //     return {
    //         column: sortBy[0].id as keyof TBudgetROIDataV2,
    //         order: sortBy[0].desc ? ESortOrder.Descending : ESortOrder.Ascending,
    //     };
    // }, [sortBy]);
    
    const fetchParameters = useMemo(
        () => ({
            module: EModules.BudgetROIV2,
            params: {
                date_from: format(dateRange.startDate, apiDateFormat),
                date_to: format(dateRange.endDate, apiDateFormat),
                dealership_uid: userAuth.dealerV2.uid,
                inventory: userAuth.inventory.value,
                ...(userAuth.inventory.value === 'all' && {
                    inventory: '',
                }) as any,
                // ...(formatOrderingAPI<keyof TBudgetROIDataV2>(sortedBy).length > 0 && {
                //     ordering: formatOrderingAPI<keyof TBudgetROIDataV2>(sortedBy),
                // }),
            },
            queryKeys: [refreshTable],
        }),
        [dateRange.endDate, dateRange.startDate, refreshTable, userAuth.dealerV2.uid, userAuth.inventory.value]
    );
    const { data, isFetching } = useGetSales<EModules.BudgetROIV2>(fetchParameters);

    // const onSort = useCallback((sort: SortingState) => {
    //     // if (!sort.length) return;
    //     setSortBy(sort);
    //     setMultipleParams({
    //         sortBy: sort.length > 0 ? `${sort[0].desc ? '-' : ''}${sort[0].id}` : '',
    //     });
    // }, []);

    const onCloseBudgetModal = useCallback(
        () => setEditBudget({ isOpen: false, provider: null, value: 0 }),
        []
    );

    const activeIndexQuickOption = useMemo(
        () =>
            commonDateRanges.findIndex(
                ({ value: quickOptionValue }) =>
                    format(quickOptionValue.startDate, apiDateFormat) ===
                    format(dateRange.startDate, apiDateFormat) &&
                    format(quickOptionValue.endDate, apiDateFormat) ===
                    format(dateRange.endDate, apiDateFormat)
            ),
        [dateRange.endDate, dateRange.startDate]
    );

    const onGeneratePDF: TActionProps<TGeneratePdfFunction> = useCallback(
        async (onGeneratePDF, setIsLoading) => {
            setIsLoading(true);
            try {
                const tempData = await getSalesAsync<EModules.BudgetROIV2>(
                    fetchParameters
                )();

                const tempPdfColumn = getPdfColumns(userAuth.inventory.value);

                const bodyFormatted = tempData.map((values, index) => {
                    return tempPdfColumn.map(
                        ({ func, key }) => {
                            if (key === 'rank') {
                                return index + 1;
                            }
                            return func?.(values[key]) || values[key];
                        }
                    );
                });

                const dateRangeTitle = activeIndexQuickOption !== -1 ? `${commonDateRanges[activeIndexQuickOption].label} (${formattedDateRange(dateRange)})` : formattedDateRange(dateRange);

                onGeneratePDF(
                    {
                        head: [tempPdfColumn.map(({ label }) => label)],
                        body: bodyFormatted,
                        ...onGeneratePDFDefaultType(`Budget ROI`, dateRangeTitle)
                    },
                    "budget-roi.pdf",
                    {
                        compress: true
                    }
                );
                setIsLoading(false);
            } catch (err) {
                toast.error("Can't export PDF file. Please contact your admin");
            }
        },
        [activeIndexQuickOption, dateRange, fetchParameters, userAuth.inventory.value]
    );

    const onGenerateXLSX: TActionProps<TGenerateXLSFunction> = useCallback(
        async (generateXLS, setIsLoading) => {
            setIsLoading(true);
            try {
                const tempData = await getSalesAsync<EModules.BudgetROIV2>(
                    fetchParameters
                )();

                const tempPdfColumn = getXLSColumns(userAuth.inventory.value);

                const bodyFormatted = tempData.map((values, index) => {
                    return tempPdfColumn.map(
                        ({ func, key }) => {
                            if (key === 'rank') {
                                return index + 1;
                            }
                            return func?.(values[key], values) || values[key];
                        }
                    );
                });


                generateXLS(
                    {
                        head: [tempPdfColumn.map(({ label }) => label)],
                        body: bodyFormatted,
                    },
                    "budget-roi.xls",
                );
                setIsLoading(false);
            } catch (err) {
                toast.error("Can't export XLS file. Please contact your admin");
            }
        },
        [fetchParameters, userAuth.inventory.value]
    );

    const onRadioChange = (nextValue: TInventoryOptionValue) => {
        const selectedInventory: TInventoryOption = inventories.find((inventory) => inventory.value === nextValue) || inventories[0];
        dispatch(setInventory({ inventory: selectedInventory }));
    };

    const filteredDateRanges: ICommonDateRange[] = commonDateRanges.filter(range => {
        return range.label !== "Last 30 Days" && range.label !== "Last 60 Days";
    });

    const filteredInventories = useMemo(() => {
        if (userAuth.dealerV2.new && userAuth.dealerV2.used) {
            return inventories;
        }
        return inventories.filter((inventory) => {
            if (userAuth.dealerV2.used && inventory.value === 'used') {
                return true;
            }
            if (userAuth.dealerV2.new && inventory.value === 'new') {
                return true;
            }
            return false;
        });
    }, [userAuth.dealerV2.new, userAuth.dealerV2.used]);

    const onDateChange = (newValue: [Date, Date]) => {
        // setDateRange({ startDate, endDate });
        setDateRange({
            startDate: set(newValue[0], { hours: 0, minutes: 0, seconds: 0 }),
            endDate: set(newValue[1], { hours: 23, minutes: 59, seconds: 59 }),
        });
    };

    const conttroller = () => {
        return (
            <Box
                display="flex"
                gap="15px"
                justifyContent="space-between"
                flex="1"
                alignItems="center"
            >
                <Box display="flex" alignItems="center" gap="15px" flex="1">
                    <RadioGroup onChange={onRadioChange} value={userAuth.inventory.value}>
                        <Stack direction="row">
                            {filteredInventories.map((inventory) => (
                                <Radio
                                    key={inventory.value}
                                    value={inventory.value}
                                    colorScheme="red"
                                >
                                    {inventory.label}
                                </Radio>
                            ))}
                        </Stack>
                    </RadioGroup>
                </Box>
                <Box>
                    <ActionButtonV2
                        actionConfig={{
                            isDownloadPDF: true,
                            isDownloadXLS: true,
                            isEmailPDF: true,
                            isEmailXLS: true,
                            isPrintPDF: true,
                        }}
                        onDownloadPDF={onGeneratePDF}
                        onDownloadXLS={onGenerateXLSX}
                        onGeneratePDF={onGeneratePDF}
                    />
                </Box>
            </Box>
        );
    };

    return (
        <>
            <EditBudgetModal 
                isOpen={editBudget.isOpen}
                onClose={onCloseBudgetModal}
                provider={editBudget.provider || EProviderInitials.Autotrader}
                budget={editBudget.value}
                setRefreshTable={setRefreshTable}
            />
            <Box display="flex" flexDirection="column" gap="15px">
                <DateRange
                    onChange={onDateChange}
                    quickOptions={filteredDateRanges}
                    value={dateRange}
                />
                <DataTable<TBudgetROIDataV2>
                    data={data || []}
                    rowCount={data?.length || 0}
                    // onPageChange={onPageChange}
                    // sort={sortBy}
                    // onSort={onSort}
                    columns={getColumns(setEditBudget, userAuth.inventory.value) as any}
                    pageIndex={1}
                    pageSize={10}
                    isLoading={isFetching}
                    conttroller={conttroller()}
                    // onSearch={setQuickSearch}
                    // pageCount={data?.total_pages}
                    // onColumnVisibilityChange={setColumnVisibility}
                    // columnVisibility={columnVisibility}
                    // onColumnSizingChange={setColumnSizing}
                    // columnSizing={columnSizing}
                    showColumns={true}
                    // onUpdateData={updateData}
                    // columnsSidebarChildren={columnSidebarChilrend()}
                    triggerButtonType="text"
                    showPagination={false}
                    manualSorting={false}
                />
                <Box display="flex" flexFlow="column" gap="10px">
                    <Alert status='info' width="max-content" borderRadius="5px">
                        <AlertIcon />
                        <Text fontSize="14px" fontStyle="italic">
                            Total VDPs include website referral traffic for third party providers
                        </Text>
                    </Alert>
                    {userAuth.dealer.is_vinsolutions && (
                        <Alert status='info' width="max-content" borderRadius="5px">
                            <AlertIcon />
                            <Text fontSize="14px" fontStyle="italic">
                                Sold count is based on Sold from Leads
                            </Text>
                        </Alert>
                    )}
                </Box>
            </Box>
        </>
    );
};
export default BudgetROI;
