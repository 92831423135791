import Tables from "./components/Tables";

const Marketing = () => {
  return (
    <>
      <Tables />
    </>
  );
};

export default Marketing;
