import React, { FC } from "react";
import ReactSelect, { Props as ReactSelectProps } from "react-select";
// import styles from "./styles.module.scss";

export interface ISelectProps extends ReactSelectProps {}

// todo: adjust the styling with the current branding https://www.figma.com/file/FruWzdzoXDWCipateTbJSA/Vindrives-Style-Guide-v1?node-id=3281-377673&t=ay71FORUuF9QVnsR-4
const Select: FC<ISelectProps> = ({ ...props }) => {
  return <ReactSelect {...props} />;
};

export default Select;
