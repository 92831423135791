import { FC, ReactNode } from "react";
import cs from "classnames";
import styles from "./styles.module.scss";

type TDisplays =
  | "display-2xl"
  | "display-xl"
  | "display-lg"
  | "display-md"
  | "display-sm"
  | "display-xs";

type TTextSizes = "text-xl" | "text-lg" | "text-md" | "text-sm" | "text-xs";
type TFontWeights = "regular" | "medium" | "semibold" | "bold";

interface IProps {
  children?: ReactNode;
  className?: string;
  fontSize?: TDisplays | TTextSizes;
  fontWeight?: TFontWeights;
}

const styleSizes = {
  "display-2xl": styles["typography__display--2xl"],
  "display-xl": styles["typography__display--xl"],
  "display-lg": styles["typography__display--lg"],
  "display-md": styles["typography__display--md"],
  "display-sm": styles["typography__display--sm"],
  "display-xs": styles["typography__display--xs"],
  "text-xl": styles["typography__text--xl"],
  "text-lg": styles["typography__text--lg"],
  "text-md": styles["typography__text--md"],
  "text-sm": styles["typography__text--sm"],
  "text-xs": styles["typography__text--xs"],
};

const fontWeightStyles = {
  bold: styles["typography--bold"],
  medium: styles["typography--medium"],
  regular: styles["typography--regular"],
  semibold: styles["typography--semibold"],
};

const Typography: FC<IProps> = ({
  children,
  className,
  fontSize = "text-md",
  fontWeight = "regular",
}) => {
  return (
    <p
      className={cs(
        styles.typography,
        styleSizes[fontSize],
        fontWeightStyles[fontWeight],
        className
      )}
    >
      {children}
    </p>
  );
};

export default Typography;
