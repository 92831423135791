import { useRef, useEffect, useState } from "react";
import styles from "./styles.module.scss";

export interface ItemProps {
  id: number;
  height: number;
  width: number;
  component: string;
}

interface DashboardProps {
  items?: ItemProps[];
}

const defaultItems = [
  {
    id: 2,
    width: 4,
    height: 2,
    component: "placeholder",
  },
];

/* 
Dashboard has 24 vertical sections and 30px height per section
*/
const Dashboard = ({ items = defaultItems }: DashboardProps) => {
  const DashboardRef = useRef<HTMLDivElement>(null);

  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);

  const handleElementResized = () => {
    if (DashboardRef.current?.offsetWidth !== width) {
      setWidth((DashboardRef.current?.offsetWidth ?? 0) - 40);
    }
    if (DashboardRef.current?.offsetHeight !== height) {
      setHeight((DashboardRef.current?.offsetHeight ?? 0) - 40);
    }
  };

  const resizeObserver = new ResizeObserver(handleElementResized);
  const xCount = Math.floor(height / 110);
  const yCount = Math.floor(width / 110);
  useEffect(() => {
    if (DashboardRef.current) {
      resizeObserver.observe(DashboardRef.current);
    }

    return function cleanup() {
      resizeObserver.disconnect();
    };
  });
  return (
    <div ref={DashboardRef} className={styles.dashboard}>
      <div className={styles.container}></div>
      <div className={styles.dragContainer}>
        {Array.from({ length: xCount }, (_, i) => i).map((x) => {
          return Array.from({ length: yCount }, (_, i) => i).map((y) => (
            <div className={styles.dropBox}>
              {x},{y}
            </div>
          ));
        })}
      </div>
    </div>
  );
};
export default Dashboard;
