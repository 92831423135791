import { useCallback } from "react";
import Typography from "components/Typography";
import BarChart from "components/BarChart";
import styles from "pages/Home/styles.module.scss";
import { EResultScoreDateRange } from "types/EResultScoreDateRange";
import { useAppDispatch, useAppSelector } from "hooks/store";
import { selectResuleScoreData, setFilterBy } from "store/resultScore/resultScoreSlice";

const ResultScores = () => {
  
  const dispatch = useAppDispatch();

  const { filterBy, resultScoresCategories, resultScoresGraph, isLoading } = useAppSelector(selectResuleScoreData);
  
  const onChangeBarFilter = useCallback(
    (newFilter: string) => {
      dispatch(setFilterBy({
        filterBy: newFilter as EResultScoreDateRange
      }));
    },
    [dispatch]
  );

  return (
    <div className={styles.reportsContainer}>
      <Typography fontSize="text-xl" fontWeight="semibold">
        Result Scores
      </Typography>
      <BarChart
        isLoading={isLoading}
        className={styles.barChart}
        data={[
          {
            color: "#4CA30D",
            id: "green",
            label: "Green",
            values: resultScoresGraph.greenScores.every((score) => score === 0) ? [] : resultScoresGraph.greenScores,
          },
          { 
            color: "#EAAA08",
            id: "yellow",
            label: "Yellow",
            values: resultScoresGraph.yellowScores.every((score) => score === 0) ? [] : resultScoresGraph.yellowScores,
          },
          {
            color: "#D92D20",
            id: "red",
            label: "Red",
            values: resultScoresGraph.redScores.every((score) => score === 0) ? [] : resultScoresGraph.redScores,
          },
        ]}
        // filters={[
        //   { label: "Today", id: EResultScoreDateRange.Today },
        //   { label: "Week", id: EResultScoreDateRange.LastWeek },
        //   { label: "Month", id: EResultScoreDateRange.LastMonth },
        // ]}
        filter={filterBy}
        filterSeriesLabel="Show/hide Result Scores"
        onChangeFilter={onChangeBarFilter}
        showLegend={false}
        showXYGridLines={false}
        showYAxis={false}
        xAxisFormat={{
          categories: resultScoresCategories,
          axisTicks: {
            show: false,
          },
          axisBorder: { show: false },
        }}
      />
    </div>
  );
};

export default ResultScores;
