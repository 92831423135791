import { useCallback, useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";
import { format, set } from "date-fns";
import useGetSales, {
  EModules,
  VPDsByChannelDataV2,
  getSalesAsync,
} from "api/useGetSales";
import DateRange, { IDateRangeProps } from "components/DateRange";
import apiDateFormat from "constants/apiDateFormat";
import commonDateRanges, {
  ICommonDateRange,
  lastWeek,
} from "constants/commonDateRanges";
import {
  TActionProps,
  TGeneratePdfFunction,
  TGenerateXLSFunction,
} from "components/Table/components/ActionButtons/types";
import {
  ELocalSavedFilters,
  ITableMarketing,
  getDefaultFilters,
} from "utils/defaultFilters";
import { formattedDateRange, onGeneratePDFDefaultType } from "utils/helper";
import { getColumns, getPdfColumns, getXLSColumns } from "./constants";
import useUrlQuery from "hooks/useUrlQuery";
import { CommonUrlParams } from "utils/urlParams";
import { Box, Radio, RadioGroup, Stack } from "@chakra-ui/react";
import {
  TInventoryOption,
  TInventoryOptionValue,
  inventories,
} from "constants/common";
import { useAppDispatch, useAppSelector } from "hooks/store";
import { selectUserData, setInventory } from "store/auth/authSlice";
import DataTable from "components/v2/DataTable";
import { ActionButtonV2 } from "components/Table/components/ActionButtons";

const filterVinmanager = ({ source }: VPDsByChannelDataV2) =>
  source.toLowerCase() !== "vinmanager";

const VDPsByChannel = () => {
  const userAuth = useAppSelector(selectUserData);

  const dispatch = useAppDispatch();

  const { getParam, setParam } = useUrlQuery<CommonUrlParams>();

  const startDateParams = getParam("startDate");
  const endDateParams = getParam("endDate");

  const defaultFilters = getDefaultFilters<ITableMarketing>(
    ELocalSavedFilters.VDPsByChannelTable,
    { dateRange: lastWeek }
  );

  const [dateRange, setDateRange] = useState<IDateRangeProps["value"]>({
    startDate: startDateParams
      ? new Date(startDateParams)
      : defaultFilters.dateRange.startDate,
    endDate: endDateParams
      ? new Date(endDateParams)
      : defaultFilters.dateRange.endDate,
  });

  useEffect(() => {
    setParam({
      startDate: format(dateRange.startDate, apiDateFormat),
      endDate: format(dateRange.endDate, apiDateFormat),
      inventory: userAuth.inventory.value,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateRange.endDate, dateRange.startDate, userAuth.inventory.value]);

  const fetchParameters = useMemo(
    () => ({
      module: EModules.VDPsByChannelV2,
      params: {
        date_from: format(dateRange.startDate, apiDateFormat),
        date_to: format(dateRange.endDate, apiDateFormat),
        dealership_uid: userAuth.dealerV2.uid,
        inventory: userAuth.inventory.value,
        ...((userAuth.inventory.value === "all" && {
          inventory: "",
        }) as any),
      },
    }),
    [
      dateRange.endDate,
      dateRange.startDate,
      userAuth.dealerV2.uid,
      userAuth.inventory.value,
    ]
  );
  const { data, isFetching } =
    useGetSales<EModules.VDPsByChannelV2>(fetchParameters);

  const activeIndexQuickOption = useMemo(
    () =>
      commonDateRanges.findIndex(
        ({ value: quickOptionValue }) =>
          format(quickOptionValue.startDate, apiDateFormat) ===
          format(dateRange.startDate, apiDateFormat) &&
          format(quickOptionValue.endDate, apiDateFormat) ===
          format(dateRange.endDate, apiDateFormat)
      ),
    [dateRange.endDate, dateRange.startDate]
  );

  const onGeneratePDF: TActionProps<TGeneratePdfFunction> = useCallback(
    async (onGeneratePDF, setIsLoading) => {
      setIsLoading(true);
      try {
        const results = await getSalesAsync<EModules.VDPsByChannelV2>(
          fetchParameters
        )();

        const tempPdfColumn = getPdfColumns(userAuth.inventory.value);

        const bodyFormatted = results
          .filter(filterVinmanager)
          .map((values, index) => {
            return tempPdfColumn.map(({ func, key }) =>
              key === "rank" ? ++index : func?.(values[key]) || values[key]
            );
          });

        const dateRangeTitle =
          activeIndexQuickOption !== -1
            ? `${commonDateRanges[activeIndexQuickOption].label
            } (${formattedDateRange(dateRange)})`
            : formattedDateRange(dateRange);

        onGeneratePDF(
          {
            head: [tempPdfColumn.map(({ label }) => label)],
            body: bodyFormatted,
            ...onGeneratePDFDefaultType(`VDPs by Channel`, dateRangeTitle),
          },
          "vdps-by-channel.pdf",
          {
            compress: true,
          }
        );
        setIsLoading(false);
      } catch (err) {
        toast.error("Can't export PDF file. Please contact your admin");
      }
    },
    [
      activeIndexQuickOption,
      dateRange,
      fetchParameters,
      userAuth.inventory.value,
    ]
  );

  const onGenerateXLSX: TActionProps<TGenerateXLSFunction> = useCallback(
    async (generateXLS, setIsLoading) => {
      setIsLoading(true);
      try {
        const results = await getSalesAsync<EModules.VDPsByChannelV2>(
          fetchParameters
        )();

        const tempXLSColumn = getXLSColumns(userAuth.inventory.value);

        const bodyFormatted = results
          .filter(filterVinmanager)
          .map((values, index) => {
            return tempXLSColumn.map(({ func, key }) =>
              key === "rank"
                ? ++index
                : func?.(values[key], values) || values[key]
            );
          });

        generateXLS(
          {
            head: [tempXLSColumn.map(({ label }) => label)],
            body: bodyFormatted,
          },
          "vdps-by-channel.xls"
        );
        setIsLoading(false);
      } catch (err) {
        toast.error("Can't export XLS file. Please contact your admin");
      }
    },
    [fetchParameters, userAuth.inventory.value]
  );

  const onRadioChange = (nextValue: TInventoryOptionValue) => {
    const selectedInventory: TInventoryOption =
      inventories.find((inventory) => inventory.value === nextValue) ||
      inventories[0];
    dispatch(setInventory({ inventory: selectedInventory }));
    // setInventory(selectedInventory);
  };

  const filteredDateRanges: ICommonDateRange[] = commonDateRanges.filter(
    (range) => {
      return range.label !== "Last 30 Days" && range.label !== "Last 60 Days";
    }
  );

  const filteredInventories = useMemo(() => {
    if (userAuth.dealerV2.new && userAuth.dealerV2.used) {
      return inventories;
    }
    return inventories.filter((inventory) => {
      if (userAuth.dealerV2.used && inventory.value === "used") {
        return true;
      }
      if (userAuth.dealerV2.new && inventory.value === "new") {
        return true;
      }
      return false;
    });
  }, [userAuth.dealerV2.new, userAuth.dealerV2.used]);

  const onDateChange = (newValue: [Date, Date]) => {
    // setDateRange({ startDate, endDate });
    setDateRange({
      startDate: set(newValue[0], { hours: 0, minutes: 0, seconds: 0 }),
      endDate: set(newValue[1], { hours: 23, minutes: 59, seconds: 59 }),
    });
  };

  const conttroller = () => {
    return (
      <Box
        display="flex"
        gap="15px"
        justifyContent="space-between"
        flex="1"
        alignItems="center"
      >
        <Box display="flex" alignItems="center" gap="15px" flex="1">
          <RadioGroup onChange={onRadioChange} value={userAuth.inventory.value}>
            <Stack direction="row">
              {filteredInventories.map((inventory) => (
                <Radio
                  key={inventory.value}
                  value={inventory.value}
                  colorScheme="red"
                >
                  {inventory.label}
                </Radio>
              ))}
            </Stack>
          </RadioGroup>
        </Box>
        <Box>
          <ActionButtonV2
            actionConfig={{
              isDownloadPDF: true,
              isDownloadXLS: true,
              isEmailPDF: true,
              isEmailXLS: true,
              isPrintPDF: true,
            }}
            onDownloadPDF={onGeneratePDF}
            onDownloadXLS={onGenerateXLSX}
            onGeneratePDF={onGeneratePDF}
          />
        </Box>
      </Box>
    );
  };

  return (
    <>
      <Box display="flex" flexDirection="column" gap="15px">
        <DateRange
          onChange={onDateChange}
          quickOptions={filteredDateRanges}
          value={dateRange}
        />
        <DataTable<VPDsByChannelDataV2>
          data={data || []}
          rowCount={data?.length || 0}
          // onPageChange={onPageChange}
          // sort={sortBy}
          // onSort={onSort}
          columns={getColumns(userAuth.inventory.value) as any}
          pageIndex={1}
          pageSize={10}
          isLoading={isFetching}
          conttroller={conttroller()}
          // onSearch={setQuickSearch}
          // pageCount={data?.total_pages}
          // onColumnVisibilityChange={setColumnVisibility}
          // columnVisibility={columnVisibility}
          // onColumnSizingChange={setColumnSizing}
          // columnSizing={columnSizing}
          showColumns={true}
          // onUpdateData={updateData}
          // columnsSidebarChildren={columnSidebarChilrend()}
          triggerButtonType="text"
          showPagination={false}
          manualSorting={false}
        />
      </Box>
    </>
  );
};
export default VDPsByChannel;
