import { UseFormRegister } from "react-hook-form";
import { ReactComponent as AlertCircleIcon } from "assets/icons/alert-circle.svg";
import cs from "classnames";
import styles from "./styles.module.scss";

interface TextInputProps {
  className?: string;
  placeholder?: string;
  value?: string;
  label?: string | React.ReactNode;
  errorMessage?: string;
  icon?: JSX.Element;
  iconSuffix?: JSX.Element;
  disabled?: boolean;
  name: string;
  type?: React.HTMLInputTypeAttribute;
  required?: boolean;
  register?: UseFormRegister<any>;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
}

const TextInput = ({
  className,
  placeholder,
  value,
  label,
  errorMessage,
  icon,
  iconSuffix,
  disabled,
  name,
  type = "text",
  required,
  register,
  onChange,
}: TextInputProps) => {
  return (
    <div className={cs(styles.textInput, className)}>
      {label && <div className={styles.label}>{label}</div>}
      <div className={styles.input}>
        {icon && <div className={styles.iconPrefix}>{icon}</div>}
        <div className={styles.suffixIcons}>
          {errorMessage && (
            <div
              className={cs(styles.errorIcon, {
                [styles.hasIconSuffix]: iconSuffix,
              })}
            >
              <AlertCircleIcon />
            </div>
          )}
          {iconSuffix && (
            <div
              className={cs(styles.iconSuffix, {
                [styles.hasError]: errorMessage,
              })}
            >
              {iconSuffix}
            </div>
          )}
        </div>
        <input
          disabled={disabled}
          className={cs(
            styles.inputBox,
            { [styles.inputIcon]: icon !== undefined },
            { [styles.error]: errorMessage !== undefined }
          )}
          type={type}
          placeholder={placeholder}
          value={value}
          {...(register ? register(name, { required }) : {})}
          {...(onChange ? { onChange } : {})}
        />
      </div>
      {errorMessage && <div className={styles.error}>{errorMessage}</div>}
    </div>
  );
};

export default TextInput;
