import Cookies from "js-cookie";
import ELocalStorageKeys from "types/LocalStorageKey";

type HttpMethod = "GET" | "POST" | "PUT" | "DELETE";

interface IOptions {
  body?: Record<string, any>;
  isAuth?: boolean;
  method: HttpMethod;
  params?: any;
  url: string;
}

const fetchUtil =
  <T>({ body, isAuth = false, method, params = {}, url }: IOptions) =>
  async () => {
    try {

      const searchParams = new URLSearchParams();

      Object.entries(params).forEach(([key, value]) => {
        if (Array.isArray(value)) {
          value.forEach((item) => {
            searchParams.append(key, item);
          });
        } else {
          searchParams.append(key, value as any);
        }
      });

      const queryString = searchParams.toString();

      const response = await fetch(
        `${url}${searchParams ? `?${queryString}` : ""}`,
        {
          ...(body && { body: JSON.stringify(body) }),
          method: method,
          headers: {
            ...((method === "POST" || method === "PUT") && {
              "Content-Type": "application/json",
            }),
            ...(isAuth && {
              Authorization: `Bearer ${Cookies.get(
                ELocalStorageKeys.AccessToken
              )}`,
            }),
          },
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();

      return data as T;
      
    } catch (error) {
      // Handle or log the error as needed
      // console.error("Fetch operation failed:", error);
      throw error; // Re-throw the error if you want to propagate it to the caller
    }
};

export default fetchUtil;
