import { useNavigate } from "react-router-dom";
import { ReactComponent as AlertCircleIcon } from "assets/icons/alert-circle.svg";
import Typography from "components/Typography";
import Badge from "components/Badge";
import Button from "components/Button";
import TResultScoreRecentActivity from "types/APIResultScoreKeys";
import IDateRangeFormatted from "types/IDateRangeFormatted";
import ERoutes, { EQueryParameterKeys } from "types/Routes";
import autoFormatNumber from "utils/autoFormatNumber";
import styles from "./styles.module.scss";

interface AttentionItem {
  reviewFilter: TResultScoreRecentActivity;
  score: number;
  title: string;
}

interface AttentionListProps {
  items: AttentionItem[];
  dateRange: IDateRangeFormatted;
}

const AttentionList = ({ dateRange, items }: AttentionListProps) => {
  const navigate = useNavigate();

  const filteredItems = items.filter((item) => item.score > 0);

  return (
    <div className={styles["attentionList"]}>
      <div className={styles["attentionList__title"]}>
        <AlertCircleIcon className={styles["attentionList__title__icon"]} />
        <Typography
          className={styles["attentionList__title--text"]}
          fontSize="text-lg"
          fontWeight="bold"
        >
          Needs Attention
        </Typography>
      </div>
      {filteredItems.map((item, index) => (
        <div
          key={`AttentionItem-${index}`}
          className={styles["attentionList__item"]}
        >
          <Badge label={autoFormatNumber(item.score)} color="gray" />
          <Typography
            className={styles["attentionList__item__text"]}
            fontSize="text-sm"
            fontWeight="semibold"
          >
            {item.title}
          </Typography>
          <Button
            size="sm"
            variant="text"
            onClick={() =>
              navigate(
                `${ERoutes.VDPResults}?${EQueryParameterKeys.ResultScore}=${item.reviewFilter}&${EQueryParameterKeys.EndDate}=${dateRange.endDate}&${EQueryParameterKeys.StartDate}=${dateRange.startDate}`
              )
            }
          >
            Review
          </Button>
        </div>
      ))}
    </div>
  );
};
export default AttentionList;
