import { FC, ReactNode, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { ReactComponent as CloseIcon } from "assets/icons/x-close.svg";
import Button from "components/Button";
import ERoutes from "types/Routes";
import styles from "../styles.module.scss";

interface IHelperText {
  message: string;
  isError?: boolean;
}

interface IProps {
  children: ReactNode;
  helperText?: IHelperText;
  isSubmitting: boolean;
  onSubmit: () => void;
}

const FormContainer: FC<IProps> = ({
  children,
  helperText,
  isSubmitting,
  onSubmit,
}) => {
  const navigate = useNavigate();

  const onClose = useCallback(() => {
    navigate(ERoutes.Home, { replace: true });
  }, [navigate]);

  return (
    <div className={styles.forgotPassword}>
      <div className={styles["forgotPassword__header"]}>
        <div className={styles["forgotPassword__header__icon"]}>
          <img src="assets/icons/lock.svg" alt="lock-icon" />
        </div>
        <div className={styles["forgotPassword__header__title"]}>
          <b>Forgot Password?</b>
          {helperText && (
            <p {...(helperText.isError && { className: styles.errorMessage })}>
              {helperText.message}
            </p>
          )}
        </div>
        <div className={styles["forgotPassword__header__close"]} role="button">
          <CloseIcon onClick={onClose} />
        </div>
      </div>
      <form className={styles["forgotPassword__body"]} onSubmit={onSubmit}>
        {children}
        <Button disabled={isSubmitting}>Submit</Button>
      </form>
    </div>
  );
};

export default FormContainer;
