import { EProviderInitials } from "types/APIProviderRanks";
import { IProviderCheckList } from "types/IProviderCheckList";

export const autoTrader = {
  id: EProviderInitials.Autotrader,
  label: "Autotrader",
  value: true,
};

export const carsDotCom = {
  id: EProviderInitials.CarsDotCom,
  label: "Cars.com",
  value: true,
};

export const carGurus = {
  id: EProviderInitials.CarGurus,
  label: "CarGurus",
  value: true,
};

export const carfax = {
  id: EProviderInitials.Carfax,
  label: "Carfax",
  value: true,
};

export const vinmanager = {
  id: EProviderInitials.Vinmanager,
  label: "Vinmanager",
  value: true,
};

export const website = {
  id: EProviderInitials.GoogleAnalytics,
  label: "Website",
  value: true,
};

export const options: IProviderCheckList[] = [
  autoTrader,
  carsDotCom,
  // carfax,
  carGurus,
  // vinmanager
];
