import { createColumnHelper } from "@tanstack/react-table";
import { VPDsByChannelDataV2 } from "api/useGetSales";
import { TInventoryOptionValue } from "constants/common";
import TAggregatorColumnPDF from "types/TAggregatorColumnPDF";
import TAggregatorColumnXLS from "types/TAggregatorColumnXLS";
import { capitalizeFirstLetter } from "utils/helper";


const columnHelper = createColumnHelper<VPDsByChannelDataV2>();

export const getColumns = (inventory: TInventoryOptionValue) => ([
  columnHelper.accessor((row) => row, {
    id: "rank",
    cell: (info) => info.row.index + 1,
    enableSorting: true,
    header: `VDP Rank by Volume: ${capitalizeFirstLetter(inventory)}`,
  }),
  columnHelper.accessor((row) => row.source, {
    id: "source",
    cell: (info) => info.getValue(),
    enableSorting: false,
    header: `Source: ${capitalizeFirstLetter(inventory)}`,
  }),
  columnHelper.accessor((row) => row.total_vdps, {
    id: "vpds",
    cell: (info) => info.getValue().toLocaleString(),
    header: `VDPs: ${capitalizeFirstLetter(inventory)}`,
    enableSorting: true,
  }),
  columnHelper.accessor((row) => row.total_srps, {
    id: "srps",
    cell: (info) => info.getValue().toLocaleString(),
    header: `SRPs: ${capitalizeFirstLetter(inventory)}`,
    enableSorting: true,
  }),
  columnHelper.accessor((row) => row.total_srps_vdps, {
    id: "ratios",
    cell: (info) => `${info.getValue().toLocaleString()}%`,
    header: `SRP/VDP Ratio: ${capitalizeFirstLetter(inventory)}`,
    enableSorting: true,
  }),
]);

export const getPdfColumns = (inventory: TInventoryOptionValue): TAggregatorColumnPDF<VPDsByChannelDataV2>[] => ([
  {
    // label: 'Rank',
    label: `Rank: ${capitalizeFirstLetter(inventory)}`,
    key: "rank",
  },
  {
    // label: 'Source',
    label: `Source: ${capitalizeFirstLetter(inventory)}`,
    key: "source",
  },
  {
    // label: 'VDPs',
    label: `VDPs: ${capitalizeFirstLetter(inventory)}`,
    key: "total_vdps",
    func: (value) => value.toLocaleString(),
  },
  {
    // label: 'SRPs',
    label: `SRPs: ${capitalizeFirstLetter(inventory)}`,
    key: "total_srps",
    func: (value) => value.toLocaleString(),
  },
  {
    // label: 'SRP/VDP Ratio',
    label: `SRP/VDP Ratio: ${capitalizeFirstLetter(inventory)}`,
    key: "total_srps_vdps",
    func: (value) => `${value.toLocaleString()}%`,
  },
]);

export const getXLSColumns = (inventory: TInventoryOptionValue): TAggregatorColumnXLS<VPDsByChannelDataV2>[] => ([
  {
    // label: 'Rank',
    label: `Rank: ${capitalizeFirstLetter(inventory)}`,
    key: "rank",
  },
  {
    // label: 'Source',
    label: `Source: ${capitalizeFirstLetter(inventory)}`,
    key: "source",
  },
  {
    // label: 'VDPs',
    label: `VDPs: ${capitalizeFirstLetter(inventory)}`,
    key: "total_vdps",
    func: (value) => value.toLocaleString(),
  },
  {
    // label: 'SRPs',
    label: `SRPs: ${capitalizeFirstLetter(inventory)}`,
    key: "total_srps",
    func: (value) => value.toLocaleString(),
  },
  {
    // label: 'SRP/VDP Ratio',
    label: `SRP/VDP Ratio: ${capitalizeFirstLetter(inventory)}`,
    key: "total_srps_vdps",
    func: (value) => `${value.toLocaleString()}%`,
  },
]);
