import cs from "classnames";
import { ReactComponent as ArrowDownIcon } from "assets/icons/arrow-down.svg";
import { ReactComponent as ArrowUpIcon } from "assets/icons/arrow-up.svg";
import Badge from "components/Badge";
import Typography from "components/Typography";
import autoFormatNumber from "utils/autoFormatNumber";
import styles from "./styles.module.scss";

type TSectionGrowth = "noValue" | "neutral" | "bearish" | "bullish";

type TArrowDirection = "up" | "down" | null;

export interface IStatisticCardProps {
  leftSection: IValues;
  rightSection: IValues;
}

export interface IValues {
  arrowDirection?: TArrowDirection;
  growth?: number;
  label: string;
  total: number;
  showGrowth?: boolean;
  description?: string;
}

const StatisticCard = ({ leftSection, rightSection }: IStatisticCardProps) => {
  const getBadgeColor = (growthStatus: TSectionGrowth) => {
    return growthStatus === "neutral" || growthStatus === "noValue"
      ? "gray"
      : growthStatus === "bearish"
      ? "error"
      : "success";
  };

  const getBadgeIcon = (arrowDirection: TArrowDirection) =>
    !arrowDirection ? (
      <div />
    ) : arrowDirection === "up" ? (
      <ArrowUpIcon />
    ) : (
      <ArrowDownIcon />
    );

  const getGrowth = (value: IValues): TSectionGrowth => {
    let growthStatus: TSectionGrowth = "noValue";

    if (value.total !== 0 && !value.growth) {
      growthStatus = "neutral";
    } else if (value.growth) {
      growthStatus = value.growth > 0 ? "bullish" : "bearish";
    }

    return growthStatus;
  };

  const rightSectionGrowth: TSectionGrowth = getGrowth(rightSection);
  const leftSectionGrowth: TSectionGrowth = getGrowth(leftSection);

  return (
    <div className={styles.statisticCard}>
      <div className={styles["statisticCard__section"]}>
        <Typography
          className={cs(
            styles["statisticCard__section__label"],
            styles[`statisticCard__section__label--${leftSectionGrowth}`]
          )}
          fontSize="text-xs"
          fontWeight="medium"
        >
          {leftSection.label}
        </Typography>
        {leftSection.description ? (
          <Typography
            className={cs(
              styles["statisticCard__section__label"],
              styles[`statisticCard__section__label--${leftSectionGrowth}`],
              styles[`statisticCard__section__description`]
            )}
            fontSize="text-xs"
            fontWeight="medium"
          >
            {leftSection.description}
          </Typography>
        ) : null}
        <div
          className={cs(
            styles["statisticCard__section__values"],
            styles[`statisticCard__section__values--${leftSectionGrowth}`]
          )}
        >
          <Typography
            fontSize="display-xs"
            fontWeight="semibold"
            className={cs(
              styles["statisticCard__section__values__total"],
              styles[
                `statisticCard__section__values__total--${leftSectionGrowth}`
              ]
            )}
          >
            {autoFormatNumber(leftSection.total)}
          </Typography>
          {/*leftSection.showGrowth ? (
            <Badge
              label={`${autoFormatNumber(Math.abs(leftSection.growth ?? 0))}%`}
              className={
                styles[
                  `statisticCard__section__values__growth--${leftSectionGrowth}`
                ]
              }
              color={getBadgeColor(leftSectionGrowth)}
              icon={getBadgeIcon(leftSection.arrowDirection ?? null)}
            />
            ) : null*/}
        </div>
      </div>

      <div className={styles["statisticCard__divider"]} />

      <div className={styles["statisticCard__section"]}>
        <Typography
          className={cs(
            styles["statisticCard__section__label"],
            styles[`statisticCard__section__label--${rightSectionGrowth}`]
          )}
          fontSize="text-xs"
          fontWeight="medium"
        >
          {rightSection.label}
        </Typography>
        {rightSection.description ? (
          <Typography
            className={cs(
              styles["statisticCard__section__label"],
              styles[`statisticCard__section__label--${rightSectionGrowth}`],
              styles[`statisticCard__section__description`]
            )}
            fontSize="text-xs"
            fontWeight="medium"
          >
            {rightSection.description}
          </Typography>
            ) : null}
        <div
          className={cs(
            styles["statisticCard__section__values"],
            styles[`statisticCard__section__values--${rightSectionGrowth}`]
          )}
        >
          <Typography
            fontSize="display-xs"
            fontWeight="semibold"
            className={cs(
              styles["statisticCard__section__values__total"],
              styles[
                `statisticCard__section__values__total--${rightSectionGrowth}`
              ]
            )}
          >
            {autoFormatNumber(rightSection.total)}
          </Typography>
            {/*rightSection.showGrowth ? (
              <Badge
                label={`${autoFormatNumber(Math.abs(rightSection.growth ?? 0))}%`}
                className={
                  styles[
                    `statisticCard__section__values__growth--${rightSectionGrowth}`
                  ]
                }
                color={getBadgeColor(rightSectionGrowth)}
                icon={getBadgeIcon(rightSection.arrowDirection ?? null)}
              />
            ) : null*/}
        </div>
      </div>
    </div>
  );
};
export default StatisticCard;
