import { yupResolver } from "@hookform/resolvers/yup";
import React, { FC, useCallback } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import TextInput from "components/TextInput";
import useAuthApi from "hooks/useAuthApi";
import { ESteps, ISendOTPForm } from "types/ForgotPassword";
import FormContainer from "./FormContainer";

interface IProps {
  setStep: (step: ESteps) => void;
  setUserEmail: (email: string) => void;
}

const sendOTPSchema = yup.object().shape({
  email: yup.string().email("Email is invalid").required("Email is required"),
});

const SendOTP: FC<IProps> = ({ setStep, setUserEmail }) => {
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    setError,
  } = useForm<ISendOTPForm>({
    resolver: yupResolver(sendOTPSchema),
  });
  const { forgotPassword } = useAuthApi();

  const onSubmit = useCallback(
    async (data: ISendOTPForm) => {
      try {
        const result = await forgotPassword({ email: data.email });

        const { status } = await result;

        if (status === 200) {
          setUserEmail(data.email);
          setStep(ESteps.ChangePassword);
        } else {
          setError("root", {
            message: "Something went wrong",
          });
        }
      } catch (e) {
        setError("root", { message: "Something went wrong" });
      }
    },
    [forgotPassword, setError, setStep, setUserEmail]
  );

  return (
    <FormContainer
      {...(errors.root?.message && {
        helperText: { message: errors.root.message, isError: true },
      })}
      isSubmitting={isSubmitting}
      onSubmit={handleSubmit(onSubmit)}
    >
      <TextInput
        disabled={isSubmitting}
        placeholder="user@email.com"
        label="Email"
        icon={
          <img
            src="assets/icons/mail.svg"
            alt="email-icon"
            width="20px"
            height="auto"
          />
        }
        type="text"
        name="email"
        register={register}
        errorMessage={errors.email?.message as string}
      />
    </FormContainer>
  );
};

export default SendOTP;
